import React, { useState } from 'react'
import DataSourceModal from './dataSourceModal'
import { useDispatch,useSelector } from 'react-redux'
import Excel from './Sources/Excel'

const DataSources = () => {
  const dispatch = useDispatch()
  const [isPopupActive,setIsPopupActive] = useState(false)
  const [ActiveTab,setActiveTab] = useState(false)

  {/**This Function Will Handle Popup**/}
  const handlePopup = () => {
    setIsPopupActive(!isPopupActive)
  }

  return (
    <div className='w-[90%] h-[70px] flex mt-[20px]  ' >
      <button onClick={handlePopup} className='w-[88%] h-[50px] text-[14px] cursor-pointer bg-new_purple_600 rounded-md active:scale-90 transition-all text-white font-Inter font-medium absolute bottom-3  ' >
        Connect To Datasource
      </button>
      <DataSourceModal setIsPopupActive={setIsPopupActive} isPopupActive={isPopupActive} />
    </div>
  )
}

export default DataSources