import article1 from '../../assets/BlogPage/Article1.png'

export const ArticlesData = [
    {
        id:1,
        type:'blog',
        readTime:'5 min read',
        title:'Learning From Japan\'s NERV Disaster Preventing Robot',
        description:'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer nec odio. Praesent libero. Sed cursus ante dapibus diam. Sed nisi.',	
        Image: article1,
        publishedDate:'November 6, 2022',
        writtenBy:'John Doe',
        twitterLink:'https://twitter.com/',
        linkedinLink:'https://www.linkedin.com/',
        facebookLink:'https://www.facebook.com/',
        endDescription:'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer nec odio. Praesent libero. Sed cursus ante dapibus diam. Sed nisi.',
        information:[
            {
                title:'Introduction',
                beforeimgPara:'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer nec odio. Praesent libero. Sed cursus ante dapibus diam. Sed nisi.',
                image: article1,
                caption: 'Image Caption',
                quote: 'In a world older and more complete than ours they move finished and complete, gifted with extensions of the senses we have lost or never attained, living by voices we shall never hear.',
                quoteAuthor: 'Olivia Rhye, Product Designer',
                afterimgPara: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer nec odio. Praesent libero. Sed cursus ante dapibus diam. Sed nisi. Nulla quis sem at nibh elementum imperdiet. Duis sagittis ipsum.'
                
            },
            {
                title:'Introduction',
                beforeimgPara:'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer nec odio. Praesent libero. Sed cursus ante dapibus diam. Sed nisi.',
            }
        ]
    },
    {
        id:2,
        type:'article',
        readTime:'5 min read',
        title:'Learning From Japan\'s NERV Disaster Preventing Robot',
        description:'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer nec odio. Praesent libero. Sed cursus ante dapibus diam. Sed nisi.',	
        Image: article1,
        publishedDate:'November 6, 2022',
        writtenBy:'John Doe',
        twitterLink:'https://twitter.com/',
        linkedinLink:'https://www.linkedin.com/',
        facebookLink:'https://www.facebook.com/',
        endDescription:'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer nec odio. Praesent libero. Sed cursus ante dapibus diam. Sed nisi.',
        information:[
            {
                title:'Introduction',
                beforeimgPara:'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer nec odio. Praesent libero. Sed cursus ante dapibus diam. Sed nisi.',
                image: article1,
                caption: 'Image Caption',
                quote: 'In a world older and more complete than ours they move finished and complete, gifted with extensions of the senses we have lost or never attained, living by voices we shall never hear.',
                quoteAuthor: 'Olivia Rhye, Product Designer',
                afterimgPara: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer nec odio. Praesent libero. Sed cursus ante dapibus diam. Sed nisi. Nulla quis sem at nibh elementum imperdiet. Duis sagittis ipsum.'
                
            },
            {
                title:'Introduction',
                beforeimgPara:'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer nec odio. Praesent libero. Sed cursus ante dapibus diam. Sed nisi.',
            }
        ]

    },
    {
        id:3,
        type:'blog',
        readTime:'5 min read',
        title:'Learning From Japan\'s NERV Disaster Preventing Robot',
        description:'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer nec odio. Praesent libero. Sed cursus ante dapibus diam. Sed nisi.',	
        Image: article1,
        publishedDate:'November 6, 2022',
        writtenBy:'John Doe',
        twitterLink:'https://twitter.com/',
        linkedinLink:'https://www.linkedin.com/',
        facebookLink:'https://www.facebook.com/',
        endDescription:'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer nec odio. Praesent libero. Sed cursus ante dapibus diam. Sed nisi.',
        information:[
            {
                title:'Introduction',
                beforeimgPara:'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer nec odio. Praesent libero. Sed cursus ante dapibus diam. Sed nisi.',
                image: article1,
                caption: 'Image Caption',
                quote: 'In a world older and more complete than ours they move finished and complete, gifted with extensions of the senses we have lost or never attained, living by voices we shall never hear.',
                quoteAuthor: 'Olivia Rhye, Product Designer',
                afterimgPara: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer nec odio. Praesent libero. Sed cursus ante dapibus diam. Sed nisi. Nulla quis sem at nibh elementum imperdiet. Duis sagittis ipsum.'
                
            },
            {
                title:'Introduction',
                beforeimgPara:'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer nec odio. Praesent libero. Sed cursus ante dapibus diam. Sed nisi.',
            }
        ]

    },
    {
        id:4,
        type:'blog',
        readTime:'5 min read',
        title:'Learning From Japan\'s NERV Disaster Preventing Robot',
        description:'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer nec odio. Praesent libero. Sed cursus ante dapibus diam. Sed nisi.',	
        Image: article1,
        publishedDate:'November 6, 2022',
        writtenBy:'John Doe',
        twitterLink:'https://twitter.com/',
        linkedinLink:'https://www.linkedin.com/',
        facebookLink:'https://www.facebook.com/',
        endDescription:'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer nec odio. Praesent libero. Sed cursus ante dapibus diam. Sed nisi.',
        information:[
            {
                title:'Introduction',
                beforeimgPara:'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer nec odio. Praesent libero. Sed cursus ante dapibus diam. Sed nisi.',
                image: article1,
                caption: 'Image Caption',
                quote: 'In a world older and more complete than ours they move finished and complete, gifted with extensions of the senses we have lost or never attained, living by voices we shall never hear.',
                quoteAuthor: 'Olivia Rhye, Product Designer',
                afterimgPara: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer nec odio. Praesent libero. Sed cursus ante dapibus diam. Sed nisi. Nulla quis sem at nibh elementum imperdiet. Duis sagittis ipsum.'
                
            },
            {
                title:'Introduction',
                beforeimgPara:'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer nec odio. Praesent libero. Sed cursus ante dapibus diam. Sed nisi.',
            }
        ]

    },
    {
        id:5,
        type:'blog',
        readTime:'5 min read',
        title:'Learning From Japan\'s NERV Disaster Preventing Robot',
        description:'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer nec odio. Praesent libero. Sed cursus ante dapibus diam. Sed nisi.',	
        Image: article1,
        publishedDate:'November 6, 2022',
        writtenBy:'John Doe',
        twitterLink:'https://twitter.com/',
        linkedinLink:'https://www.linkedin.com/',
        facebookLink:'https://www.facebook.com/',
        endDescription:'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer nec odio. Praesent libero. Sed cursus ante dapibus diam. Sed nisi.',
        information:[
            {
                title:'Introduction',
                beforeimgPara:'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer nec odio. Praesent libero. Sed cursus ante dapibus diam. Sed nisi.',
                image: article1,
                caption: 'Image Caption',
                quote: 'In a world older and more complete than ours they move finished and complete, gifted with extensions of the senses we have lost or never attained, living by voices we shall never hear.',
                quoteAuthor: 'Olivia Rhye, Product Designer',
                afterimgPara: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer nec odio. Praesent libero. Sed cursus ante dapibus diam. Sed nisi. Nulla quis sem at nibh elementum imperdiet. Duis sagittis ipsum.'
                
            },
            {
                title:'Introduction',
                beforeimgPara:'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer nec odio. Praesent libero. Sed cursus ante dapibus diam. Sed nisi.',
            }
        ]

    },
    {
        id:6,
        type:'blog',
        readTime:'5 min read',
        title:'Learning From Japan\'s NERV Disaster Preventing Robot',
        description:'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer nec odio. Praesent libero. Sed cursus ante dapibus diam. Sed nisi.',	
        Image: article1,
        publishedDate:'November 6, 2022',
        writtenBy:'John Doe',
        twitterLink:'https://twitter.com/',
        linkedinLink:'https://www.linkedin.com/',
        facebookLink:'https://www.facebook.com/',
        endDescription:'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer nec odio. Praesent libero. Sed cursus ante dapibus diam. Sed nisi.',
        information:[
            {
                title:'Introduction',
                beforeimgPara:'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer nec odio. Praesent libero. Sed cursus ante dapibus diam. Sed nisi.',
                image: article1,
                caption: 'Image Caption',
                quote: 'In a world older and more complete than ours they move finished and complete, gifted with extensions of the senses we have lost or never attained, living by voices we shall never hear.',
                quoteAuthor: 'Olivia Rhye, Product Designer',
                afterimgPara: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer nec odio. Praesent libero. Sed cursus ante dapibus diam. Sed nisi. Nulla quis sem at nibh elementum imperdiet. Duis sagittis ipsum.'
                
            },
            {
                title:'Introduction',
                beforeimgPara:'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer nec odio. Praesent libero. Sed cursus ante dapibus diam. Sed nisi.',
            }
        ]

    },
    {
        id:7,
        type:'blog',
        readTime:'5 min read',
        title:'Learning From Japan\'s NERV Disaster Preventing Robot',
        description:'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer nec odio. Praesent libero. Sed cursus ante dapibus diam. Sed nisi.',	
        Image: article1,
        publishedDate:'November 6, 2022',
        writtenBy:'John Doe',
        twitterLink:'https://twitter.com/',
        linkedinLink:'https://www.linkedin.com/',
        facebookLink:'https://www.facebook.com/',
        endDescription:'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer nec odio. Praesent libero. Sed cursus ante dapibus diam. Sed nisi.',
        information:[
            {
                title:'Introduction',
                beforeimgPara:'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer nec odio. Praesent libero. Sed cursus ante dapibus diam. Sed nisi.',
                image: article1,
                caption: 'Image Caption',
                quote: 'In a world older and more complete than ours they move finished and complete, gifted with extensions of the senses we have lost or never attained, living by voices we shall never hear.',
                quoteAuthor: 'Olivia Rhye, Product Designer',
                afterimgPara: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer nec odio. Praesent libero. Sed cursus ante dapibus diam. Sed nisi. Nulla quis sem at nibh elementum imperdiet. Duis sagittis ipsum.'
                
            },
            {
                title:'Introduction',
                beforeimgPara:'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer nec odio. Praesent libero. Sed cursus ante dapibus diam. Sed nisi.',
            }
        ]

    },
    {
        id:8,
        type:'article',
        readTime:'5 min read',
        title:'Learning From Japan\'s NERV Disaster Preventing Robot',
        description:'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer nec odio. Praesent libero. Sed cursus ante dapibus diam. Sed nisi.',	
        Image: article1,
        publishedDate:'November 6, 2022',
        writtenBy:'John Doe',
        twitterLink:'https://twitter.com/',
        linkedinLink:'https://www.linkedin.com/',
        facebookLink:'https://www.facebook.com/',
        endDescription:'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer nec odio. Praesent libero. Sed cursus ante dapibus diam. Sed nisi.',
        information:[
            {
                title:'Introduction',
                beforeimgPara:'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer nec odio. Praesent libero. Sed cursus ante dapibus diam. Sed nisi.',
                image: article1,
                caption: 'Image Caption',
                quote: 'In a world older and more complete than ours they move finished and complete, gifted with extensions of the senses we have lost or never attained, living by voices we shall never hear.',
                quoteAuthor: 'Olivia Rhye, Product Designer',
                afterimgPara: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer nec odio. Praesent libero. Sed cursus ante dapibus diam. Sed nisi. Nulla quis sem at nibh elementum imperdiet. Duis sagittis ipsum.'
                
            },
            {
                title:'Introduction',
                beforeimgPara:'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer nec odio. Praesent libero. Sed cursus ante dapibus diam. Sed nisi.',
            }
        ]

    }
]

export const NewsLetter = 
    {
        title:'Weekly newsletter',
        description:'No spam. Just the latest releases and tips, interesting articles, and exclusive interviews in your inbox every week.',
        text:'Read about our privacy policy',
        buttonText:'Subscribe'
    }
