import React from 'react'
import { usedispatch , useSelector } from 'react-redux'
import Spinner from '../../../../../../components/spinner'

const NumberChart = () => {

  const dataChart = useSelector((state)=>state.charts.numberChart)
  const isLoading = useSelector((state)=>state.charts.isCalculationLoading)

  return (
    <div className='w-full h-full flex items-center justify-center' >

      {
        isLoading ? <>
        <Spinner w={12} h={12} /> <span className='text-[20px] font-Inter' >Importing Data</span>
        </>: 

        dataChart ? 
        <h2 className='text-[40px] font-Inter font-semibold ' >{dataChart}</h2>
        :
        <h2>Please Import Any DataSet</h2>
      }
     
    </div>
  )
}

export default NumberChart