import React, { useEffect, useState } from 'react';
import AllDataSources from '../../../../../../../../Services/data-sources';
import Spinner from '../../../../../../../../components/spinner';
import io from 'socket.io-client';

// Replace with your Socket.IO server URL
const SOCKET_SERVER_URL = 'http://localhost:5152';
const SOCKET_NAMESPACE = '/sockets';



const ConnectData = ({ setIsLoged }) => {
  const [socket, setSocket] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isConnect, setIsConnect] = useState(false);
  const [message, setMessage] = useState('');
  
  const userId = JSON.parse(localStorage.getItem("user-profile"))?._id


  const handleConnect = async () => {
    try {
      setIsLoading(true);
      const res = await AllDataSources.googleSheet();

      if (res) {
        setIsLoading(false);
        setIsConnect(true);
        window.open(res);
      }
    } catch (error) {
      setIsLoading(false);
      setIsConnect(false);
    }
  };

  useEffect(() => {
    if (!userId) {
      console.error("User ID not found");
      return;
    }

    const socketInstance = io(`${SOCKET_SERVER_URL}${SOCKET_NAMESPACE}`, {
      transports: ['websocket'],
    });

    socketInstance.on('connect', () => {
      console.log('WebSocket connection established');
    });

    socketInstance.on('disconnect', () => {
      console.log('WebSocket connection terminated');
    });

    socketInstance.on(`google-confirmation/${userId}`, (data) => {

      if (data?.message) {
        setIsLoged(true)
        localStorage.setItem("googleLogined", true);
        console.log(data.message)
        setMessage(data.message.response);
      }
    });

    socketInstance.on('test-socket', (data) => {
      console.log("Test socket message received:", data);
    });

    setSocket(socketInstance);

    // Cleanup on component unmount
    return () => {
      socketInstance.disconnect();
    };
  }, [isConnect==true]);

  // useEffect(()=>{
    
  //   console.log(message,"message")
  //   if(message){
  //     
  //   }

  // },[isConnect])

  return (
    <div className='w-full h-full flex'>
      <div className='w-[500px] h-auto flex items-center flex-col gap-6 mx-auto my-auto'>
        <img src='/media/data-sources/pablita2.png' alt='Google Account' />
        <p className='text-[14px] font-Inter font-normal'>
          You Haven't Selected Any Google Account Yet
        </p>
        <button
          onClick={handleConnect}
          className='w-full h-[60px] text-white bg-[#039855] hover:bg-[#039855e0] active:scale-90 transition-all rounded-md cursor-pointer inline-flex items-center justify-center font-Inter font-semibold'
        >
          {isLoading ? <Spinner /> : "Connect to Google"}
        </button>
      </div>
    </div>
  );
};

export default ConnectData;
