import heroimg from '../../assets/Kpi/heroimg.png'
import heroimgMobile from '../../assets/Kpi/heroimgMobile.png'
import kpi1 from '../../assets/Kpi/kpi1.png'
import kpi2 from '../../assets/Kpi/kpi2.png'
import kpi3 from '../../assets/Kpi/kpi3.png'
import kpi4 from '../../assets/Kpi/kpi4.png'
import kpi5 from '../../assets/Kpi/kpi5.png'
import kpi6 from '../../assets/Kpi/kpi6.png'
import kpi7 from '../../assets/Kpi/kpi7.png'
import kpi8 from '../../assets/Kpi/kpi8.png'
import kpi9 from '../../assets/Kpi/kpi9.png'

export const heroSection = {
    title : "KPI Examples and Templates",
    description : "This resource provides visual KPI examples and templates for key departments such as Sales, Marketing, Accounting, Supply Chain, Call Centers and more",
    buttonText: "Let's See",
    heroimg,
    heroimgMobile
}

export const heading = {
    heading: "Dashboard Template",
    title: "How to use these business dashboard reporting examples",
    description: [
        "Overview",
        "A Key Performance Indicator (KPI) is a measurable value that demonstrates how effectively a company is achieving key business objectives. Organizations use KPIs to evaluate their success at reaching targets. Selecting the right KPIs will depend on your industry and which part of the business you are looking to track",
        "Once you’ve selected your key business metrics, you will want to track them in a real-time reporting tool. KPI management can be done using dashboard reporting software, giving your entire organization insights into your current performance. Learn more about how to track KPIs in a report or dashboard."
    ]
}

export const kpiTemplates = [
    {
        img: kpi1,
        title: "Financial Metrics & KPIs",
    },
    {
        img: kpi2,
        title: "Financial Metrics & KPIs",
    },
    {
        img: kpi3,
        title: "Financial Metrics & KPIs",
    },
    {
        img: kpi4,
        title: "Financial Metrics & KPIs",
    },
    {
        img: kpi5,
        title: "Financial Metrics & KPIs",
    },
    {
        img: kpi6,
        title: "Financial Metrics & KPIs",
    },
    {
        img: kpi7,
        title: "Financial Metrics & KPIs",
    },
    {
        img: kpi8,
        title: "Financial Metrics & KPIs",
    },
    {
        img: kpi9,
        title: "Financial Metrics & KPIs",
    }
]