import React from "react";
import RegisterFrom from "./registerForm";
import "./index.css";

const RegisterPage = () => {

  const largeDevice = "2xl:scale-[1.7] 2xl:right-[122px] 2xl:-bottom-[250px]"
  const normalDevice = "lg:scale-[2.2] lg:right-[150px] lg:-bottom-[190px]"

  return (
    <div className="w-full min-h-[100vh] flex flex-row relative ">
      {/**Container Form**/}
      <div className="sm:w-full lg:w-[50%]  flex items-center justify-center   ">
        {/**Inner Conatiner**/}
        <div className="w-[70%] max-w-[550px]  flex flex-col items-center gap-6 ">
          {/**Logo Baiynah**/}
          <div className="w-full flex flex-col gap-3 items-center p-4  ">
            <img
              src="/media/login/baiynah-logo.svg"
              className="mb-[10px] mt-[20px] "
            />
            <h2 className=" sm:text-[22px] 2xl:text-[30px] font-Inter font-semibold ">
              SIGN UP
            </h2>
            <span className="sm:text-[14px] 2xl:text-[16px] font-Inter font-normal text-[new_gray_700] text-[#344054] ">
              We suggest using SSO for Sign UP.
            </span>
          </div>
          {/***Google Auth***/}
          <div className="w-full flex items-center justify-center flex-col gap-4  ">
            <div className="w-full flex items-center justify-center active:scale-95 transition-all h-[50px] border-[1px] border-[new_gray_300] cursor-pointer rounded-md bg-white shadow-sm ">
              <img src="/media/login/google.svg" />
            </div>
            <div className="w-full h-[50px] flex flex-row items-center justify-center gap-2 ">
              <span className="w-[40%] h-[1px]  bg-gray-200 "></span>
              <span className="w-[20%]  text-[#667085]  font-Inter text-center text-[14px] ">
                Or Sign Up
              </span>
              <span className="w-[40%] h-[1px]  bg-gray-200 "></span>
            </div>
          </div>
          {/**SIGN UP FORM **/}
          <RegisterFrom />
          <div className="w-full h-[30px] block"></div>
        </div>
      </div>
      {/**Container About Product**/}
     <div className="sm:w-0 lg:w-[50%] h-[100%] fixed top-0 right-0  overflow-hidden  ">
      <div className=" w-full h-full relative flex bg-gradient-to-r from-primary_purple_gradient_start to-primary_purple_gradient_end" >
        <img src="/media/login/circle-vector.svg" className="absolute lg:top-[40%] 2xl:top-[22%]  z-10 right-0 lg:h-[100%] 2xl:h-[90%]  " />
        <div className="w-full h-full flex flex-col z-30 items-center mt-[10%]  " >
          {/**Some Text About Product**/}
          <div className="w-[80%] flex flex-col gap-4 items-start " > 
            <img src="/media/login/baiynah-white.svg" className="h-[30px]" />
            <h2 className="lg:text-[30px] 2xl:text-[40px] font-Inter font-semibold text-white " >Streamlining Insights with a <br/> Unified Dashboard</h2>
            <p className="text-[15px] font-Inter font-normal text-white " >Effortlessly Monitor Diverse Data Sources in One Interface</p>
            {/**Inegrating companies**/}
            <div className="flex flex-row gap-8 mt-[10px] items-center justify-between " >
              <div className="flex flex-row gap-2 items-center " >
                <img className="h-[22px]" src="/media/login/activecampaign.svg"/>
                <span className="text-white font-Inter text-[14px]" >active Campaign</span>
              </div>
              <div className="flex flex-row gap-2 items-center " >
                <img className="h-[22px]" src="/media/login/airbrake.svg"/>
                <span className="text-white font-Inter text-[14px]" >AirBrake</span>
              </div>
              <div className="flex flex-row gap-2 items-center " >
                <img className="h-[22px]" src="/media/login/airtable.svg"/>
                <span className="text-white font-Inter text-[14px]" >Airtable</span>
              </div>
            </div>
          </div>
          {/**Dashbaord Picture**/}
          <div style={{backgroundImage:"url(/media/login/dashboard-3.svg)",backgroundSize:"cover",border:"10px solid #8669fb",borderRadius:"20px"}}  className="w-full h-full  mt-[60px] relative bg-gray-200 ml-[20%] " >
            {/* <img src="/media/login/dashboard-3.svg" className={`w-full h-full aspect-auto `} /> */}
          </div>
        </div>
      </div>
     </div>
      
    </div>
  );
};

export default RegisterPage;
