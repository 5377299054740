import React, { useState } from 'react'

const DropedColumn = ({columns,setColumns,activeformula,setActiveFormula}) => {

  const [isActive,setIsActive] = useState(false)

    const handleDelete = () => {
        setColumns(" ");
    };

    const handleFormula = (e) => {
      const {value} = e.target;
      setActiveFormula(value)
    }

  return (
    <div className='w-full min-h-[45px] max-h-auto flex flex-col items-center border-b-[1px] px-4 ' >
       <div className='w-full h-[45px] flex flex-row items-center justify-between gap-2  ' >
         <div className=' flex gap-2 items-center' >
            <img onClick={(prev)=>setIsActive(!isActive)} src='/media/dashboard/chevron-right.svg' className='h-[18px] cursor-pointer' />
            <span className='text-[15px] font-Inter ' > {activeformula} of {columns?.value}</span>
         </div>
         <img onClick={handleDelete} src='/media/dashboard/delete.svg' className='h-[18px] cursor-pointer hover:scale-125 active:scale-90  transition-all  ' />
       </div>
       <div style={{height:isActive ? "50px" : "0px" }} className={`w-full transition-all ${isActive ? " opacity-100  py-2" : " opacity-0 "} border-t-[1px]  flex flex-col  gap-2  overflow-hidden `} >
         <select onChange={handleFormula} className='w-[150px] h-[40px] border-[1px] outline-none px-2 ' >
          <option>Sum</option>
          <option>Count of Row</option>
          <option>Minimum</option>
          <option>Maximum</option>
          <option>Distinct Count</option>
         </select>
       </div>
    </div>
  )
}

export default DropedColumn