import React, { useState } from "react";
import Wrapper from "../../components/Wrapper";
import ReusableButton from "../../components/IntegrationButton";
import active from "../../assets/LandingPage/active.svg";
import airbrake from "../../assets/LandingPage/airbrake.svg";
import aircall from "../../assets/LandingPage/aircall.svg";
import airtable from "../../assets/LandingPage/airtable.svg";
import amplitude from "../../assets/LandingPage/amplitude.svg";
import dataAi from "../../assets/LandingPage/dataai.svg";
import appFigures from "../../assets/LandingPage/appfigures.svg";
import googleSheet from "../../assets/LandingPage/googlesheets.svg";
import bannerBear from "../../assets/LandingPage/bannerbear.svg";
import buffer from "../../assets/LandingPage/buffer.svg";
import chartMogul from "../../assets/LandingPage/chart.svg";
import campaignMonitor from "../../assets/LandingPage/campaignmonitor.svg";
import baremetrics from "../../assets/LandingPage/baremetrics.svg";
import chargify from "../../assets/LandingPage/chargify.svg";
import codepen from "../../assets/LandingPage/codepen.svg";

const logoRows = [
  [
    { name: "Active Campaign", image: active },
    { name: "AirBrake", image: airbrake },
    { name: "AirCall", image: aircall },
    { name: "AirTable", image: airtable },
    { name: "Amplitude", image: amplitude },
  ],
  [
    { name: "Data.ai", image: dataAi },
    { name: "appFigures", image: appFigures },
    { name: "Google Sheet", image: googleSheet },
    { name: "BannerBear", image: bannerBear },
  ],
  [
    { name: "Buffer", image: buffer },
    { name: "Chart Mogul", image: chartMogul },
    { name: "Campaign Monitor", image: campaignMonitor },
  ],
  [
    { name: "Baremetrics", image: baremetrics },
    { name: "Chargify", image: chargify },
  ],
  [{ name: "50+ Integrations", image: codepen }],
];

const IntegrationGrid = () => {
  const [activeIndex, setActiveIndex] = useState(0);

  const handleMouseEnter = (index) => {
    setActiveIndex(index);
  };

  const handleMouseLeave = () => {
    setActiveIndex(0);
  };

  return (
    <div className="bg-[#101115]">
      <Wrapper>
        <div>
          <div className="flex justify-center items-center">
            <button className="bg-[#170c31] text-[#9757ff] font-semibold px-3 py-1 rounded-full text-lg mt-28">
              Applications Collabrating Seemlessly
            </button>
          </div>
          <div className="bg-darkPurple text-white py-8 px-4 lg:px-8 font-Inter">
            <div className="text-center mb-6">
              <h2 className="lg:text-4xl sm:text-[24px] font-semibold my-4">
                Integration with numerous applications
              </h2>
              <p className="mb-8 sm:px-18 lg:px-48 md:px-38 sm:text-[14px]">
                Bayinah connects to over 90 popular business tools, including
                spreadsheets. If you don’t see the data source you need, then
                get in touch. It’s often possible to connect your data via
                Zapier or our custom API.
              </p>
            </div>

            <div className="hidden md:block">
              {logoRows.map((row, rowIndex) => (
                <div key={rowIndex} className="flex justify-center mb-4">
                  {row.map((item, itemIndex) => {
                    const index = rowIndex * row.length + itemIndex;
                    const isActive = index === activeIndex;
                    return (
                      <ReusableButton
                        key={itemIndex}
                        label={item.name}
                        onClick={() =>
                          console.log(`${item.name} button clicked`)
                        }
                        className={`border border-gray-300 text-sm shadow p-2 flex items-center justify-left mx-2 transition-colors duration-300 ${
                          isActive
                            ? "lg:bg-[#221546] lg:text-[#9757ff]"
                            : "lg:hover:bg-[#221546] lg:hover:text-[#9757ff]"
                        }`}
                        logo={item.image}
                        onMouseEnter={() => handleMouseEnter(index)}
                        onMouseLeave={handleMouseLeave}
                        isActive={isActive}
                      />
                    );
                  })}
                </div>
              ))}
            </div>

            <div className="md:hidden">
              {logoRows.flat().map((item, index) => (
                <div key={index} className="flex justify-center mb-4">
                  <ReusableButton
                    label={item.name}
                    onClick={() => console.log(`${item.name} button clicked`)}
                    className={`border-2 border-gray-300 text-sm shadow p-2 flex items-center justify-center w-11/12 transition-colors duration-300 ${
                      index === activeIndex
                        ? "bg-[#221546] text-[#9757ff]"
                        : "hover:bg-[#221546] hover:text-[#9757ff]"
                    }`}
                    logo={item.image}
                    onMouseEnter={() => handleMouseEnter(index)}
                    onMouseLeave={handleMouseLeave}
                    isActive={index === activeIndex}
                  />
                </div>
              ))}
            </div>
          </div>
        </div>
      </Wrapper>
    </div>
  );
};

export default IntegrationGrid;
