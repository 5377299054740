
export const FormatFormula = (formula) => {
  switch (formula) {
    case "Sum":
      return "SUM";
    case "Count of Row":
      return "COUNT";
    case "Minimum":
      return "MIN";
    case "Maximum":
      return "MAX";
    case "Distinct Count":
      return "DISTINCT_COUNT";
    default:
      return "SUM";
  }
};