import React, { useState,useEffect } from 'react'
import { Outlet } from 'react-router-dom';
import UserProfile from '../../../Services/userProfile';

const userProfile = new UserProfile().getUserProfile()

const AddWidgets = () => {


  const {fullName,email,photoProfile} = userProfile
  const [isSelectData,setIsSelectData] = useState(false)

  console.log(UserProfile)

  useEffect(()=>{
    if(window.location.pathname == "/dashboard/select-data"){
     console.log(true)
     setIsSelectData(true)
    }
    else{
     setIsSelectData(false)
    }
   },[])

  const trimString = (str) => {
    if(str.length >= 16){
      return str.substring(0, 16) + '...';
    }
    else{
      return str;
    }
  }

  const createAvatar = () => {
    const word = fullName[0];
    return word
  }


  return (
    <div  className='w-full h-[100vh] flex flex-col overflow-hidden  ' >
      {/***Header*/}
      <div className='w-full h-[70px] flex flex-row  justify-between px-10   border-b-[1px] border-new_gray_100 ' >
        {/**Header Left Side**/}
         <div className='sm:w-full lg:w-[60%] flex flex-row gap-6 items-center ' >
           {/**Website Logo**/}
            <div className='flex flex-row items-center gap-6  ' >
              <img src='/media/add-widgets/baiynah.svg'  className='h-[30px]'  />
              <div className=' bg-new_gray_200 w-[1px] h-[35px] ' ></div>
            </div>
            {/***Active Tabs**/}
            <div className='flex flex-row items-center gap-4' >
              <span className='text-[14px] text-new_gray_900 text-new  font-Inter font-semibold'>Dashboard</span>
              <span className='w-[1px] h-[22px] bg-new_gray_200  rotate-12' ></span>
              <span className='text-[14px] text-new_gray_900 text-new  font-Inter font-semibold' >Widget</span>
              {
                isSelectData == true && 
                <>
                <span className='w-[1px] h-[22px] bg-new_gray_200  rotate-12' ></span>
                <span className='text-[14px] text-new_gray_900 text-new  font-Inter font-semibold' >Select Data</span>
                </>
              }
            </div>
         </div>
        {/**Header Right Side**/}
        <div className='sm:w-0 lg:w-[40%] sm:hidden lg:flex flex flex-row gap-4 items-center justify-end ' >

        <div className='flex flex-row gap-2  p-1 bg-gray-100 rounded-full items-center ' >
          {
            !photoProfile ?
            <div className='w-[35px] h-[35px] bg-orange-600 rounded-full flex items-center text-white font-Inter font-semibold justify-center' >{createAvatar()}</div>
            :
            <img src={photoProfile} className='h-[35px] w-[35px]' />
          }
          <div className='flex flex-col' > 
           <span className='text-[14px]  font-Inter font-semibold  ' >{trimString(fullName)}</span>
           <span className='text-[12px] text-new_gray_500 font-Inter font-normal ' >{trimString(email)}</span>
          </div>
        </div>         

        </div>
      </div>
      <Outlet/>
    </div>
  )
}

export default AddWidgets