import React from "react";
import stars from "../../assets/stars.svg";
import Header from "../../components/Header";

export default function NotFoundPage() {
  return (
    <div className="relative bg-black overflow-hidden min-h-screen">
      <Header />
      <div className="flex items-center justify-center bg-black text-white px-4 mb-10 relative sm:mt-20 lg:mt-40">
        <div className="text-center max-w-lg mb-20 z-30">
          <h1 className="text-9xl font-extrabold text-gradient font-inter z-40 ">
            404
          </h1>
          <p className="text-3xl font-bold uppercase tracking-wider mt-4 font-Inter">
            Ooups, page not found
          </p>
          <p className="mt-4 font-inter text-small font-light ">
            Oops! It seems like the page you're looking for has taken an
            unexpected detour into the digital wilderness. Don't worry, though,
            you're not lost – just a bit off the beaten path.
          </p>
        </div>
        <img src={stars} alt="" className="absolute z-10 w-full" />
      </div>
    </div>
  );
}
