import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import DropdownMenu from "../screens/homepage/Dropdown";

export default function Header() {

  const navigate = useNavigate()

  const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);

  return (
    <header className=" bg-black text-white font-Inter mt-[10px] block">
      <div className="mx-auto w-[80%] sm:w-[90%] xs:w-[85%] md:w-[90%] lg:w-[85%] xl:w-[80%] h-[100%] max-w-6xl  ">
        <div className="flex justify-between items-center py-3 md:justify-start md:space-x-10">
          <div className="lg:w-0 lg:flex-1">
            <a href="/">
              <img
                className=" w-auto h-[30px] "
                src="/media/login/baiynah-white.svg"
                alt="logo"
              />
            </a>
          </div>

          <div className="-mr-2 -my-2 md:hidden">
            <button
              onClick={() => setMobileMenuOpen(!isMobileMenuOpen)}
              className="bg-black p-2 rounded-md inline-flex items-center justify-center text-gray-400 hover:text-white hover:bg-gray-800 focus:outline-none focus:bg-gray-800 focus:text-white"
              aria-controls="mobile-menu"
              aria-expanded={isMobileMenuOpen}
            >
              <span className="sr-only">Open main menu</span>

              <svg className="h-6 w-6" fill="currentColor" viewBox="0 0 20 20">
                {isMobileMenuOpen ? (
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                  />
                ) : (
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M2.5 6a.75.75 0 000 1.5h15a.75.75 0 000-1.5h-15zM2.5 10a.75.75 0 000 1.5h15a.75.75 0 000-1.5h-15zM2.5 14a.75.75 0 000 1.5h15a.75.75 0 000-1.5h-15z"
                  />
                )}
              </svg>
            </button>
          </div>

          <nav className="hidden md:flex space-x-10">
            <a
               onClick={()=>navigate("/")}
              className="text-base font-medium text-white hover:text-gray-300"
            >
              Home
            </a>
            <a
             
              className="text-base font-medium text-white hover:text-gray-300"
            >
              Product
            </a>
            <a
              onClick={()=>navigate("/pricing")}
              className="text-base font-medium text-white hover:text-gray-300 cursor-pointer "
            >
              Pricing
            </a>

            <DropdownMenu />
          </nav>

          <div className="hidden md:flex items-center justify-end md:flex-1 lg:w-0 space-x-8">
            <a
              className="text-base font-medium text-white hover:text-gray-300 cursor-pointer " 
              onClick={()=>navigate("/login")}
            >
              Log in
            </a>
            <a
               onClick={()=>navigate("/register")} 
              className="whitespace-nowrap inline-flex items-center cursor-pointer justify-center px-4 py-2  border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-new_purple_600 hover:bg-hovered_new_purple-600"
            >
              Get started
            </a>
          </div>
        </div>
      </div>

      <div
        className={`${isMobileMenuOpen ? "block" : "hidden"} md:hidden`}
        id="mobile-menu"
      >
        <div className="px-2 pt-2 pb-3 space-y-1 sm:px-3">
          <a
           onClick={()=>navigate("/")}
            className="text-white block px-3 py-2 rounded-md text-base font-medium hover:bg-gray-800"
          >
            Home
          </a>
          <a
            href="#"
            className="text-white block px-3 py-2 rounded-md text-base font-medium hover:bg-gray-800"
          >
            Product
          </a>
          <a
           onClick={()=>navigate("/pricing")}
            className="text-white block px-3 py-2 rounded-md text-base font-medium hover:bg-gray-800"
          >
            Pricing
          </a>
          <a
            href="#"
            className="text-white block px-3 py-2 rounded-md text-base font-medium hover:bg-gray-800"
          >
            Resources
          </a>
        </div>
        <a
          href="#"
          className="block w-full px-5 py-3 text-center font-medium text-white bg-new_purple_600 hover:bg-hovered_new_purple-600"
        >
          onClick={()=>navigate("/login")}
        </a>
        <a
        
          className="block w-full px-5 py-3 text-center font-medium text-white bg-new_purple_600 hover:bg-hovered_new_purple-600"
        >
         onClick={()=>navigate("/register")}
        </a>
      </div>
    </header>
  );
}
