import React, { useState } from 'react'
import AllDataSources from '../../../../../../../../Services/data-sources'
import Spinner from '../../../../../../../../components/spinner'
import { GetDataSets } from '../../../../../../../../Redux/dataSourcesSlice';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'
import { useDispatch } from 'react-redux';

const Sheeturl = ({item,sheet}) => {

    console.log(item,"ITEM")

    const dispatch = useDispatch()

    const {properties} = item

    const [isLoading,setIsLoading] = useState(false)

    const saveSheet = async () => {

        const payload = {
            sheetURL : sheet,
            sheetRange : properties.title,
            sheetTitle : properties.title
        }

        try {

            setIsLoading(true)

            const resp = await AllDataSources.saveSheet(payload)

            if(resp){
                setIsLoading(false)
                dispatch(GetDataSets())

                toast.success(`Sheet Added Successfully`, {
                    position: "top-right",
                },5000)
            }
            
        } catch (error) {
            setIsLoading(false)
        }


    }

  return (
    <div onClick={saveSheet} className='w-full h-[50px] cursor-pointer flex flex-row items-center border-b-[1px] border-[#D0D5DD] justify-between px-4 relative ' >
      
       {
        isLoading && 
        <div className='w-full h-full bg-gray-200 bg-opacity-20 flex items-center justify-center absolute top-0 ' >
        <Spinner/>
       </div>
       }

        <div className=' inline-flex items-center gap-2  ' >
        <img src='/media/data-sources/google-sheet.svg' className='h-[30px]' />
        <span className='font-Inter font-normal' >{properties.title}</span>
        </div>
        <span>5 August 2023</span>
    </div>
  )
}

export default Sheeturl