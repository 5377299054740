import api from '../config/api';

const TOKEN = localStorage.getItem('token');

//Normal Json Data
const header = TOKEN ? {
  'Content-Type': 'application/json',
  'Authorization': 'Bearer ' + TOKEN
} : { 'Content-Type': 'application/json' };

export const API = new api({ headers: header });


// Api for File type Data
const headerFile = TOKEN ? {
  'Content-Type': 'multipart/form-data',
  'Authorization': 'Bearer ' + TOKEN
} : { 'Content-Type': 'multipart/form-data' };

export const FileAPI = new api({headers:headerFile})