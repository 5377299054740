import React from "react";
import { heroSection } from "../constant";
import ReusableButton from "../../../components/IntegrationButton";

const HeroSection = () => {


  return (
    <section className="relative">
  
      <div
          className="
        absolute
        top-0
        left-0
        w-full
        md:block 
        h-[300px]
        lg:h-full
        bg-gradient-to-r
        from-[#060606]/0
        via-[#6938EF]/20
        to-[#060606]/0
        z-10
        "
        ></div>
        <div className="flex justify-start lg:justify-between lg:items-center flex-col lg:flex-row gap-8 lg:gap-0 py-[44px] lg:py-0
        mx-auto w-[80%] sm:w-[90%] xs:w-[85%] md:w-[90%] lg:w-[85%] xl:w-[80%] h-[100%] max-w-6xl">

          {/* Text Section */}
          <div className="flex flex-col md:w-[550px] lg:max-w-[612px] gap-5">
          <h1 className="text-2xl lg:text-7xl font-medium text-white">
              {heroSection.title}
            </h1>
            <p className="text-gray-100 text-[14px] ">
              {heroSection.description}
            </p>
           
            <ReusableButton label={heroSection.buttonText} className={'bg-new_purple_600 text-gray-100 rounded-lg justify-center w-fit mt-4'}/>
          </div>

          {/* Image Section */}
          <div className="w-full md:w-[550px] lg:max-w-[612px]">
            <img src={heroSection.heroimg} alt="heroImg" className="relative z-20 hidden lg:flex"/>
            <img src={heroSection.heroimgMobile} alt="heroImgMobile" className="relative z-20 flex lg:hidden "/>
          </div>
        </div>

    </section>
  );
};

export default HeroSection;
