import { API  } from "./api";


const Dashboard = {
  createDashboard: (payload)=> API.post("/dashboard",payload),
  getAllDashboads:()=>API.get("/dashboard"),
  getSingleDashboard:(params)=>API.get(`dashboard?dashboardId=${params}`),
  updateDashboard:(payload)=>API.put(`dashboard/widgets`,payload)

}

export default Dashboard;
