import { configureStore } from '@reduxjs/toolkit'
import toolsSlice from './Redux/toolsSlice'
import SheetSlice from './Redux/SheetSlice'
import dashboardSlice from './Redux/dashboardSlice'
import widgetSlice from './Redux/widgetSlice'
import chartsSlice from './Redux/chartsSlice'
import  DataSources  from './Redux/dataSourcesSlice'
export const store = configureStore({
  reducer: {
    tools:toolsSlice,
    sheet:SheetSlice,
    dashboard:dashboardSlice,
    widgets:widgetSlice,
    charts:chartsSlice,
    sources:DataSources
  },
})