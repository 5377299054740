import React, { useState } from "react";
import phone from "../../assets/ContactUs/phone.svg";
import location from "../../assets/ContactUs/location.svg";
import Wrapper from "../../components/Wrapper";

const ContactPage = () => {
  const [formState, setFormState] = useState({
    name: "",
    email: "",
    message: "",
    termsAccepted: false,
  });

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormState({
      ...formState,
      [name]: type === "checkbox" ? checked : value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("Form submitted:", formState);
  };

  return (
    <Wrapper>
      <div className="text-white min-h-screen flex flex-col items-center mx-4 sm:mx-4 my-12 font-Inter">
        <div className="w-full">
          <h1 className="text-3xl sm:text-4xl md:text-5xl font-bold mb-4">
            Contact Us
          </h1>
          <p className="mb-8 font-base text-sm w-full lg:w-[50%]">
            Contact us for any questions or concerns. Our team is ready to
            assist you promptly. Call, email, or use our online contact form. We
            value your feedback and look forward to hearing from you.
          </p>

          <hr className="border border-[#141b24] my-8 sm:my-12 lg:w-[85vw] xl:w-[80vw]"></hr>

          <div className="flex flex-col lg:flex-row my-8 sm:my-16 lg:px-[55px] lg:-mx-14 sm:mx-7">
            <div className="w-full lg:w-1/2 mb-8 lg:mb-0">
              <div className="mb-8">
                <h2 className="xl:text-2xl lg:text-xl sm:text-2xl font-bold mb-4">
                  Feel free to get in touch with us via {""}
                  <span className="text-gradient font-bold">
                    email or phone
                  </span>
                </h2>
                <hr className="w-full sm:w-[75vw] xl:w-[35vw] lg:w-[40vw] border border-[#141b24] my-4 sm:my-8"></hr>
                <div className="mb-4 flex items-center sm:mx-4">
                  <img
                    src={location}
                    alt="Location"
                    className="h-6 sm:h-9 w-6 sm:w-9 mr-4"
                  />{" "}
                  <p>
                    <p className="font-bold">Our Office Location</p>
                    <p className="text-xs sm:text-sm lg:whitespace-nowrap">
                      4 Chome-2-8 Shibakoen, Minato City, Tokyo 105-0011, Japan
                    </p>
                  </p>
                </div>
                <hr className="w-full sm:w-[75vw] xl:w-[35vw] lg:w-[40vw] border border-[#141b24] my-4 sm:my-8"></hr>
                <div className="flex items-center mx-0 sm:mx-6">
                  <img
                    src={phone}
                    alt="Phone"
                    className="h-6 sm:h-9 w-6 sm:w-9 mr-4"
                  />{" "}
                  <p>
                    <p className="font-bold">Phone</p>
                    <p className="text-xs sm:text-sm">+628123456789</p>
                  </p>
                </div>
              </div>
            </div>
            <div className="lg:w-auto sm:-translate-x-4 lg:translate-x-6 xl:translate-x-4">
              <form
                onSubmit={handleSubmit}
                className="bg-black p-4 lg:p-8 rounded-lg border-2 border-[#141b24] sm:w-[290px] lg:w-[35vw] lg:mx-20 -my-3 flex flex-col"
              >
                <h2 className="lg:text-lg xl:text-xl sm:text-2xl font-semibold text-white mb-6 sm:mb-8">
                  Get started with a free quotation
                </h2>
                <div className="mb-4 sm:mb-6">
                  <label
                    htmlFor="name"
                    className="block text-xs sm:text-sm font-medium text-white mb-2"
                  >
                    Name
                  </label>
                  <input
                    type="text"
                    name="name"
                    id="name"
                    value={formState.name}
                    onChange={handleChange}
                    className="w-full p-2 sm:p-3 rounded-lg bg-white text-black placeholder-gray-500 border border-gray-600"
                    placeholder="Olivia"
                  />
                </div>
                <div className="mb-4 sm:mb-6">
                  <label
                    htmlFor="email"
                    className="block text-xs sm:text-sm font-medium text-white mb-2"
                  >
                    Email
                  </label>
                  <input
                    type="email"
                    name="email"
                    id="email"
                    value={formState.email}
                    onChange={handleChange}
                    className="w-full p-2 sm:p-3 rounded-lg bg-white text-black placeholder-gray-500 border border-gray-600"
                    placeholder="olivia@untitledui.com"
                  />
                </div>
                <div className="mb-4 sm:mb-6">
                  <label
                    htmlFor="message"
                    className="block text-xs sm:text-sm font-medium text-white mb-2"
                  >
                    Messages
                  </label>
                  <textarea
                    name="message"
                    id="message"
                    value={formState.message}
                    onChange={handleChange}
                    className="w-full p-2 sm:p-3 rounded-lg bg-white text-black placeholder-gray-500 border border-gray-600"
                    placeholder="Enter a description..."
                  ></textarea>
                </div>
                <div className="mb-4 sm:mb-6 flex items-center">
                  <input
                    type="checkbox"
                    name="termsAccepted"
                    checked={formState.termsAccepted}
                    onChange={handleChange}
                    className="w-4 h-4 text-purple-600 bg-white border-gray-600 rounded"
                  />
                  <label
                    htmlFor="termsAccepted"
                    className="ml-2 text-xs sm:text-sm text-gray-300"
                  >
                    I accept the Terms of Service
                  </label>
                </div>
                <button
                  type="submit"
                  className="w-full p-2 sm:p-3 rounded-lg bg-[#6938ef] text-white hover:bg-[#6938ef] transition-colors"
                >
                  Send your Messages
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  );
};

export default ContactPage;
