import React,{useState} from 'react'
import { faqData } from '../constant'
import { ChevronUp, ChevronDown } from 'lucide-react';

const Faq = () => {

    const [selectedArticle, setSelectedArticle] = useState(faqData[0].articles[0]);
    const [openCategory, setOpenCategory] = useState(null);
    const [expanded, setExpanded] = useState({});

    const handleCategoryClick = (category) => {
        setOpenCategory(openCategory === category ? null : category);
        setExpanded(prev => ({ ...prev, [category]: !prev[category] }));
        
    };

    const handleArticleClick = (article) => {
        setSelectedArticle(article);
    };
    
  return (
    <div className="flex text-white max-h-full gap-6 my-11">

        {/* First Section */}
        <div className="w-full lg:w-[340px] flex flex-col gap-3">
            {faqData.map((category) => (
                <div key={category.category} className='border-[1px] border-gray-700 rounded-lg p-4 flex flex-col gap-5'>
                    <div
                        className="flex justify-between items-center cursor-pointer "
                        onClick={() => handleCategoryClick(category.category)}
                    >
                
                        <h2 className="text-2xl font-bold ">{category.category}</h2>
                        {openCategory === category.category ? (
                            <ChevronUp className="w-6 h-6"/>
                        ) : (
                            <ChevronDown className="w-6 h-6"/>
                        )}

                    </div>

                    <div className={`${openCategory === category.category ? 'block' : 'hidden'} `}>
                        <div>
                        {(expanded[category.category] ? category.articles : category.articles.slice(0, 4)).map((article) => (
                            <div className='flex flex-col gap-4'>
                                <p
                                key={article.id}
                                className={`cursor-pointer font-normal text-[16px] text-gray-300 ${selectedArticle?.id === article.id ? 'text-purple-500' : 'hover:text-blue-500'}`}
                                onClick={() => handleArticleClick(article)}
                            >
                                {article.title}   
                            </p>

                            {/* For mobile  */}
                           
                                {selectedArticle == article  && (
                                     <div className="flex lg:hidden min-w-[300px] max-w-[852px] border-[1px] border-gray-700 rounded-lg p-6 h-fit">
                                    <div>
                                        <h3 className="text-xl font-bold mb-2">{selectedArticle.title}</h3>
                                        <p className='font-normal text-[16px]'>{selectedArticle.description}</p>
                                    </div>
                                    </div>
                                )}
                            

                            <div className='h-[1px] bg-gray-800 mb-2'></div>
                            </div>
                        ))}
                        {category.articles.length > 4 && (
                            <p
                                className="cursor-pointer text-blue-500 text-sm"
                                onClick={() => setExpanded(prev => ({ ...prev, [category.category]: !prev[category.category] }))}
                            >
                                {expanded[category.category] ? 'See Less' : `See all ${category.articles.length} articles`}
                            </p>
                        )}
                        </div>
                        
                    </div>
                    
                </div>
            ))}
        </div>

        {/* Second Section */}
        <div className="hidden lg:flex min-w-[300px] max-w-[852px] border-[1px] border-gray-700 rounded-lg p-6 h-fit">
            {selectedArticle && (
                <div>
                    <h3 className="text-xl font-bold mb-2">{selectedArticle.title}</h3>
                    <p className='font-normal text-[16px]'>{selectedArticle.description}</p>
                </div>
            )}
        </div>
    </div>
  )
}

export default Faq