import React from 'react'
import { useDispatch } from 'react-redux'

const Source = ({item,setCurrentTab}) => {

  const dispatch = useDispatch()

  const handleSource = () => {
    setCurrentTab(prev=>({
      ...prev,
      id:item._id,
      name:item.name
    }))

  }

  return (
    <div onClick={handleSource} className='w-[150px] h-[150px] border-[1px] border-new_gray_200 flex gap-2 rounded-lg items-center justify-center flex-col cursor-pointer ' >
       <img src='/media/data-sources/google-sheet.svg'/>
       <span className='font-Inter font-medium' >{item.name}</span>
    </div>
  )
}

export default Source