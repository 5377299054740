import React, { useEffect, useState } from "react";
import { useDrop } from "react-dnd";
import {useDispatch} from "react-redux"
import DropedColumn from "./dropedColumn";
import { Calculate } from "../../../../../../../Redux/chartsSlice";
import { FormatFormula } from "../Formula";

const NumberDrop = () => {

  const dipatch = useDispatch()

  const [columns, setColumns] = useState();
  const [activeformula,setActiveFormula] = useState("Sum")

  const [{ canDrop, isOver }, drop] = useDrop({
    accept: "number",
    drop: (item) => {
      if (item?.column?.dataType == "number") {
        setColumns(item.column);
      } else {
        return;
      }
    },
    collect: (monitor) => ({
      isOver: !!monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  });

  const isActive = canDrop ;

  useEffect(()=>{

    if(!columns){
      return 
    }
    
    
    const payload = {
      dataId:columns.dataId,
      column:columns.column,
      formula:FormatFormula(activeformula)
    }

   dipatch(Calculate(payload))
  },[columns,activeformula])

  
  return (
    <div className="w-full h-auto border-[1px]  flex flex-col">
      <div className="w-full h-[40px] flex items-center justify-start px-4 text-[12px] font-Inter border-b-[1px]  ">
        METRIC (IN COLUMNS)
      </div>
      <div className="w-full min-h-[40px] max-h-auto flex flex-col gap-2 ">
        {columns?.value && (
          <DropedColumn columns={columns} setColumns={setColumns}  setActiveFormula={setActiveFormula} activeformula={activeformula}/>
        )}

        <div className="p-2">
          <div
            ref={drop}
            className={`w-full h-[45px] border-[3px] ${isActive ? "border-blue-200" : " "} rounded-sm border-dashed border-new_gray_200  p-1`}
          >
            <div
              className={` w-full h-full flex gap-2 items-center justify-center rounded-sm  ${isActive ? " bg-blue-300 " : ""}  `}
            >
              {isActive ? (
                <span className="text-[14px] font-Inter text-white ">
                  Drop to Add
                </span>
              ) : (
                <>
                  <span className="text-[14px] font-Inter  ">Drag</span>
                  <span className="text-[14px] font-Inter text-green-400  ">
                    123
                  </span>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NumberDrop;
