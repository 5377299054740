import React, { useEffect, useRef, useState } from "react";
import { ReactComponent as BlogIcon } from "../../assets/LandingPage/blog.svg";
import { ReactComponent as ContactIcon } from "../../assets/LandingPage/contact.svg";
import { ReactComponent as HelpIcon } from "../../assets/LandingPage/help.svg";
import { ReactComponent as ServicesIcon } from "../../assets/LandingPage/services.svg";
import Wrapper from "../../components/Wrapper";
import { ReactComponent as Down } from "../../assets/LandingPage/down.svg";

function useOutsideAlerter(ref, onOutsideClick) {
  useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        onOutsideClick();
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref, onOutsideClick]);
}

const DropdownMenu = () => {
  const [isOpen, setIsOpen] = useState(false);
  const wrapperRef = useRef(null);
  const dropdownRef = useRef(null);
  useOutsideAlerter(wrapperRef, () => setIsOpen(false));

  useEffect(() => {
    const updateDropdownPosition = () => {
      if (dropdownRef.current && wrapperRef.current) {
        const rect = wrapperRef.current.getBoundingClientRect();
        dropdownRef.current.style.top = `${rect.bottom}px`;
      }
    };

    if (isOpen) {
      updateDropdownPosition();
      window.addEventListener("scroll", updateDropdownPosition);
      window.addEventListener("resize", updateDropdownPosition);
    }

    return () => {
      window.removeEventListener("scroll", updateDropdownPosition);
      window.removeEventListener("resize", updateDropdownPosition);
    };
  }, [isOpen]);

  return (
    <Wrapper>
      <div className="relative" ref={wrapperRef}>
        <button
          onClick={() => setIsOpen(!isOpen)}
          className="text-white hover:text-gray-300 font-Inter flex items-center"
          type="button"
        >
          Resources{" "}
          <span className="md:px-4">
            <Down />
          </span>
        </button>

        {isOpen && (
          <div
            ref={dropdownRef}
            className="fixed inset-x-0 bg-black rounded-md shadow-xl z-50 border border-gray-800 px-6 py-6 font-Inter w-full max-w-screen-xl mx-auto mt-10"
          >
            <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-4 gap-4 p-5 text-white">
              <div className="flex flex-col col-span-2 space-y-4">
                <div className="grid grid-cols-2 gap-4">
                  <div className="rounded-lg p-6 flex flex-col items-start border border-gray-800 h-[240px] justify-center hover:bg-[#171717]">
                    <BlogIcon className="h-8 w-8 mb-2" />
                    <p className="text-lg font-bold">Blog</p>
                    <p className="text-base text-gray-400 mt-2">
                      Stay up to date with the latest news, announcements, and
                      articles.
                    </p>
                  </div>

                  <div className="rounded-lg p-6 flex flex-col items-start border border-gray-800 h-[240px] justify-center hover:bg-[#171717]">
                    <HelpIcon className="h-8 w-8 mb-2" />
                    <p className="text-lg font-bold">Help Center</p>
                    <p className="text-base text-gray-400 mt-2">
                      Stay up to date with the latest news, announcements, and
                      articles.
                    </p>
                  </div>
                </div>
                <div className="grid grid-cols-2 gap-4">
                  <div className="rounded-lg p-6 flex flex-col items-start border border-gray-800 h-[240px] justify-center hover:bg-[#171717]">
                    <ContactIcon className="h-8 w-8 mb-2" />
                    <p className="text-lg font-bold">Contact Us</p>
                    <p className="text-base text-gray-400 mt-2">
                      Stay up to date with the latest news, announcements, and
                      articles.
                    </p>
                  </div>

                  <div className="rounded-lg p-6 flex flex-col items-start border border-gray-800 bg-black h-[240px] justify-center hover:bg-[#171717]">
                    <ServicesIcon className="h-8 w-8 mb-2" />
                    <p className="text-lg font-bold">Services</p>
                    <p className="text-base text-gray-400 mt-2">
                      Stay up to date with the latest news, announcements, and
                      articles.
                    </p>
                  </div>
                </div>
              </div>

              <div className="flex flex-col col-span-2 space-y-4 border border-gray-800 p-4 rounded-lg">
                <div>
                  <p className="text-lg font-bold mb-4">Articles</p>
                  <div className="grid grid-cols-2 gap-4 text-base">
                    <p className="hover:text-gray-300">What is a KPI?</p>
                    <p className="hover:text-gray-300">KPI Presentations</p>
                    <p className="hover:text-gray-300">
                      Business Metrics vs KPIs
                    </p>
                    <p className="hover:text-gray-300">
                      Starter Guide to Dashboards
                    </p>
                  </div>
                </div>

                <hr className="my-4 border-gray-600" />
                <div>
                  <p className="text-lg font-bold mb-4">Dashboard Example</p>
                  <div className="grid grid-cols-2 gap-4 text-base">
                    <p className="hover:text-gray-300">Social Media</p>
                    <p className="hover:text-gray-300">Executive</p>
                    <p className="hover:text-gray-300">Finance</p>
                    <p className="hover:text-gray-300">Sales</p>
                    <p className="hover:text-gray-300">Saas</p>
                    <p className="hover:text-gray-300">Marketing</p>
                  </div>
                </div>
                <hr className="my-4 border-gray-600" />
                <div>
                  <p className="text-lg font-bold mb-4">KPI Example</p>
                  <div className="grid grid-cols-2 gap-4 text-base">
                    <p className="hover:text-gray-300">Social Media</p>
                    <p className="hover:text-gray-300">Executive</p>
                    <p className="hover:text-gray-300">Finance</p>
                    <p className="hover:text-gray-300">Sales</p>
                    <p className="hover:text-gray-300">Saas</p>
                    <p className="hover:text-gray-300">Marketing</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </Wrapper>
  );
};

export default DropdownMenu;
