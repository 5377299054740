import Layout from "../layout";
import PricingComponent from "./main";

export default function Pricing() {
  return (
    <div>
      <Layout>
        <PricingComponent />
      </Layout>
    </div>
  );
}
