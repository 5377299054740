import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import SelectColumn from "./selectColumn";
import Formulas from "./formulas";
import { setSelectedColumn,setSelectedFormula } from "../../../../../Redux/SheetSlice";

const FormulasBar = ({ isSideBarOpen ,id}) => {

  const dispatch = useDispatch()

  const selectedFormula = useSelector(state=>(state.sheet.selectedFormula))
  const selectedColumn = useSelector(state => (state.sheet.selectedColumn))
  
 
  return (
    <div
      className={`transition-all ${isSideBarOpen ? "lg:w-[30%] 2xl:w-[23%] opacity-1 " : "opacity-0 w-0 pointer-events-none"} overflow-y-auto h-full dataTable_Container  flex flex-col bg-white rounded-md py-4  `}
    >
      <div className="w-full h-auto flex flex-col gap-4 px-6 py-3 border-b-[1px] border-new_purple_50  ">
        <h2  className=" text-[16px] text-black font-semibold font-Inter">
          Summarize By
        </h2>
        {/***SELECTED DATA */}
        <div className="w-full h-auto flex flex-row flex-wrap gap-2">
          {selectedFormula && (
            <div className="w-[150px] h-[35px] flex flex-row items-center bg-new_purple_50 rounded-full p-[5px]  ">
              <span className="text-[14px] text-new_purple_700 font-Inter font-medium bg-white w-[80%] h-full rounded-full flex items-center justify-center ">
                {selectedFormula}
              </span>
              <img
                onClick={()=>dispatch(setSelectedFormula(""))}
                src="/media/add-widgets/cancel.svg"
                className="cursor-pointer hover:scale-105 active:scale-95 transition-all h-[20px]"
              />
            </div>
          )}
          {selectedColumn && (
            <div className="w-[150px] h-[35px] flex flex-row items-center bg-new_purple_50 rounded-full p-[5px]  ">
              <span className="text-[14px] text-new_purple_700 font-Inter font-medium bg-white w-[80%] h-full rounded-full flex items-center justify-center ">
                {selectedColumn}
              </span>
              <img
                onClick={()=>dispatch(setSelectedColumn(""))}
                src="/media/add-widgets/cancel.svg"
                className="cursor-pointer hover:scale-105 active:scale-95 transition-all h-[20px]"
              />
            </div>
          )}
        </div>
      </div>
      {/**Different Methods**/}
      <div className="w-full h-auto flex flex-col  mt-[10px] ">
        <div className="px-8 py-3 flex flex-row gap-2 border-b-[1px] border-new_purple_50 ">
          <img src="/media/add-widgets/formula.svg" className=" h-[25px] " />
          <h2 className="text-[16px] font-Inter font-semibold">
            Basic Metrics
          </h2>
        </div>
        <Formulas
          title={"Count of Row"}     
          formula={"COUNT"}  
          selectedFormula={selectedFormula}
        />
        <Formulas
          title={"Sum off.."}
          formula={"SUM"}
          selectedFormula={selectedFormula}
        />
        <Formulas
          title={"Minimum"}
          formula={"MIN"}
          selectedFormula={selectedFormula}
        />
        <Formulas
          title={"Maximum"}
          formula={"MAX"}
          selectedFormula={selectedFormula}
        />
        <Formulas
          title={"Distinct Count"}
          formula={"DISTINCT_COUNT"}
          selectedFormula={selectedFormula}
        />
      
         <SelectColumn  id={id}  />

       
       
      </div>
    </div>
  );
};

export default FormulasBar;
