import React from 'react'

const TemplateCard = ({img, title}) => {
  return (
    <div className='flex flex-col items-start gap-5 p-2 lg:my-8'>
      <img src={img} alt={title} />
      <p className='font-medium text-xl text-white'>{title}</p>
    </div>
  )
}

export default TemplateCard