import React, { useState } from "react";
import Wrapper from "../../components/Wrapper";

const GetNotified = () => {
  const [email, setEmail] = useState("");

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    console.log("Submitted email:", email);
  };

  return (
    <div className="bg-[#101115]">
      <Wrapper>
        <div className="text-white p-6 lg:p-0 flex flex-col md:flex-row justify-between items-center mt-32 h-[180px] font-Inter">
          <div className="md:mr-4">
            {" "}
            <h2 className="lg:text-[30px] sm:text-lg md:text-xl font-medium">
              Get notified when we launch
            </h2>
            <p className="text-sm mt-5">
              Stay up to date with the latest news, announcements, and articles.
            </p>
          </div>
          <form onSubmit={handleSubmit} className="mt-4 md:mt-0 flex">
            <input
              type="email"
              placeholder="Enter your email"
              className="p-2 rounded-lg focus:outline-none focus:ring-2 focus:ring-[#623ad1] focus:border-transparent w-auto"
              value={email}
              onChange={handleEmailChange}
            />
            <button
              type="submit"
              className="ml-2 bg-[#623ad1] hover:bg-[#9757ff] text-white text-sm font-medium p-2 rounded-lg"
            >
              Subscribe
            </button>
          </form>
        </div>
      </Wrapper>
    </div>
  );
};

export default GetNotified;
