import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import UserProfileService from '../../../../Services/userProfile';
import CreatedDashboards from './createdDashboards';

const CreateDashboard = () => {
  const [userProfile, setUserProfile] = useState(() => new UserProfileService().getUserProfile());
  const { fullName, email, photoProfile } = userProfile;
  const [isProfileMenuOpen, setIsProfileMenuOpen] = useState(false);
  const navigate = useNavigate();
  const profileMenuRef = useRef(null);

  const trimString = (str) => {
    return str.length >= 16 ? str.substring(0, 16) + '...' : str;
  };

  const createAvatar = () => {
    return fullName ? fullName[0] : '';
  };

  const handleProfileClick = () => {
    setIsProfileMenuOpen(!isProfileMenuOpen);
  };

  const handleSettingsClick = () => {
    navigate('/profile');
  };

  const handleLogoutClick = () => {
    localStorage.removeItem("token")
    window.location.reload()
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (profileMenuRef.current && !profileMenuRef.current.contains(event.target)) {
        setIsProfileMenuOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [profileMenuRef]);

  return (
    <div className="w-[100vw] min-h-[100vh] flex flex-col bg-background_color overflow-x-hidden">
      <div className="w-full h-[70px] flex flex-row justify-between px-4 lg:px-10 border-b-[1px] border-new_gray_100 bg-white">
        <div className="flex items-center gap-4 lg:gap-6">
          <div className="flex items-center gap-4 lg:gap-6">
            <img src="/media/add-widgets/baiynah.svg" className="h-[30px]" alt="Logo" />
            <div className="bg-new_gray_200 w-[1px] h-[35px]"></div>
          </div>
          <div className="flex items-center gap-2 lg:gap-4">
            <span className="text-[14px] text-new_gray_900 font-Inter font-semibold">Baiynah Workspace</span>
          </div>
        </div>
        <div className="flex items-center gap-2 lg:gap-4">
          <div className="relative" ref={profileMenuRef}>
            <div
              className="flex gap-2 p-1 bg-gray-100 rounded-full items-center cursor-pointer"
              onClick={handleProfileClick}
            >
              {!photoProfile ? (
                <div className="w-[35px] h-[35px] bg-orange-600 rounded-full flex items-center justify-center text-white font-Inter font-semibold">
                  {createAvatar()}
                </div>
              ) : (
                <img src={photoProfile} className="h-[35px] w-[35px] rounded-full" alt="Profile" />
              )}
              <div className="flex flex-col">
                <span className="text-[14px] font-Inter font-semibold">{trimString(fullName)}</span>
                <span className="text-[12px] text-new_gray_500 font-Inter font-normal">{trimString(email)}</span>
              </div>
            </div>
            {isProfileMenuOpen && (
              <div className="absolute right-0 mt-2 w-48 bg-white border border-gray-200 rounded-lg shadow-lg z-10">
                <button
                  onClick={handleSettingsClick}
                  className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                >
                  Settings
                </button>
                <button
                  onClick={handleLogoutClick}
                  className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                >
                  Logout
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
      <CreatedDashboards fullName={fullName} />
    </div>
  );
};

export default CreateDashboard;
