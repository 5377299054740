import React from 'react'
import { Copy } from 'lucide-react';
import {ReactComponent as Twitter } from '../../../assets/BlogPage/twitter.svg'
import {ReactComponent as Linkedin } from '../../../assets/BlogPage/linkedin.svg'
import {ReactComponent as Facebook} from '../../../assets/BlogPage/facebook.svg'

const TopArticle = ({data}) => {
  return (
    <section className='py-[96px]'>
        {/* Read Time */}
        <div className='flex gap-2 text-white text-[12px] bg-[#F7FCF8]/10 rounded-full px-2 py-1 w-fit'>
            <p className='bg-new_purple_600 rounded-full px-2 py-[1px]'>{data.type}</p>
            <p>{data.readTime}</p>
        </div>

        {/* Heading */}
        <h1 className='text-white text-3xl lg:text-5xl font-semibold mt-8 max-w-[768px]'>{data.title}</h1>

        {/* Description */}
        <p className='text-gray-400 text-[14px] lg:text-[20px] mt-8 max-w-[768px]'>{data.description}</p>

        {/* Image */}
        <img src={data.Image} alt='blog' className='mt-8'/>

        {/* Div that contain written by and pusblished on date on left side and copy link and social media links on right side  */}
        <div className='flex flex-col lg:flex-row gap-6 justify-between mt-4'>
            {/* Written by and published on date with labels on top */}
            <div className='flex gap-16'>
                <div className='flex flex-col gap-2'>
                    <p className='text-gray-400 text-[12px] lg:text-[14px] font-semibold'>Written By</p>
                    <p className='text-white text-[14px] lg:text-[18px] font-medium'>{data.writtenBy}</p>
                </div>
                <div className='flex flex-col gap-2'>
                    <p className='text-gray-400 text-[12px] lg:text-[14px] font-semibold'>Published On</p>
                    <p className='text-white text-[14px] lg:text-[18px] font-medium'>{data.publishedDate}</p>
                </div>
            </div>

            {/* Copy link and social media links */}
            <div className='flex gap-4'>
                <button className='text-white text-[14px] rounded-lg font-semibold flex gap-2 items-center bg-[#FFFFFF]/10 px-3 h-fit py-2'> <Copy size={15}/><p>Copy Link</p></button>
                <button className='text-white text-[14px] rounded-lg bg-[#FFFFFF]/10 px-3 h-fit py-2'><Twitter/></button>
                <button className='text-white text-[14px] rounded-lg bg-[#FFFFFF]/10 px-3 h-fit py-2'><Linkedin/></button>
                <button className='text-white text-[14px] rounded-lg bg-[#FFFFFF]/10 px-3 h-fit py-2'><Facebook/></button>
            </div>
        </div>
    </section>
  )
}

export default TopArticle