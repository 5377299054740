import blog1 from '../../assets/BlogPage/blog1.png'
import blog2 from '../../assets/BlogPage/blog2.png'

export const heading = {
    title: "Blog & Articles",
    description: "Welcome to my blog. Here I share my thoughts on software development and other topics."
}

export const BlogCardData = [
    {
        id: 1,
        image : blog1,
        title: "Learning From Japan's NERV Disaster Preventing Robot",
        type: "Article",
        date: "November 6, 2022",
    },
    {
        id: 2,
        image : blog2,
        title: "Climbing The Danish Design Ladder: How Design Can Elevate Your Business",
        type: "Articles",
        date: "November 6, 2022",
    },
    {
        id: 3,
        image : blog1,
        title: "Learning From Japan's NERV Disaster Preventing Robot",
        type: "Article",
        date: "November 6, 2022",
    },
    {
        id: 4,
        image : blog2,
        title: "Climbing The Danish Design Ladder: How Design Can Elevate Your Business",
        type: "Articles",
        date: "November 6, 2022",
    },
    {
        id: 5,
        image : blog1,
        title: "Learning From Japan's NERV Disaster Preventing Robot",
        type: "Article",
        date: "November 6, 2022",
    },
    {
        id: 6,
        image : blog2,
        title: "Climbing The Danish Design Ladder: How Design Can Elevate Your Business",
        type: "Articles",
        date: "November 6, 2022",
    },
    {
        id: 7,
        image : blog1,
        title: "Learning From Japan's NERV Disaster Preventing Robot",
        type: "Article",
        date: "November 6, 2022",
    },
    {
        id: 8,
        image : blog2,
        title: "Climbing The Danish Design Ladder: How Design Can Elevate Your Business",
        type: "Articles",
        date: "November 6, 2022",
    }
]

export const SubscribeBarContent = {
    title: "Get notified when we launch",
    description: "Stay up to date with the latest news, announcements, and articles.",
    buttonText: "Subscribe",
    placeholder: "Emter your email address"
}