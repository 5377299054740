import Container from "../../components/Container";
import Heading from "../../components/Heading";
import Subscribebar from "../../components/Subscribebar";
import Layout from "../layout";
import {heading} from "./constant";
import Blogcards from "./sections/Blogcards";

const Blog = () => {
    return (
      <div className="bg-black ">
        <Layout>
        <div className="mx-auto w-[80%] sm:w-[90%] xs:w-[85%] md:w-[90%] lg:w-[85%] xl:w-[80%] h-[100%] max-w-6xl py-[24px]">
           <Heading
            title={heading.title}
            description={heading.description}
            />
            <Blogcards/>
            </div>
        <Subscribebar/>
      </Layout>
      </div>
    );
  };
  
  export default Blog;