import React,{useState} from 'react'

const ColorSelector = () => {

    const [activeTab,setActiveTab] = useState("purple")

    const activeClass = 'bg-white border-[1px] border-new_gray_200 '
    const deActiveClass = ''


    const handleTab = (tab) => {
        setActiveTab(tab)
    }

  return (
    <div className='w-full h-[45px] bg-[#F8F9FC] border-[1px] border-new_gray_100 rounded-lg flex flex-row items-center gap-2 p-2 ' >
    <div onClick={()=>handleTab("purple")} className={` w-1/5 h-[35px] p-2 rounded-lg cursor-pointer flex items-center justify-center font-Inter ${activeTab == "purple" ? activeClass : deActiveClass }  `} >
    <img src='/media/add-widgets/pink.svg' className='h-[15px]' />
    </div>
    <div onClick={()=>handleTab("green")} className={`w-1/5 h-[35px]  p-2 px-4 rounded-lg cursor-pointer flex items-center justify-center font-Inter ${activeTab == "green" ? activeClass : deActiveClass }  `} >
        <img src='/media/add-widgets/green.svg' className='h-[15px]'/>
    </div>
    <div onClick={()=>handleTab("orange")} className={`w-1/5 h-[35px] p-2 rounded-lg cursor-pointer flex items-center justify-center font-Inter ${activeTab == "orange" ? activeClass : deActiveClass }  `} >
        <img src='/media/add-widgets/orange.svg' className='h-[15px]'/>
    </div>
    <div onClick={()=>handleTab("blue")}  className={`w-1/5 h-[35px] p-2 rounded-lg cursor-pointer flex items-center justify-center font-Inter ${activeTab == "blue" ? activeClass : deActiveClass }  `} >
        <img src='/media/add-widgets/blue.svg' className='h-[15px]'/>
    </div>
    <div onClick={()=>handleTab("red")} className={`w-1/5 h-[35px] p-2 rounded-lg cursor-pointer flex items-center justify-center font-Inter ${activeTab == "red" ? activeClass : deActiveClass }  `} >
        <img src='/media/add-widgets/red.svg' className='h-[15px]'/>
    </div>
   
   
</div>
  )
}

export default ColorSelector