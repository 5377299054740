import LoginPage from "../modules/Authentication/login";
import RegisterPage from "../modules/Authentication/register";
import Email_Verification from "../modules/Authentication/email_veification";
import Forgot_Password from "../modules/Authentication/ResetPass/forgot-pass";
import { Navigate } from "react-router-dom";

import AuthApi from "../Services/Auth";


export const Auth  = ({children}) => {
  const token  = localStorage.getItem("token");
  if(token && AuthApi.isAuthenticatied()){
    return  <Navigate to="/create/dashboard" replace />;
  }
  else{
    return children
  }
}


export const AUTH_ROUTES = [
    {
      path:"/login",
      component:LoginPage
    },
    {
      path:"/register",
      component: RegisterPage
    },
   {
    path:"/email-verification",
    component: Email_Verification
   },
   {
    path:"/forgot-password",
    component: Forgot_Password
   }
]