import Layout from "../layout";
import ContactPage from "./main";

export default function ContactUs() {
  return (
    <div className="bg-black">
      <Layout>
        <ContactPage />
      </Layout>
    </div>
  );
}
