import React,{useRef} from 'react'
import { motion, AnimatePresence } from "framer-motion";
import  {useDrag} from "react-dnd"

const modalsAnimation = {
    hidden: {
      translateX: -100,
      opacity: 0,
      transition: {
        duration: 0.2,
        type: "spring",
        damping: 15,
      },
    },
    show: {
      translateX: 0,
      opacity: 100,
      transition: {
        duration: 0.2,
        type: "spring",
        damping: 15,
      },
    },
  };

const DragColumn =  ({ column,sourceID, isActive }) => {

    const columnRef = useRef()

    const name = column.value;

    const [{isDragging},drag] = useDrag({
      type:column?.dataType,
      item:{column,sourceID},
      collect: monitor => ({
        isDragging: !!monitor.isDragging(),
      }),
    })
  
    const splitName = () => {
      if (name.length > 18) {
        return name.substring(0, 18) + "...";
      } else {
        return name;
      }
    };
  
    // Styling While Dragging
    const styles = {
        border: isDragging ? '1px dashed blue ' : '   ', 
        cursor: 'grab',
        opacity: isDragging ? 0.5 : 1
      };

  

  return (
    <>
    <AnimatePresence>
      {isActive && (
        <motion.div
        style={styles}
        ref={drag}
        variants={modalsAnimation}
        initial="hidden"
        animate="show"
        exit="hidden"
        draggable={true}
          className="p-2 border-b-[1px]  border-new_gray_100  flex flex-row items-center gap-2"
        >
          {
            column.dataType == "string" ?
             <span className="text-[12px] font-Inter text-orange-500 " >ABC</span>
             :
             <span className="text-[12px] font-Inter text-green-500 " >123</span>
          }
         
          <span className="text-[12px] font-Inter py-1 hover:  ">
            {splitName()}
          </span>
        </motion.div>
      )}
    </AnimatePresence>
  </>
  )
}

export default DragColumn