import React from "react";
import { heroSection } from "../constant";
import ReusableButton from "../../../components/IntegrationButton";

const HeroSection = () => {
  const words = heroSection.title.split(" ");

  const firstTwoWords = words.slice(0, 1).join(" ");
  const middleWords = words.slice(1).join(" ");

  return (
    <section className="relative">
      <div
          className="
        absolute
        top-0
        left-0
        w-full
        md:block 
        h-[300px]
        lg:h-full
        bg-gradient-to-r
        from-[#060606]/0
        via-[#6938EF]/20
        to-[#060606]/0
        z-10
        "
        ></div>
     
        <div className="flex flex-col lg:flex-row justify-between items-center relative py-[24px] gap-5
        mx-auto w-[80%] sm:w-[90%] xs:w-[85%] md:w-[90%] lg:w-[85%] xl:w-[80%] max-w-6xl z-50">

          {/* Text Section */}
          <div className="relative flex flex-col w-full h-[350] md:w-[60%] lg:w-[40%] gap-5 z-50 ">

            <h1 className="text-[36px] md:text-4xl lg:text-7xl font-medium ">
              <span className="text-white lg:leading-tight">{firstTwoWords} </span>
              <span className="text-new_purple_600 lg:leading-tight">{middleWords}</span>
            </h1>
            <p className="text-gray-100 text-[14px] md:w-[390px]">
              {heroSection.description}
            </p>

            <ReusableButton
              label={heroSection.buttonText}
              className={
                "bg-new_purple_600 text-gray-100 rounded-lg w-full justify-center  lg:w-fit mt-4"
              }
            />
          </div>

          {/* Image Section */}
          <div className="relative h-[350] w-full md:w-[50%] flex justify-start lg:justify-end lg:self-end ">
            <img
              src={heroSection.dashboardimg}
              alt="Dashboard Image"
              className="relative z-20 h-[291px] w-[286px]  md:w-fit md:h-fit justify-self-start lg:justify-self-end  "
            />
           
          </div>
     
      </div>
    </section>
  );
};

export default HeroSection;
