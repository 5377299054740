import React, { useState, useEffect } from "react";
import { heading, kpiTemplates } from "../constant";
import TemplateCard from "./TemplateCard";
import { ChevronRight } from 'lucide-react';

const Template = () => {
  const words = heading.title.split(" ");

  const firstTwoWords = words.slice(0, 2).join(" ");
  const middleWords = words.slice(3).join(" ");
  const [visibleCount, setVisibleCount] = useState(9);

  useEffect(() => {
    const handleResize = () => {
      // Set visibleCount to 4 on mobile screens, otherwise 9
      if (window.innerWidth <= 768) {
        setVisibleCount(4);
      } else {
        setVisibleCount(9);
      }
    };

    // Add event listener
    window.addEventListener('resize', handleResize);

    // Remove event listener on cleanup
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <section className="flex flex-col items-center gap-[52px] py-10 lg:py-20 mx-auto w-[80%] sm:w-[90%] xs:w-[85%] md:w-[90%] lg:w-[85%] xl:w-[80%] h-[100%] max-w-6xl">
      {/* Heading */}
      <div className={`text-white flex flex-col items-left gap-7 `}>
        <p className="bg-custom_dark_pruple/20 text-custom_dark_pruple font-medium text-lg px-3 w-fit rounded-full text-center">
          {heading.heading}
        </p>
        <h1 className="text-2xl font-normal lg:text-5xl max-w-[700px] text-left ">
          <span className="text-white">{firstTwoWords} </span>
          <span className="text-gray-400">{middleWords}</span>
        </h1>
        <p className="text-white text-[14px] lg:text-[18px] font-normal text-left  ">
          {heading.description.map((desc, index) => (
            <span key={index} className="block mb-4">
              {desc}
            </span>
          ))}
        </p>
      </div>

      {/* Kpi Templates */}
      <div
              className="
              h-[2px] 
              bg-gradient-to-r
              from-[#52729F]/0
              via-[#1D2939]
              to-[#52729F]/0
              
              w-full
                "
      ></div>
      <div className="flex xl:justify-between flex-wrap ">
        {kpiTemplates.slice(0, visibleCount).map((kpi, index) => (
          <>
            <TemplateCard key={index} img={kpi.img} title={kpi.title} />
            {(index + 1) % 3 === 0 && index < kpiTemplates.length - 1 && (
              <div className="hidden lg:flex w-full h-[2px] bg-gradient-to-r from-[#52729F]/0 via-[#1D2939] to-[#52729F]/0 "></div>
            )}
            {/* Horizontal Line */}
            <div className="flex lg:hidden w-full h-[2px] bg-gradient-to-r from-[#52729F]/0 via-[#1D2939] to-[#52729F]/0 "></div>
            {/* Vertical Line */}
            {([0,1,3,4,6,7].includes(index) && (
              <div className="hidden lg:flex w-[2px] bg-gradient-to-b from-[#52729F]/0 via-[#1D2939] to-[#52729F]/0  "></div>
            ))}
       
          </>
        ))}
         
      </div>

       {/* Button to show more cards */}
       {visibleCount < kpiTemplates.length && (
        <button className="text-new_purple_600 flex items-center gap-1 text-[16px]" 
        onClick={() => setVisibleCount(kpiTemplates.length)}>
          View All Templates 
        <ChevronRight size={16} className="mt-[2px]"/></button>
      )}

     
    </section>
  );
};

export default Template;
