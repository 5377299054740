import React, { useRef, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { motion, AnimatePresence } from "framer-motion";
import { GetDataSources} from "../../../../../../Redux/dataSourcesSlice";
import Source from "./sources";
import SearchBar from "./searchBar";

const AllDataSource = ({isPopupActive,setCurrentTab}) => {
    const dispatch = useDispatch()

    const dataSources = useSelector((state)=>state.sources.dataSources)

    useEffect(()=>{
        dispatch(GetDataSources())
      },[isPopupActive])

  return (
    
       <div className="w-full h-full " >
         <div className="w-full h-[70px] flex items-center justify-between border-b-[1px]  ">
          <span className="font-Inter font-semibold text-[#101828] " >Connect Data Resource</span>
          <img src="/media/add-widgets/connect.svg"/>
        </div>

        <div className="w-full h-[calc(100%-70px)] flex gap-4 flex-wrap content-start	  " >
        <SearchBar/>
         {
           dataSources && dataSources.length > 0 && dataSources.map((item,index)=>{
             return(
                 <Source item={item}  setCurrentTab={setCurrentTab}/>
             )
           })
         }
          <div className="w-full h-[20px] block" ></div>
        </div>
       </div>

  )
}

export default AllDataSource