import React from 'react';
import { useSelector } from 'react-redux';
import { TrendingUp } from "lucide-react"
import { CartesianGrid, Line, LineChart, XAxis } from "recharts"

import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "../../../../../../components/ui/card"

import {
  ChartConfig,
  ChartContainer,
  ChartTooltip,
  ChartTooltipContent,
} from "../../../../../../components/ui/chart"



const chartConfig = {
  desktop: {
    label: "Desktop",    
  },
} 

const TableChart = () => {


  const graphData = useSelector((state) => state.charts.lineGraph);
  const isLoading = useSelector((state) => state.charts.isLineGraphLoading);
  
  let chartData = graphData?.records;
  let lineKeys = graphData?.keys;

  let newArray = [];

  chartData?.forEach((item,index)=>{

    if (index == 0){
      return false
    }
    newArray.push(item)
  })



  return (
    <div className="w-full h-full flex items-center justify-center">
      <Card className="w-[80%] h-[100%]">
        <CardHeader>
          <CardTitle>Sales</CardTitle>
          <CardDescription>January - December 2024</CardDescription>
        </CardHeader>
        <CardContent>
          <ChartContainer config={chartConfig }>
            <LineChart
              data={newArray && newArray}
              margin={{
                left: 12,
                right: 12,
              }}
            >
              <CartesianGrid vertical={false} />
              <XAxis
                dataKey="name" // Update to match your data
                tickLine={false}
                axisLine={false}
                tickMargin={8}
              />
              
              <ChartTooltip
                cursor={false}
                content={<ChartTooltipContent hideLabel />}
              />
           
               {lineKeys?.map((key, index) => (
                <Line
                  key={`line-${index}`}
                  dataKey={key}
                  type="monotone"
                  stroke={index % 2 === 0 ? "#8884d8" : "#82ca9d"} // Alternating colors for lines
                  strokeWidth={2}
                  dot={false}
                />
              ))}
              
            </LineChart>
          </ChartContainer>
        </CardContent>
        {/* <CardFooter className="flex-col items-start gap-2 text-sm">
          <div className="flex gap-2 font-medium leading-none">
            Trending up by 5.2% this month <TrendingUp className="h-4 w-4" />
          </div>
          <div className="leading-none text-muted-foreground">
            Showing total visitors for the last 6 months
          </div>
        </CardFooter> */}
      </Card>
    </div>
  )
}

export default TableChart;
