export const heading = {
    title: "Help Center",
    description: "We prioritize excellent customer service and support. Our Help Center is designed to efficiently address your questions and concerns, whether it's technical difficulties, order assistance, or product inquiries."
}

export const faqData = [
    {
        category: 'How to start?',
        articles:[
            {
                id:1,
                title:'How to create an account?',
                description: "To create an account, simply click on the \"Sign Up\" or \"Register\" button on our website's homepage. Follow the prompts to enter your information and set up your account. Creating an account allows you to track your orders, save your shipping information for future purchases, and access exclusive member discounts and promotions.",
                link: '/helpcenter/article/1'
            },
            {
                id:2,
                title:'How to place an order?',
                description: "To place an order, simply browse our website and add the items you want to purchase to your shopping cart. Once you're ready to check out, click on the shopping cart icon and follow the prompts to enter your shipping and payment information. Once your order is confirmed, you will receive an email with your order details and tracking information.",
                link: '/helpcenter/article/2'
            },
            {
                id:3,
                title:'How to track an order?',
                description: "To track your order, simply log in to your account and go to the \"Orders\" section. You will see a list of all your orders and their current status. Click on the order you want to track to view more details, including the tracking number and estimated delivery date.",
                link: '/helpcenter/article/3'
            },
            {
                id:44,
                title:'How to track an order?',
                description: "To track your order, simply log in to your account and go to the \"Orders\" section. You will see a list of all your orders and their current status. Click on the order you want to track to view more details, including the tracking number and estimated delivery date.",
                link: '/helpcenter/article/3'
            },
            {
                id:45,
                title:'How to track an order?',
                description: "To track your order, simply log in to your account and go to the \"Orders\" section. You will see a list of all your orders and their current status. Click on the order you want to track to view more details, including the tracking number and estimated delivery date.",
                link: '/helpcenter/article/3'
            }
        ]
    },
    {
        category: 'Account setting',
        articles:[
            {
                id:4,
                title:'How to change password?',
                description: "To change your password, simply log in to your account and go to the \"Account Settings\" section. Click on the \"Change Password\" button and follow the prompts to enter your current password and set a new one. Make sure to choose a strong password that is unique to your account to keep it secure.",
                link: '/helpcenter/article/4'
            },
            {
                id:5,
                title:'How to update account information?',
                description: "To update your account information, simply log in to your account and go to the \"Account Settings\" section. Click on the \"Edit Profile\" button and follow the prompts to update your information, such as your name, email address, and shipping address. Make sure to save your changes before exiting the page.",
                link: '/helpcenter/article/5'
            },
            {
                id:6,
                title:'How to delete account?',
                description: "To delete your account, simply log in to your account and go to the \"Account Settings\" section. Click on the \"Delete Account\" button and follow the prompts to confirm your decision. Please note that deleting your account is irreversible and will result in the loss of all your account information and order history.",
                link: '/helpcenter/article/6'
            }
        ]
    },
    {
        category: 'Data safety',
        articles: [
            {
                id:7,
                title:'How to secure account?',
                description: "To secure your account, make sure to choose a strong password that is unique to your account. Avoid using common passwords or sharing your password with others. You can also enable two-factor authentication for an extra layer of security. If you suspect any unauthorized activity on your account, contact our customer service team immediately.",
                link: '/helpcenter/article/7'
            },
            {
                id:8,
                title:'How to secure payment?',
                description: "To secure your payment information, make sure to only use secure payment methods, such as credit cards or PayPal. Avoid sharing your payment information with others or entering it on unsecured websites. If you suspect any fraudulent activity on your account, contact your bank or credit card company immediately.",
                link: '/helpcenter/article/8'
            },
            {
                id:9,
                title:'How to secure personal information?',
                description: "To secure your personal information, make sure to only share it with trusted websites and companies. Avoid entering your personal information on unsecured websites or responding to unsolicited requests for information. If you suspect any fraudulent activity involving your personal information, contact the appropriate authorities immediately.", 
                link: '/helpcenter/article/9'
            }
        ]
    },
    {
        category: 'Payment',
        articles: [
            {
                id:10,
                title:'How to pay?',
                deescription: "To pay",
                link: '/helpcenter/article/10'
            },
            {
                id:11,
                title:'How to refund?',
                description: "To refund",
                link: '/helpcenter/article/11'
            },
            {
                id:12,
                title:'How to cancel order?',
                description: "To cancel",
                link: '/helpcenter/article/12'
            }
        ]
    }
]