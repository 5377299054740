import dashboard from '../../assets/Dashboard/dashboard.png'
import statistics from '../../assets/Dashboard/statistics.png'
import social from '../../assets/Dashboard/social.png'
import warehouse from '../../assets/Dashboard/warehouse.png'
import ecommerce from '../../assets/Dashboard/ecommerce.png'
import multibrand from '../../assets/Dashboard/multibrand.png'

export const heroSection = {
    title: 'Dashboard Examples and Templates',
    description: 'Over 60 business dashboards for Executives, Sales, Marketing, Social Media, and more. Check out this gallery to get inspired in building your own today!',
    buttonText: 'Let’s See',
    dashboardimg: dashboard,
    statisticsimg: statistics
}

export const heading = {
    heading: "Dashboard Template",
    title: "Ways to utilize these business dashboard reporting examples",
    description: "The KPI report setup ideas provided in this section stem from business dashboard examples. Each example has been carefully selected by the Klipfolio team, drawing on their extensive experience of over 15 years in the data and analytics field."
}

export const TemplateCardData = [
    {
        icon: social,
        heading: 'Dashboard Template',
        title: 'Social Media Dashboard',
        description: 'Find out why over 10,000 customers trust us to enhance their decision-making process by utilizing our executive dashboards, which offer a robust method to monitor business performance. Join our social media community and unlock the potential to make informed decisions that drive your success.'
    },
    {
        icon: warehouse,
        heading: 'Dashboard Template',
        title: 'Warehouse Dashboards',
        description: 'Executive dashboards provide a powerful way to monitor your business performance. Discover why 12,000+ customers rely on us to make better decisions.'
    },
    {
        icon: ecommerce,
        heading: 'Dashboard Template',
        title: 'Ecommerce KPI Dashboards',
        description: 'Bring KPIs together for online sellers so they can optimize performance.'
    },
    {
        icon: multibrand,
        heading: 'Dashboard Template',
        title: 'Ecommerce Multi-brand Management',
        description: 'Gives Shopify merchants key metrics for an overview of online store performance.'
    }
]