import React, { useState } from 'react'
import Alert from '../../components/Alert'
import AuthApi from '../../Services/Auth'
import { useNavigate } from 'react-router-dom';
import  Spinner from "../../components/spinner"

const RegisterFrom = () => {

  const navigate = useNavigate()
  
  const [isError,setError] = useState(false)
  const [isLoading,setLoading] = useState(false)
  const [message,setMessage] = useState("")
  const [form,setForm] = useState({
    fullName:"",
    email:"",
    password:""
  })

  //Handle Input Value
  const handleInput = (event) => {

      const {value,name} = event.target;

      setForm({
        ...form,
        [name]:value
      })
  }

  const handleRegister = async (e) => {
    setError(false)
    e.preventDefault();
    const {email,password} = form;
    const passwordRegex = /^(?=.*\d).{8,}$/;

    if (!passwordRegex.test(form.password)) {
      setMessage("Password must be at least 8 characters long and include a number") 
      setError(true)
      return; 
    }
    else{
      setError(false)
      setMessage("")
      setLoading(true)

      try {
         await AuthApi.userRegistration(form);
         navigate('/email-verification', { state: { email, password } })
         setLoading(false)
         setError(false);
      } catch (error) {
         setLoading(false)
         setForm(prev=>({...prev,password:""}))
         setError(true)
         setMessage(error?.response?.data?.error);
         console.log(error,"ERROR OCCUR")

      }
  } 
}

  const disabled = !form.fullName || !form.email || !form.password

  return (
    <div className='w-full flex flex-col gap-6  ' >

      {
        isError  &&
        <Alert
         message={message && message}
         closeAction={() => setError(false)}
      />
      }
      
      { /***Input Full Name */}
      <div className='w-full flex flex-col gap-1' >
       <span className='text-[#344054] font-Inter text-[14px] font-[500]' >Full Name</span>
       <input onChange={handleInput} type='text'  placeholder='Your Name' value={form.fullName} name='fullName' required className='px-3 py-2 border-[1px] focus:border-new_purple_500 border-[#D0D5DD] shadow-[shadow_inputs]  outline-none rounded-md' />
      </div>
      { /***Input Email***/}
      <div className='w-full flex flex-col gap-1' >
       <span className='text-[#344054] font-Inter text-[14px] font-[500]' >Email</span>
       <input onChange={handleInput} type='email' placeholder='example@gmail.com' value={form.email} name='email' required className='px-3 py-2 border-[1px] focus:border-new_purple_500 border-[#D0D5DD] shadow-[shadow_inputs]  outline-none rounded-md' />
      </div>
      { /***Input Password ***/}
      <div className='w-full flex flex-col gap-1' >
       <span className='text-[#344054] font-Inter text-[14px] font-[500]' >Password</span>
       <input onChange={handleInput} type='password' placeholder='*****************' value={form.password} name='password' required className='px-3 py-2  border-[1px] focus:border-new_purple_500 border-[#D0D5DD] shadow-[shadow_inputs]  outline-none rounded-md' />
      </div>
      {/**SIGN UP Button**/}
      <div className='w-full flex flex-col gap-1' >
       <button disabled={disabled || isLoading } onClick={handleRegister} className={`p-3 disabled:cursor-not-allowed bg-new_purple_600 ${disabled ?"bg-hovered_new_purple_600":"hover:bg-hovered_new_purple_600 active:scale-95"}  transition-all text-white font-Inter rounded-md `} >
        {
          isLoading ?
          <Spinner/>
          :
          "SIGN UP"
        }
       </button>
      </div>
      {/**Navigate**/}
      <span className='flex items-center justify-center flex-row gap-1' >
        <p className='font-Inter font-normal text-[14px] text-new_gray_500 ' >Already have account?</p>
        <p onClick={()=>navigate("/login")} className='font-Inter font-normal text-[14px] cursor-pointer text-new_purple_600 ' >Login</p>
      </span>
      {/**Extra not**/}
      <div className='flex items-center justify-center flex-row gap-1 flex-wrap ' >
        <img src='/media/login/check.svg'/>
        <p className='font-Inter font-normal text-[10px] text-new_gray_500'  >By signing up, you're agreeing to the</p>
        <p className='font-Inter  font-semibold text-[10px] text-new_purple_600'  >Terms of Service</p>
        <p className='font-Inter font-normal text-[10px] text-new_gray_500'  >and</p>
        <p className='font-Inter  font-semibold text-[10px] text-new_purple_600'  >Privacy policy</p>
        <p className='font-Inter font-normal text-[10px] text-new_gray_500'  >on our website</p>
      </div>
    </div>
  )
}

export default RegisterFrom