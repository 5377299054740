import React, { useEffect } from 'react'
import { useParams,useNavigate,useLocation } from 'react-router-dom'
import { useSelector,useDispatch } from 'react-redux'
import { setSideBarOpen } from '../../../../../Redux/SheetSlice'
import { DataCalculation } from '../../../../../Redux/SheetSlice'
import SheetTable from './sheetable'
import FormulasBar from './formulasBar'

const SelectData = () => {

  const params = useParams()
  const navigate = useNavigate()
  const { state } = useLocation();
  const dispatch = useDispatch()

  console.log(state,"state")

  const {id} = params;
  

    const isSideBarOpen = useSelector((state)=>state.sheet.isSidebarOpen)
    const selectedColumn = useSelector((state)=>state.sheet.selectedColumnData)
    const selectedFormula = useSelector((state)=>state.sheet.selectedFormula)

  useEffect(()=>{
    if(!state?.dataId){
      navigate("/dashboard/add-widgets")
    }
  },[navigate, state])


  const handleSideBar = () => {
    dispatch(setSideBarOpen())
  }

  const handleContinue = () => {
    if (!selectedColumn || !selectedFormula) {
        return;
    }

    let formula;
    switch (selectedFormula) {
        case "Sum off..":
            formula = "SUM";
            break;
        case "Count of Row":
            formula = "COUNT";
            break;
        case "Minimum":
            formula = "MIN";
            break;
        case "Maximum":
            formula = "MAX";
            break;
        case "Distinct Count":
            formula = "DISTINCT_COUNT";
            break;     
    }

    const payload = {
        dataId: selectedColumn.dataId,
        column: selectedColumn.column,
        formula: formula
    };



    dispatch(DataCalculation(payload)).then((resp)=>{

      const dashboardId = state.dashboardId
      console.log(dashboardId)

      if(resp.payload){
        const payload = resp.payload
        navigate(`${window.location.pathname}/create-widget`, { state:{payload,dashboardId:dashboardId}})
      }
      else{
        return
      }

    });
};


  return (
   <>
    {
      state?.dataId &&
      <div className='w-full h-[calc(100vh-70px)] bg-background_color  flex flex-col bg-background_color overflow-y-auto ' >
      {/* <div className='w-full h-[calc(100vh-70px)] flex flex-col bg-new_gray_200 overflow-y-auto ' > */}
       <div className='w-full h-auto flex flex-col px-14 pt-6 gap-6  ' >
         {/***Continue Option*/}
         <div className='w-full h-[50px] flex items-center justify-between'  >
          <div className='flex flex-row gap-2' >
            <h2 className='font-Inter text-[17px] text-black font-bold ' >Pnl Statement.xls</h2>
            <img src='/media/add-widgets/chevron-down-bold.svg'/>
          </div>
          <div className='flex gap-4 ' >
          <button onClick={handleSideBar} className='flex flex-row gap-2 items-center justify-center text-new_purple_600 hover:text-white font-Inter font-semibold border-[1px] border-new_purple_600 hover:bg-new_purple_500 active:scale-110 transition-all px-8 py-2 rounded-md  ' >
             Sumarize
          </button>
          <button onClick={handleContinue} className='flex flex-row gap-2 items-center justify-center bg-new_purple_600 hover:bg-new_purple_500 active:scale-110 transition-all px-4 py-2 rounded-md  ' >
             <img src='/media/add-widgets/plus.svg'/>
             <span className='text-white font-Inter' >Continue</span>
          </button>
          </div>
         </div>
      
       </div>
       <div className='w-full h-[calc(100vh-180px)] flex flex-row px-14 gap-4  mt-[20px] '  >
          <SheetTable isSideBarOpen={isSideBarOpen} id={id} />
          <FormulasBar isSideBarOpen={isSideBarOpen} id={id} />
       </div>
       <div className='w-full h-[10px] block' >

       </div>

     </div>
    }
   </>
  )
}

export default SelectData