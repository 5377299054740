export default function DashboardBuilder(
    playGroundRef,
    playGroundDiemensionsSetter,
    aspectRatio = 9 / 16,
    cellHighlighter
) {

    // let virtualCellSize = 12;
    let virtualCell = {
        highlightCell: cellHighlighter.current,
        total: 12,
        gap: 8
    };

    let dashboardWrapper = {
        node: playGroundRef.current,
        width: 0,
        playground: {
            node: null,
            rect: null
        },
    };

    let widget = {
        id: false, 
        node: null,
        shiftX: null,
        shiftY: null,
        isDragging: false,
        isMoving: false,
        index: false // this property is used to store the index number of the model inside the models array.
    };

    let cellWidth = 0;
    let cellHeight = 0;

    const widgets = [];

    const initPlayground = () => {
        let diemensions = {};

        diemensions.w = Math.ceil(playGroundRef.current.getClientRects()[0].width * 100) / 100;
        
        diemensions.h = (Math.ceil((diemensions.w * aspectRatio) * 100) / 100);

        console.log(diemensions);

        playGroundDiemensionsSetter(prevStat => ({
            ...prevStat,
            ...diemensions
        }));
    }

    const setDashboardWrapperPlayground = () => {
        dashboardWrapper = {
            node: playGroundRef.current,
            width: playGroundRef.current.getClientRects()[0].width,
            playground: {
                node: playGroundRef.current.firstChild,
                rect: playGroundRef.current.firstChild.getBoundingClientRect()
            }
        };

        // Calculate the grid cell size
        cellWidth = dashboardWrapper.playground.rect.width / virtualCell.total;
        cellHeight = dashboardWrapper.playground.rect.height / virtualCell.total;
    }

    const allowDrop = (event) => {
        event.preventDefault();
    }

    const dropWidget = (event) => {
        event.preventDefault();
        widget.isDragging = false;
        console.log("dropping...");
    }

    const dragWidget = (event) => {
        event.preventDefault();

        if (event.target.classList.contains("resizeer")) return false;

        setDashboardWrapperPlayground();

        let target = event.target.classList.contains("widget") ?
            event.target
            :
            event.target.closest(".widget");

        widget.id = target.dataset.id;
        widget.node = target;
        widget.index = 0;
        widget.shiftX = (event.clientX - widget.node.getBoundingClientRect().left);
        widget.shiftY = (event.clientY - widget.node.getBoundingClientRect().top);
        widget.isDragging = true;

        widget.node.style.position = "absolute";

        // Get the boundaries of the dashboardWrapper
        const widgetRect = widget.node.getBoundingClientRect();

        // Calculate the grid cell dimensions that fit the widget's dimensions
        const virtualCellWidth = parseFloat(widgetRect.width.toFixed(2));
        const virtualCellHeight = parseFloat(widgetRect.height.toFixed(2));

        // Set virtual cell positions and diemensions
        virtualCell.highlightCell.style.width = `${virtualCellWidth}px`;
        virtualCell.highlightCell.style.height = `${virtualCellHeight}px`;

        virtualCell.highlightCell.style.left = `${widget.node.offsetLeft}px`;
        virtualCell.highlightCell.style.top = `${widget.node.offsetTop}px`;

        document.addEventListener('mousemove', moveWidget, false);
        document.addEventListener('mouseup', endDragging, false);
    }

    const moveWidget = (event) => {
        if (!widget.isDragging) return false;

        widget.isMoving = true;

        // Calculate the new position based on mouse movement
        let newLeft = event.pageX - widget.shiftX - dashboardWrapper.playground.rect.left;
        let newTop = event.pageY - widget.shiftY - dashboardWrapper.playground.rect.top;

        // Get the boundaries of the dashboardWrapper
        const widgetRect = widget.node;

        // Calculate the max values for left and top to prevent overflow
        let maxLeft = dashboardWrapper.playground.node.offsetWidth - widgetRect.offsetWidth;
        let maxTop = dashboardWrapper.playground.node.offsetHeight - widgetRect.offsetHeight;

        maxLeft = parseFloat(maxLeft.toFixed(2));
        maxTop = parseFloat(maxTop.toFixed(2));

        // Restrict newLeft and newTop to be within boundaries
        newLeft = Math.max(0, Math.min(newLeft, maxLeft));
        newTop = Math.max(0, Math.min(newTop, maxTop));

        newLeft = parseFloat(newLeft.toFixed(2));
        newTop = parseFloat(newTop.toFixed(2));

        // Apply the new position
        widget.node.style.left = `${newLeft}px`;
        widget.node.style.top = `${newTop}px`;

        let virtualCellWidth = customRound(widgetRect.offsetWidth / cellWidth) * cellWidth;
        let virtualCellHeight = customRound(widgetRect.offsetHeight / cellHeight) * cellHeight;

        virtualCellWidth = parseFloat(virtualCellWidth.toFixed(2));
        virtualCellHeight = parseFloat(virtualCellHeight.toFixed(2));

        // Calculate the nearest grid cell
        let snapLeft = customRound(newLeft / cellWidth) * cellWidth;
        let snapTop = customRound(newTop / cellHeight) * cellHeight;

        snapLeft = parseFloat(snapLeft.toFixed(2));
        snapTop = parseFloat(snapTop.toFixed(2));

        // Check for collision with other widgets
        const isCollision = widgets.some(otherWidget => {
            if (otherWidget.node === widget.node) return false; // Skip self

            const otherRect = otherWidget.node;
            const otherLeft = otherRect.offsetLeft;
            const otherTop = otherRect.offsetTop;
            const otherRight = otherLeft + otherRect.offsetWidth;
            const otherBottom = otherTop + otherRect.offsetHeight;

            let snapRight = snapLeft + virtualCellWidth;
            let snapBottom = snapTop + virtualCellHeight;

            snapRight = parseFloat(snapRight.toFixed(2));
            snapBottom = parseFloat(snapBottom.toFixed(2));

            // Check if snapping area is overlapping with other widgets
            if (
                ((snapLeft + 5) < otherRight) &&
                ((snapRight - 5) > otherLeft) &&
                ((snapTop + 5) < otherBottom) &&
                ((snapBottom - 5) > otherTop)
            )
                return true;
        });

        if (!isCollision) {
            // No collision, apply the new position
            virtualCell.highlightCell.style.left = `${snapLeft}px`;
            virtualCell.highlightCell.style.top = `${snapTop}px`;
        }

        virtualCell.highlightCell.style.display = 'block';
    }

    const endDragging = (event) => {

        if (!widget.isDragging) return false;

        document.removeEventListener('mousemove', moveWidget, false);

        widget.isDragging = false;

        if (!widget.isMoving) return false;

        widget.isMoving = false;

        widget.node.style.left = `${virtualCell.highlightCell.style.left}`;
        widget.node.style.top = `${virtualCell.highlightCell.style.top}`;

        // Update widget's position in the widgets list
        const existingWidgetIndex = widgets.findIndex(w => w.node === widget.node);

        if (existingWidgetIndex !== -1) {
            widgets[existingWidgetIndex] = { ...widget };
        } else {
            widgets.push({ ...widget });
        }

        virtualCell.highlightCell.style.display = "none";
        virtualCell.highlightCell.style.border = "none";

    }


    const resizeWidget = (event) => {
        if (!widget.isResizing) return;

        let newWidth = Math.max(
            cellWidth,
            Math.ceil((event.pageX - widget.node.getBoundingClientRect().left) / cellWidth) * cellWidth
        );
        let newHeight = Math.max(
            cellHeight,
            Math.ceil((event.pageY - widget.node.getBoundingClientRect().top) / cellHeight) * cellHeight
        );

        newWidth = parseFloat(newWidth.toFixed(2));
        newHeight = parseFloat(newHeight.toFixed(2));

        const widgetRect = widget.node;

        let snapLeft = widgetRect.offsetLeft;
        let snapTop = widgetRect.offsetTop;
        let snapRight = snapLeft + newWidth;
        let snapBottom = snapTop + newHeight;

        snapRight = parseFloat(snapRight.toFixed(2));
        snapBottom = parseFloat(snapBottom.toFixed(2));

        let collisionOnX = false;
        let collisionOnY = false;

        // Check if the resized widget overlaps with other widgets
        const isCollision = widgets.some(otherWidget => {
            if (otherWidget.node === widget.node) return false;

            const otherRect = otherWidget.node;
            const otherLeft = otherRect.offsetLeft;
            const otherTop = otherRect.offsetTop;
            const otherRight = otherLeft + otherRect.offsetWidth;
            const otherBottom = otherTop + otherRect.offsetHeight;

            // Check for X-axis collision (horizontal overlap)
            const overlapOnX = ((snapLeft + 5) < otherRight) && ((snapRight - 5) > otherLeft);
            
            // Check for Y-axis collision (vertical overlap)
            const overlapOnY = ((snapTop + 5) < otherBottom) && ((snapBottom - 5) > otherTop);

            // Determine if the collision occurs only on the X or Y axis
            if (overlapOnX && overlapOnY) {
                if (newWidth > widget.node.offsetWidth) {
                    collisionOnX = true;
                }
                if (newHeight > widget.node.offsetHeight) {
                    collisionOnY = true;
                }
            }

            // Return true if there's a collision on either axis
            return overlapOnX && overlapOnY;
        });

        if (!collisionOnX)
            widget.node.style.width = `${newWidth}px`;

        if (!collisionOnY)
            widget.node.style.height = `${newHeight}px`;
    };

    const startResizing = (event) => {
        event.preventDefault();
        
        widget.id = event.target.closest(".widget").dataset.id;
        widget.node = event.target.closest(".widget");
        widget.index = 0;
        widget.shiftX = (event.clientX - widget.node.getBoundingClientRect().left);
        widget.shiftY = (event.clientY - widget.node.getBoundingClientRect().top);
        widget.isDragging = false;
        widget.isResizing = true;

        widget.node.style.border = "1px solid blue";

        document.addEventListener('mousemove', resizeWidget, false);
        document.addEventListener('mouseup', endResizing, false);
    };

    const endResizing = () => {
        widget.isResizing = false;
        document.removeEventListener('mousemove', resizeWidget, false);
        document.removeEventListener('mouseup', endResizing, false);

        widget.node.style.border = "4px solid white";
    };

    const customRound = (value) => {
        let decimalPart = value - Math.floor(value);
        let firstDecimalDigit = Math.floor(decimalPart * 10);
        return firstDecimalDigit <= 5 ? Math.floor(value) : Math.ceil(value);
    }
    

  return {
    initPlayground,
    allowDrop,
    dropWidget,
    dragWidget,
    moveWidget,
    endDragging,
    startResizing
  };
}
