import React from "react";

const TemplateCard = ({ card, values }) => {
  // Determine the order of the image and text based on the key
 
  const isOdd = values % 2 !== 0;
  const orderForImage = isOdd ? "lg:order-last" : "";
  const orderForText = isOdd ? "" : "lg:order-last";

  return (
    <div className="flex flex-col lg:flex-row items-center gap-[20px] py-[52px] lg:gap-[60px]">

      <div className={`text-white flex flex-col items-start gap-7 max-w-[370px] lg:max-w-[955px] ${orderForText}`}>
        <p className="bg-custom_dark_pruple/20 text-custom_dark_pruple font-medium text-lg px-3 rounded-full text-center">
          {card.heading}
        </p>
        <h1 className="text-2xl font-medium lg:text-5xl text-left">
          {card.title}
        </h1>
        <p className="text-gray-300 text-lg font-normal text-left">
          {card.description}
        </p>
      </div>

      <img
        src={card.icon}
        alt="icon"
        className={`w-full lg:w-auto ${orderForImage}`}
      />
    </div>
  );
};

export default TemplateCard;
