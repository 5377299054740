import React, { useState } from 'react'
import Alert from '../../../components/Alert';
import Spinner from '../../../components/spinner';
import AuthApi from '../../../Services/Auth';

const ChangePassword = ({STATUS_TYPE,setForm,form}) => {

    const [isError,setError] = useState(false);
    const [message,setMessage] = useState("");
    const [isLoading,setLoading] = useState(false);

    const [password,setPassword] = useState({
        newPassword:"",
        confirmPassword:""
    })

    const handleInput = (e) => {

        const {value,name} = e.target;

        setPassword({
            ...password,
            [name]:value
        })
    }

    const handleChangePassword = async () => {

        const passwordRegex = /^(?=.*\d).{8,}$/;

        const payload = {
          email:form.email,
          password:password.newPassword,
          verifyCode:form.verifyCode
        }

        if (!passwordRegex.test(password.newPassword)) {
          setMessage("Password must be at least 8 characters long and include a number") 
          setError(true)
          return; 
        }
      
        else if(password.newPassword !== password.confirmPassword){
            setError(true)
            setMessage("Your New and Current Password doesnt match")         
            return 
        }
        else{
            setLoading(true)
            setError(false)
            setMessage(" ")

            try {
              await AuthApi.changePassword(payload);
              setError(false)
              setMessage(" ")
              setLoading(false)
              setForm((prev)=>({
                ...prev,
                status:STATUS_TYPE.SUCCESS
              }))
            } catch (error) {
              setLoading(false)
              setPassword(prev=>({...prev,newPassword:" ",confirmPassword:" "}));
              setError(true)
              setMessage(error?.response?.data?.error)
            }
                  
        }
    }

const disabled = false
  return (
    <div className=" w-[70%] max-w-[550px]  flex flex-col items-center gap-6 ">
    {/**Logo Baiynah**/}
    <div className="w-full flex flex-col gap-3 items-center p-4  ">
      <img
        src="/media/login/baiynah-logo.svg"
        className="mb-[10px] mt-[20px] "
      />
      <h2 className=" sm:text-[22px] 2xl:text-[30px] font-Inter font-semibold ">
      Reset Password
      </h2>
      {/* <span className="sm:text-[14px] 2xl:text-[16px] font-Inter font-normal text-center text-[new_gray_700] text-[#344054] ">
      We suggest using the email adress you use at wok.
      </span> */}
    </div>
   
    {/**SIGN UP FORM **/}
    <div className='w-full  flex flex-col gap-6  ' >
      {
        isError &&
        <Alert
         message={message}
         closeAction={() => setError(false)}
        />
      
      }
     <div className='w-full flex flex-col gap-1' >
        <span className='text-[#344054] font-Inter text-[14px] font-[500]' >New Password</span>
        <input onChange={handleInput} name='newPassword' type='password' value={password.newPassword} placeholder='Enter a strong password' className='px-3 py-2 border-[1px] border-[#D0D5DD] shadow-[shadow_inputs] focus:border-new_purple_500  outline-none rounded-md' />
     </div>
     <div className='w-full flex flex-col gap-1' >
        <span className='text-[#344054] font-Inter text-[14px] font-[500]' >Confirm Password</span>
        <input onChange={handleInput}  name='confirmPassword' type='password' value={password.confirmPassword} placeholder='Enter again your password' className='px-3 py-2 border-[1px] border-[#D0D5DD] shadow-[shadow_inputs] focus:border-new_purple_500  outline-none rounded-md' />
     </div>
    </div>
  {/**Button**/}
    <div className='w-full flex flex-col ' >
      <button  onClick={handleChangePassword}  className={`p-3 bg-new_purple_600 disabled:cursor-not-allowed disabled:bg-hovered_new_purple_600 hover:bg-hovered_new_purple_600 ${!disabled && "active:scale-95" } transition-all text-white font-Inter rounded-md `} >
       
       {
          isLoading ?
          <Spinner/>
          :
          "Change Password"
       }
      </button>
      <div className='flex flex-row gap-1 items-center justify-center mt-[30px] ' >
          <span className='text-[16px] font-Inter text-new_gray_500 hover:text-new_purple_600 cursor-pointer' >Back to Login</span>       
      </div>
    </div>
    <div className="w-full h-[30px] block"></div>
    </div>
  )
}

export default ChangePassword