import { createSlice } from '@reduxjs/toolkit'
import { createAsyncThunk } from '@reduxjs/toolkit';
import Dashboard from '../Services/dashboard'

const initialState = {
    isCreateDashboarLoading:false,
    isDashbaordsLoading:false,
    userDashboards:[],
    dashboardData:{},
    isSingleDashbaordLoading:false,
    isDashboardSaveLoading:false
}

export const CreateDashboard = createAsyncThunk(`create-dashboard`,async(payload)=>{
    const res = await Dashboard.createDashboard(payload)
    return res;
})


export const GetAllDashboard = createAsyncThunk(`get-all-dashboard`,async()=>{
    const res = await Dashboard.getAllDashboads()
    return res;
})

export const GetSingleDashboard = createAsyncThunk(`get-single-dashboard`,async(params)=>{
    const res = await Dashboard.getSingleDashboard(params);
    return res;
})

export const UpdateDashbaord = createAsyncThunk(`update-dashboard`,async(payload)=>{
  const res = await Dashboard.updateDashboard(payload);
  return res;
})


export const DashboardSlice = createSlice({
  name: 'Dashboard',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(CreateDashboard.pending,(state,action)=>{
           state.isCreateDashboarLoading = true
      })
      .addCase(CreateDashboard.fulfilled,(state,action)=>{
        state.isCreateDashboarLoading = false

      })
      .addCase(CreateDashboard.rejected,(state,action)=>{
        state.isCreateDashboarLoading = false

      })

      // Get All Dashbaords

      .addCase(GetAllDashboard.pending,(state,action)=>{
        state.isDashbaordsLoading = true
      })
      .addCase(GetAllDashboard.fulfilled,(state,action)=>{
        state.isDashbaordsLoading = false
        state.userDashboards = action.payload

      })
      .addCase(GetAllDashboard.rejected,(state,action)=>{
        state.isDashbaordsLoading = false

      })

      // Get Single Dashboard

      .addCase(GetSingleDashboard.pending,(state,action)=>{
        state.isSingleDashbaordLoading = true
      })
      .addCase(GetSingleDashboard.fulfilled,(state,action)=>{
        state.isSingleDashbaordLoading = false;
        state.dashboardData = action.payload
      })
      .addCase(GetSingleDashboard.rejected,(state,action)=>{
        state.isSingleDashbaordLoading = false
      })

      .addCase(UpdateDashbaord.pending,(state,action)=>{
        state.isDashboardSaveLoading = true
      })
      .addCase(UpdateDashbaord.fulfilled,(state,action)=>{
        state.isDashboardSaveLoading = false;
      })
      .addCase(UpdateDashbaord.rejected,(state,action)=>{
        state.isDashboardSaveLoading = false
      })

   
  },
})

export const {} = DashboardSlice.actions

export default DashboardSlice.reducer