import line from "../../assets/LandingPage/line.svg";
import dots from "../../assets/LandingPage/dots.svg";
import star from "../../assets/LandingPage/star.svg";
import sparks from "../../assets/LandingPage/sparks.svg";

const KPIVisualizationBanner = () => {
  return (
    <div className="p-2 bg-black overflow-y-hidden overflow-x-hidden max-w-[1216px] mx-auto">
      <div className="bg-black text-white py-12 sm:px-8 font-Inter">
        <div className="flex flex-col items-center text-center space-y-4">
          <div className="mb-6 text-center flex items-center justify-center">
            <div className="mb-6 text-center flex items-center justify-center">
              <img
                src="/media/login/baiynah-white.svg"
                alt="Bayinah logo"
                className="h-12 w-12"
              />
              <div className="border-l-2 border-white h-12 ml-2 mr-2"></div>
              <div className="mt-2">
                <span className="block text-xs sm:text-sm">
                  Visualize Your Data
                </span>
                <span className="block text-xs sm:text-sm font-medium">
                  With Bayinah
                </span>
              </div>
            </div>
          </div>

          <p className="text-xl sm:text-2xl md:text-3xl lg:text-4xl xl:text-5xl 2xl:text-6xl font-semibold relative flex items-center z-30">
            <img
              src={dots}
              alt=""
              className="hidden md:block w-4 md:w-6 lg:w-8 mr-0 md:mr-0.5 lg:mr-2"
            />
            Boost Your Team Performance With
          </p>
          <img src={sparks} alt="" className="absolute z-10 top-[320px]" />
          <img
            src={"/media/chart.png"}
            alt=""
            className="hidden lg:flex w-[400px] h-[400px] -rotate-[15deg] absolute lg:-left-[100px] lg:-bottom-[150px] z-10 opacity-50 xl:left-[-50px] xl:-bottom-[160px]"
          />
          <img
            src={"/media/active.png"}
            alt=""
            className="hidden lg:flex w-[400px] h-[400px] absolute lg:-right-[0px] lg:-bottom-[120px] z-10 opacity-50 xl:-right-[-10px] xl:-bottom-[130px]"
          />
          <p className="text-xl sm:text-2xl md:text-3xl lg:text-4xl xl:text-5xl font-semibold text-gradient relative whitespace-nowrap z-30">
            <span className="relative inline-block text-[#aa83fd]">
              Real
              <img
                src={line}
                alt=""
                className="absolute left-0 bottom-[-10px] w-full h-auto hidden md:block"
              />
            </span>
            -Time KPI Visualization!
          </p>

          <div className="items-center flex flex-col mt-8">
            <button
              className="text-white font-semibold py-2 px-4 sm:px-7 rounded-lg border-4 border-new_purple_600 flex-row flex"
              style={{
                background: "linear-gradient(to right, #d3c3f4, #5500ff)",
                transition: "background 0.3s ease-in-out",
              }}
            >
              <span className="pr-5 w-auto">
                <img src={star}></img>
              </span>
              Try It for Free
            </button>
          </div>

          <div className="mt-4 mb-8 z-30">
            <img
              src="/media/login/kpi.png"
              alt=""
              className="max-w-full h-auto object-contain mt-10"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default KPIVisualizationBanner;
