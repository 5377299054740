import React, { useState } from 'react';
import SendEmail from './sendEmail';
import Verification from './verification';
import ChangePassword from './changePass';
import SuccessPage from './successPage';

const STATUS_TYPE = {
  SEND_OTP: 'send_otp',
  VERIFIED: 'verified',
  SUCCESS: 'success',
 }

const Forgot_Password = () => {

  const [form,setForm] = useState({
    status:"",
    email:"",
    verifyCode:""
  })

  return (
     <div className="w-full min-h-[100vh] flex flex-row relative ">
      {/**Container Form**/}
      <div className="sm:w-full lg:w-[50%]  flex items-center justify-center   ">
      {
        !form.status && 
        <SendEmail STATUS_TYPE={STATUS_TYPE} setForm={setForm} form={form} />
      }

      {
        form.status == STATUS_TYPE.SEND_OTP && 
        <Verification STATUS_TYPE={STATUS_TYPE} setForm={setForm} form={form} />
      }

      {
        form.status == STATUS_TYPE.VERIFIED &&
        <ChangePassword STATUS_TYPE={STATUS_TYPE} setForm={setForm} form={form} />
      }

      {
        form.status == STATUS_TYPE.SUCCESS &&
        <SuccessPage/>
      }


      </div>
      {/**Container About Product**/}
     <div className="sm:w-0 lg:w-[50%] h-[100%] fixed top-0 right-0  overflow-hidden  ">
      <div className=" w-full h-full relative flex bg-gradient-to-r from-primary_purple_gradient_start to-primary_purple_gradient_end" >
        <img src="/media/login/circle-vector.svg" className="absolute lg:top-[40%] 2xl:top-[22%]  z-10 right-0 lg:h-[100%] 2xl:h-[90%]  " />
        <div className="w-full h-full flex flex-col z-30 items-center mt-[10%]  " >
          {/**Some Text About Product**/}
          <div className="w-[80%] flex flex-col gap-4 items-start " > 
            <img src="/media/login/baiynah-white.svg" className="h-[30px]" />
            <h2 className="lg:text-[30px] 2xl:text-[40px] font-Inter font-semibold text-white " >Streamlining Insights with a <br/> Unified Dashboard</h2>
            <p className="text-[15px] font-Inter font-normal text-white " >Effortlessly Monitor Diverse Data Sources in One Interface</p>
            {/**Inegrating companies**/}
            <div className="flex flex-row gap-8 mt-[10px] items-center justify-between " >
              <div className="flex flex-row gap-2 items-center " >
                <img className="h-[22px]" src="/media/login/activecampaign.svg"/>
                <span className="text-white font-Inter text-[14px]" >active Campaign</span>
              </div>
              <div className="flex flex-row gap-2 items-center " >
                <img className="h-[22px]" src="/media/login/airbrake.svg"/>
                <span className="text-white font-Inter text-[14px]" >AirBrake</span>
              </div>
              <div className="flex flex-row gap-2 items-center " >
                <img className="h-[22px]" src="/media/login/airtable.svg"/>
                <span className="text-white font-Inter text-[14px]" >Airtable</span>
              </div>
            </div>
          </div>
          {/**Dashbaord Picture**/}
          <div style={{backgroundImage:"url(/media/login/dashboard-3.svg)",backgroundSize:"cover",border:"10px solid #8669fb",borderRadius:"20px"}}  className="w-full h-full  mt-[60px] relative bg-gray-200 ml-[20%] " >
            {/* <img src="/media/login/dashboard-3.svg" className={`w-full h-full aspect-auto `} /> */}
          </div>
        </div>
      </div>
     </div>
      
      
    </div>
  )
}

export default Forgot_Password