import React from "react";
import rocket from "../../assets/LandingPage/rocket.svg";
import Wrapper from "../../components/Wrapper";

const Testimonials = () => {
  const testimonials = [
    {
      name: "Olivia Rhye",
      title: "Head Of Marketing Specialist",
      text: "Streamlined Efficiency with Bayinah. With Bayinah, our workflows are optimized, saving us time and resources.",
    },
    {
      name: "Rehan Ali",
      title: "Head Of Marketing Specialist",
      text: "Data Management Reinvented with Bayinah. Bayinah transformed our data handling – intuitive, powerful, and effective",
    },
    {
      name: "James",
      title: "Head Of Marketing",
      text: "Bayinah simply is empowering us to make decisions effectively. Craft your perfect data strategy and preferences.",
    },
    {
      name: "Huston",
      title: "Head Of Marketing",
      text: "Craft Your Perfect Data Strategy with Bayinah. Define, streamline and build your perfect data handling strategy.",
    },
    {
      name: "Ollie",
      title: "Head Of Sporti",
      text: "Craft Your Perfect Data Strategy with Bayinah. Define, streamline and build your perfect data handling strategy.",
    },
  ];

  return (
    <div
      style={{
        background: "linear-gradient(to bottom, #111111 50%, #000000 50%)",
      }}
      className="flex items-center justify-center md:px-0 sm:px-5"
    >
      <Wrapper>
        <div className="bg-[#623ad1] overflow-hidden text-white p-4 flex flex-col lg:flex-row justify-between items-center font-Inter lg:px-10 sm:px-5 rounded-xl h-[500px] w-full">
          <div className="flex-1 mb-6 lg:mb-0 lg:-mr-36 sm:mr-0">
            <p className="text-lg mb-4">Let's try it</p>
            <h2 className="lg:text-4xl xl:text-5xl sm:text-3xl self-stretch font-semibold mb-4">
              Begin today, absolutely free of charge
            </h2>
            <p className="lg:mb-6 sm:mb-28 sm:text-[15px] lg:text-lg">
              Discover why top businesses trust Jasper for superior AI-driven
              outcomes.
            </p>
            <div className="flex lg:flex-row gap-4 sm:flex-col">
              <button className="text-white bg-transparent border-2 border-r-white py-2 px-4 rounded-lg font-semibold shadow-md sm:w-auto">
                Start Free Trial
              </button>
              <button className="bg-white py-2 px-4 rounded-lg font-semibold shadow-md border border-white flex items-center space-x-2 sm:w-auto justify-center">
                <span className="text-[#a36bff]">Get A Demo</span>
                <img src={rocket} alt="" className="w-6 h-6" />
              </button>
            </div>
          </div>

          <div className="lg:flex justify-center gap-4 translate-x-60 sm:hidden xs:hidden md:hidden">
            <div className="relative flex flex-col justify-center gap-4 ">
              {testimonials.slice(0, 2).map((testimonial, index) => (
                <div
                  key={index}
                  className="relative bg-white p-4 rounded-lg shadow-lg flex items-center space-x-4 lg:w-[352px] lg:h-[176px] xl:w-[400px] xl:h-[200px]"
                >
                  <img
                    src={`https://source.unsplash.com/random/100x100?sig=${index}`}
                    alt="Avatar"
                    className="w-16 h-16 rounded-full"
                  />
                  <div>
                    <h3 className="text-lg font-semibold text-black">
                      {testimonial.name}
                    </h3>
                    <p className="text-xs text-black">{testimonial.title}</p>
                    <p className="text-sm mt-2 text-black">
                      {testimonial.text}
                    </p>
                  </div>
                </div>
              ))}
            </div>
            <div className="space-y-4">
              {testimonials.slice(2).map((testimonial, index) => (
                <div
                  key={index}
                  className="bg-white p-4 rounded-lg shadow-lg flex items-center space-x-4 lg:w-[352px] lg:h-[176px] xl:w-[400px] xl:h-[200px]"
                >
                  <img
                    src={`https://source.unsplash.com/random/100x100?sig=${index + 2}`}
                    alt="Avatar"
                    className="w-16 h-16 rounded-full"
                  />
                  <div>
                    <h3 className="text-lg font-semibold text-black">
                      {testimonial.name}
                    </h3>
                    <p className="text-xs text-black">{testimonial.title}</p>
                    <p className="text-sm mt-2 text-black">
                      {testimonial.text}
                    </p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </Wrapper>
    </div>
  );
};

export default Testimonials;
