import React from "react";
import { heading, TemplateCardData } from "../constant";
import TemplateCard from "./TemplateCard";
import { ChevronRight } from 'lucide-react';

const Template = () => {
  const words = heading.title.split(" ");

  const firstTwoWords = words.slice(0, 2).join(" ");
  const middleWords = words.slice(3).join(" ");

  return (
    <section className="flex flex-col items-center py-[44px] lg:py-[120px] mx-auto  w-[80%] sm:w-[90%] xs:w-[85%] md:w-[90%] lg:w-[85%] xl:w-[80%] max-w-6xl">
      {/* Heading */}
      <div
        className={`text-white flex flex-col items-left lg:items-center gap-6 max-w-[370px] lg:max-w-[955px] mx-auto`}
      >
        <p className="bg-custom_dark_pruple/20 text-custom_dark_pruple font-medium text-lg px-3 w-fit rounded-full text-center">
          {heading.heading}
        </p>
        <h1 className="text-2xl font-normal md:text-3xl lg:text-5xl max-w-[700px] text-left lg:text-center">
          <span className="text-white">{firstTwoWords} </span>
          <span className="text-white md:text-gray-400">{middleWords}</span>
        </h1>
        <p className="text-gray-300 text-[14px lg:text-[18px] font-normal text-left lg:text-center ">
          {heading.description}
        </p>
      </div>

      {/* Template Card */}
      <div className="flex flex-col">
        {TemplateCardData.map((card, index) => (
            <TemplateCard key={index} card={card} values={index}/>
        ))    
        }
      </div>

      <button className="text-new_purple_600 flex items-center gap-1 text-[16px]">View All Templates <ChevronRight size={16} className="mt-[2px]"/></button>
      
    </section>
  );
};

export default Template;
