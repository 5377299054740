import React from 'react'
import { SubscribeBarContent } from '../screens/blog/constant'

const Subscribebar = () => {
  return (
    <div className='bg-[#101115] sm:px-[10px] md:px-[40px] lg:px-[80px] '>

      <div className="flex flex-col mx-auto md:flex-row md:items-start md:justify-between py-12 gap-y-4 
      w-[80%] sm:w-[90%] xs:w-[85%] md:w-[90%] lg:w-[85%] xl:w-[80%] h-[100%] max-w-6xl
      ">

       <div className='flex flex-col'>
       <h1 className="text-[30px] font-semibold text-white">{SubscribeBarContent.title}</h1>
        <p className="text-[20px] text-gray-300 font-normal leading-7">{SubscribeBarContent.description}</p>
       </div>

        <div className="flex flex-col md:flex-row gap-4 ">
          <input type="email" placeholder="Enter your email" className="px-4 py-2 w-auto md:w-[320px] rounded-md text-black"/>
          <button className="px-4 py-2 bg-new_purple_600 rounded-md text-white">Subscribe</button>
        </div>

      </div>
    </div>
  )
}

export default Subscribebar