import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import AllDataSources from '../Services/data-sources';

const initialState = {
  isDataSourcesLoading:false,
  isDataSourceModalActive:false,
  dataSources:[],
  isDataSetsLoading:false,
  dataSets:[]
}

export const GetDataSources = createAsyncThunk(`get-data-sources`,async()=>{
    const res = await AllDataSources.getDataSources();
    return res;
  })


export const GetDataSets = createAsyncThunk(`get-data-sets`,async()=>{
  const res = await AllDataSources.getDataSets();
  return res;
})

export const DataSources = createSlice({
  name: 'DataSources',
  initialState,
  reducers: {
    setDataSourceModal : (state,action) => {
      state.isDataSourceModalActive = action.payload
    }
  },
  extraReducers(builder) {
    builder
      .addCase(GetDataSources.pending,(state,action)=>{
           state.isDataSourcesLoading = true
      })
      .addCase(GetDataSources.fulfilled,(state,action)=>{
          state.dataSources = action.payload.response
      })
      .addCase(GetDataSources.rejected,(state,action)=>{
        state.isDataSourcesLoading = false

      })
      .addCase(GetDataSets.pending,(state,action)=>{
           state.isDataSetsLoading = true
      })
      .addCase(GetDataSets.fulfilled,(state,action)=>{
          state.dataSets = action.payload
          state.isDataSetsLoading = false
      })
      .addCase(GetDataSets.rejected,(state,action)=>{
        state.isDataSetsLoading = false

      })
  },
})

export const {setDataSourceModal} = DataSources.actions

export default DataSources.reducer