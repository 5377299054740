import React, { useState } from "react";
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'

const DropYTarget = ({accept,item,label,onDrop,handleDelete }) => {


  const [{ canDrop,isOver }, drop] = useDrop({
    accept: accept,
    drop:(col,id)=>{

      if(item?.length > 0){
        if(col.column.dataId  !== item[0].dataId){
          toast.error(`⚠️ Columns must be from the same data source.`, {
            position: "top-left",
           },5000)
        }
        else{
         saveItem(col)
        }
      }
      else{
        saveItem(col)
      }
    },
    collect: (monitor) => ({
        isOver: !!monitor.isOver(),
        canDrop: monitor.canDrop(),
    }),
  });

  const saveItem = (col) => {
    const newColumn = {
      ...col.column,
      axis:"y",
    }

    onDrop(prev => [
      ...prev,
      newColumn
    ]);
  }


  return (
    <div className="w-full h-auto border-[1px] flex flex-col  ">
      <div className="w-full h-[40px] border-b-[1px] flex items-center px-4  ">
        <h2 className="font-Inter">{label}</h2>
      </div>
      <div className="w-full h-auto  flex flex-col gap-2 items-center justify-center py-2 ">
        {/***DRAGGED BOX*/}
        {
          item?.map((column,index)=>{
            return(
              <>
               <div key={index}  className="w-full    ">
              <div className={`w-full px-2   h-[40px] flex items-center justify-between gap-2 border-b-[1px] `}>
            
                <div
                className=" flex gap-2 items-center px-2"
              >
               
                <span className="text-[15px] font-Inter ">
                 {column.value}
                </span>
              </div>
              <img
                onClick={() => handleDelete(column._id)}
                src="/media/dashboard/delete.svg"
                className="h-[18px] cursor-pointer hover:scale-125 active:scale-90  transition-all  "
              />
            
            </div>
           
          </div>
              </>
            )
          })
        }

       <div ref={drop}  className={`w-[95%] h-[40px] border-dashed border-[3px] text-center px-2 ${canDrop && "border-blue-200 p-1  "}}"} `}>
           <div  className={`w-full h-full  text-center ${canDrop && " bg-blue-300 "}  `}>
            <span className="w-full h-full flex flex-row gap-2 items-center  justify-center">
            {canDrop ? (
                <span className="text-[14px] font-Inter text-white ">
                  Drop to Add
                </span>
              ) : (
                <>
                  <span className="text-[12px] font-Inter  ">Drag</span>
                  <span className="text-[12px] font-Inter text-green-400  ">
                    123
                  </span>
                  <span className="text-[12px] font-Inter   ">
                    OR
                  </span>
                  <span className="text-[12px] font-Inter text-orange-400  ">
                    ABC
                  </span>
                </>
              )}
            </span>
           </div>
       </div>
      </div>
    </div>
  );
};

export default DropYTarget;
