import React, { useEffect } from "react";
import { GoogleOAuthProvider } from '@react-oauth/google';
import Layout from "../layout";
import KPIVisualizationBanner from "./Banner";
import IntegrationSection from "./IntegrationSection";
import FeatureSection from "./FeatureSection";
import FeatureGrid from "./FeatureCards";
import IntegrationGrid from "./IntegrationGrid";
import Testimonials from "./Testimonials";
import GetNotified from "./GetNotified";

const HomePage = () => {

 

  return (
    <Layout>
      <div className="bg-black">
        <KPIVisualizationBanner />
        <IntegrationSection />
        <FeatureSection />
        <FeatureGrid />
        <IntegrationGrid />
        <Testimonials />
        <GetNotified />
      </div>
    </Layout>
  );
};

export default HomePage;
