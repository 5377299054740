import React, { useState ,useEffect} from "react";
import { useNavigate, useLocation } from "react-router-dom";
import Spinner from "../../components/spinner";
import AuthApi from "../../Services/Auth";
import Alert from "../../components/Alert";
const Email_Verification = () => {
  const { state } = useLocation();
  const navigate = useNavigate();
  const [isLoading, setisLoading] = useState(false);
  const [isError, setisError] = useState("");
  const [code, setCode] = useState({
    first: "",
    second: "",
    third: "",
    fourth: "",
    fifth: "",
    sixth: "",
  });

  const [values, setValues] = useState({
    email: state?.email,
    password: state?.password,
    verified: false,
  });

  useEffect(()=>{
    if(!state?.email || !state?.password){
      navigate("/login")
    }
  },[navigate, state])

  const handleNumber = (e) => {
    const { value, name } = e.target;

    if (value.length > 1) {
      const newValue = value.slice(0, 1);
      setCode({
        ...code,
        [name]: newValue,
      });
    } else {
      setCode({
        ...code,
        [name]: value,
      });
    }
  };

  const handleVerification = async () => {
    setisLoading(true);
    //Concatenate all the digites
    const verificationCode = Object.values(code).join("");
    const { email } = values;

    const payload = {
      email: email,
      verifyCode: verificationCode,
    };

    try {
      const resp = await AuthApi.emailVerification(payload);
      console.log(resp,"RESP");
      setisError(" ");
  
      //Call the SetSession Function and Pass the Token
      AuthApi.setSession(resp.token)
      // navigate("/dashboard");
    } catch (error) {
      setisLoading(false);
      setisError(error?.response?.data?.error);
      console.log(error,"ERROR")
    }
  };

  const disabled =
    !code.first ||
    !code.second ||
    !code.third ||
    !code.fourth ||
    !code.fifth ||
    !code.sixth;

  return (
    <>
  
    <div className="w-full min-h-[100vh] flex flex-row relative ">
      {/**Container Form**/}
      <div className="sm:w-full lg:w-[50%]  flex items-center justify-center   ">
        {/**Inner Conatiner**/}
        <div className="sm:w-[70%] md:w-[70%]  lg:max-w-[600px]  flex flex-col items-center gap-6 ">
          {/**Logo Baiynah**/}
          <div className="w-full flex flex-col gap-3 items-center p-4  ">
            <img
              src="/media/login/baiynah-logo.svg"
              className="mb-[10px] mt-[20px] "
            />
            <h2 className=" sm:text-[22px] 2xl:text-[30px] font-Inter font-semibold ">
              Please check email
            </h2>
            <span className="sm:text-[14px] 2xl:text-[16px] text-center font-Inter font-normal text-[new_gray_700] text-[#344054] ">
              We send the <b>6-digit</b> code to <b>{values.email}</b> for
              verification
            </span>
          </div>
          {/***Verification Code***/}
          <div className="w-full  ">
            {!isError == " " && (
              <Alert message={isError} closeAction={() => setisError(false)} />
            )}
            <div className="flex flex-row items-center justify-center gap-4">
              <div className="w-[45%] h-[50px] border-[1px] flex flex-row  border-border_fields  rounded-md ">
                <input
                  onChange={handleNumber}
                  name="first"
                  type="number"
                  value={code.first}
                  placeholder="0"
                  max={1}
                  min={1}
                  maxLength={1}
                  className="w-1/3 h-full pl-[3px] outline-none flex items-center justify-center text-[25px] font-Inter border-r-[1px] bg-transparent text-center  border-border_fields "
                />
                <input
                  onChange={handleNumber}
                  name="second"
                  type="number"
                  value={code.second}
                  placeholder="0"
                  max={1}
                  min={1}
                  maxLength={1}
                  className="w-1/3 h-full pl-[3px] outline-none flex items-center justify-center text-[25px] font-Inter border-r-[1px] bg-transparent text-center  border-border_fields "
                />
                <input
                  onChange={handleNumber}
                  name="third"
                  type="number"
                  value={code.third}
                  placeholder="0"
                  max={1}
                  min={1}
                  maxLength={1}
                  className="w-1/3 h-full pl-[3px] outline-none flex items-center justify-center text-[25px] font-Inter border-r-[1px] bg-transparent text-center  border-border_fields "
                />
              </div>
              <span className="w-[10%] h-[4px]  bg-border_fields"></span>
              <div className="w-[45%] h-[50px] border-[1px] flex flex-row  border-border_fields  rounded-md ">
                <input
                  onChange={handleNumber}
                  name="fourth"
                  type="number"
                  value={code.fourth}
                  placeholder="0"
                  max={1}
                  min={1}
                  maxLength={1}
                  className="w-1/3 h-full pl-[3px] outline-none flex items-center justify-center text-[25px] font-Inter border-r-[1px] bg-transparent text-center  border-border_fields "
                />
                <input
                  onChange={handleNumber}
                  name="fifth"
                  type="number"
                  value={code.fifth}
                  placeholder="0"
                  max={1}
                  min={1}
                  maxLength={1}
                  className="w-1/3 h-full pl-[3px] outline-none flex items-center justify-center text-[25px] font-Inter border-r-[1px] bg-transparent text-center  border-border_fields "
                />
                <input
                  onChange={handleNumber}
                  name="sixth"
                  type="number"
                  value={code.sixth}
                  placeholder="0"
                  max={1}
                  min={1}
                  maxLength={1}
                  className="w-1/3 h-full pl-[3px] outline-none flex items-center justify-center text-[25px] font-Inter border-r-[1px] bg-transparent text-center  border-border_fields "
                />
              </div>
            </div>
          </div>

          <div className="w-full flex flex-col ">
            <button
              disabled={disabled || isLoading}
              onClick={handleVerification}
              className={`p-3 disabled:cursor-not-allowed bg-new_purple_600 ${
                disabled
                  ? "bg-hovered_new_purple_600"
                  : "hover:bg-hovered_new_purple_600 active:scale-95"
              }  transition-all text-white font-Inter rounded-md `}
            >
              {isLoading ? <Spinner /> : "Verify Email"}
            </button>
            <div className="flex flex-row gap-1 items-center justify-center mt-[30px] ">
              <span className="text-[14px] font-Inter text-new_gray_500">
                Added wrong email?
              </span>
              <span className="text-[14px] font-Inter text-new_purple_600">
                <b>Change Email</b>
              </span>
            </div>
          </div>

          {/* <LoginForm /> */}
          <div className="w-full h-[30px] block"></div>
        </div>
      </div>
      {/**Container About Product**/}
      <div className="w-0 sm:w-0 lg:w-[50%] h-[100%] fixed top-0 right-0  overflow-hidden  ">
        <div className=" w-full h-full relative flex bg-gradient-to-r from-primary_purple_gradient_start to-primary_purple_gradient_end">
          <img
            src="/media/login/circle-vector.svg"
            className="absolute lg:top-[40%] 2xl:top-[22%]  z-10 right-0 lg:h-[100%] 2xl:h-[90%]  "
          />
          <div className="w-full h-full flex flex-col z-30 items-center mt-[10%]  ">
            {/**Some Text About Product**/}
            <div className="w-[80%] flex flex-col gap-4 items-start ">
              <img src="/media/login/baiynah-white.svg" className="h-[30px]" />
              <h2 className="lg:text-[30px] 2xl:text-[40px] font-Inter font-semibold text-white ">
                Streamlining Insights with a <br /> Unified Dashboard
              </h2>
              <p className="text-[15px] font-Inter font-normal text-white ">
                Effortlessly Monitor Diverse Data Sources in One Interface
              </p>
              {/**Inegrating companies**/}
              <div className="flex flex-row gap-8 mt-[10px] items-center justify-between ">
                <div className="flex flex-row gap-2 items-center ">
                  <img
                    className="h-[22px]"
                    src="/media/login/activecampaign.svg"
                  />
                  <span className="text-white font-Inter text-[14px]">
                    active Campaign
                  </span>
                </div>
                <div className="flex flex-row gap-2 items-center ">
                  <img className="h-[22px]" src="/media/login/airbrake.svg" />
                  <span className="text-white font-Inter text-[14px]">
                    AirBrake
                  </span>
                </div>
                <div className="flex flex-row gap-2 items-center ">
                  <img className="h-[22px]" src="/media/login/airtable.svg" />
                  <span className="text-white font-Inter text-[14px]">
                    Airtable
                  </span>
                </div>
              </div>
            </div>
            {/**Dashbaord Picture**/}
            <div
              style={{
                backgroundImage: "url(/media/login/dashboard-3.svg)",
                backgroundSize: "cover",
                border: "10px solid #8669fb",
                borderRadius: "20px",
              }}
              className="w-full h-full  mt-[60px] relative bg-gray-200 ml-[20%] "
            >
              {/* <img src="/media/login/dashboard-3.svg" className={`w-full h-full aspect-auto `} /> */}
            </div>
          </div>
        </div>
      </div>
    </div>
   
    </>
    
  );
};

export default Email_Verification;
