import React, { useState } from 'react';
import Navbar from './userNavbar';
import AccountSettings from './accountSetting';
import SettingsNav from './settingNav';

const Profile = () => {
  const [activeTab, setActiveTab] = useState('Account');

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  const renderActiveTab = () => {
    switch (activeTab) {
      case 'Account':
        return <AccountSettings />;
      case 'Teams':
        return <AccountSettings />;

      case 'General':
        return <AccountSettings />;

      case 'Work Space':
        return <AccountSettings />;

      case 'Pricing & Billing':
        return <AccountSettings />;

      case 'Connection':
        return <AccountSettings />;

      case 'API':
        return <AccountSettings />;

      default:
        return <AccountSettings />;
    }
  };

  return (
    <div className="w-[100vw] min-h-[100vh] bg-background_color overflow-x-hidden">
      <Navbar />
      <div className="mt-20 p-4">
        <SettingsNav activeTab={activeTab} handleTabClick={handleTabClick} />
        {renderActiveTab()}
      </div>
    </div>
  );
};

export default Profile;
