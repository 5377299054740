import heroImg from '../../assets/Services/heroImg.png'
import stars from '../../assets/Services/stars.png'
import briefcase from '../../assets/Services/briefcase.png'
import link from '../../assets/Services/link.png'
import layout from '../../assets/Services/layout.png'

export const heroSection = {
    title : "Efficiently achieve excellent outcomes while conserving valuable time",
    description : "Our data specialists not only expedite project launches but also offer comprehensive training and tailored solutions to meet your business objectives seamlessly. Explore our services and reach out. We're here to assist you.",
    buttonText: "Let's See",
    heroImg,
    stars
}

export const heading = {
    heading: "Our Services",
    title: "Deployment and Execution Services",
    description: "Trust our Khudi experts to handle your implementation needs. Whether it's metrics and dashboards or data integration, our dedicated team will streamline processes, saving you time and delivering swift outcomes. Contact us for tailored solutions and a personalized quotation."
}

export const servicesList = [
    {
        icon : briefcase,
        title : "Proof of Principle",
        description: "Our team of professional services experts will evaluate how your project integrates with Khudi and develop a Proof of Concept. Subsequently, we will guide you through the necessary steps to finalize the project."
    },
    {
        icon : layout,
        title : "Accelerated Account Configuration",
        description: "Accelerate your setup with our Accelerated Account Configuration, encompassing data feed, metric, and dashboard creation, typically completed within 5 hours."
    },
    {
        icon : link,
        title : "Integration of Data Infrastructure",
        description: "We'll assess your current data infrastructure and devise optimal strategies for seamlessly incorporating Klipfolio products."
    },
    {
        icon : briefcase,
        title : "Data Connectors and Sources",
        description: "Leave the heavy lifting to our engineering team. We'll handle API navigation and establish connections to your data using either data feeds or data sources."
    },
    {
        icon : link,
        title : "Metric & Visualization Creation",
        description: "Our analytics specialists will generate metrics, including calculated ones, and visualizations using your current data feeds or sources."
    },
    {
        icon : link,
        title : "Crafting Dashboards and Reports",
        description: "Together, we'll create your inaugural dashboards and reports, tailored for stakeholder sharing. By understanding your reporting needs, we'll plan the essential metrics, KPIs, and visualizations."
    },
]
