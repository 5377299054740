import React, { useState,useEffect } from 'react'
import { useDispatch,useSelector } from 'react-redux'
import { GetColumnNames } from '../../../../../Redux/SheetSlice'
import { setSelectedColumn ,setColumnData } from '../../../../../Redux/SheetSlice'


const ColumnName = ({colItem}) => {

    const dispatch = useDispatch()

    const selectedColumn = useSelector(state => (state.sheet.selectedColumn))

    console.log(selectedColumn,"SELECTED COLUMN")


    const ResetColumn = () => {
        dispatch(setSelectedColumn(""))
    }

    

    const handleSelectColumn = () => {

        const payload = {
            dataId:colItem.dataId,
            column:colItem.column,
            value:colItem.value
        }

        dispatch(setSelectedColumn(colItem.value))
        dispatch(setColumnData(payload))
    }

    return(
       <>
       
         <div onClick={handleSelectColumn} className={`px-10 py-3 cursor-pointer transition-all flex flex-row justify-between gap-2 border-b-[1px] border-new_purple_50 ${selectedColumn == colItem.value ? "bg-new_purple_50" : ""} `} >
         <div className='px-2 w-full flex  justify-between' >
             <h2 className={`lg:text-[14px] 2xl"text-[16px] font-Inter font-normal ${selectedColumn == colItem.value ? "text-new_purple_600":""}  `} >{colItem.value}</h2>
               {
                 selectedColumn == colItem.value ?
                 <img onClick={ResetColumn}  src='/media/add-widgets/cancel.svg'  className='h-[25px]  ' />
                 :
                 ""
               }
         </div>
         </div>
     

       </>
    )
    
    }

const SelectColumn = ({id}) => {

    const dispatch = useDispatch()

    const ColumnNames = useSelector((state)=>state.sheet.columnNames)

    const [isDropDownActive,setIsDropDownActive] = useState(false)

    useEffect(()=>{
        dispatch(GetColumnNames(id))
    },[])


return (
    <>
    <div onClick={()=> setIsDropDownActive(!isDropDownActive)}  className={`px-8 py-3 cursor-pointer transition-all flex flex-row justify-between gap-2 border-b-[1px] border-new_purple_50  `} >
    <h2 className={`lg:text-[14px] 2xl"text-[16px] font-Inter font-semibold  `} >Select Column</h2>
    <img src='/media/add-widgets/chevron-down.svg' className={` cursor-pointer transition-all ${isDropDownActive ? "rotate-180" :"rotate-0" } `} />
    </div>
    <div className={`w-full h-auto ${isDropDownActive ? "visible " :"hidden"} transition-all  bg-white border-[1px] border-new_purple_50  `} >
       

        {
            ColumnNames && ColumnNames.length > 0 && ColumnNames.map((colItem,index)=> {
                return(
                    <ColumnName key={index} colItem={colItem}  />

                )
            })
        }
    
    </div>
    </>
)
}

export default SelectColumn

