// userAPI.js
import { API } from "./api";

const userAPI = {
  getProfile: (config) => API.get(`/user/profile`, config),
  updateProfile: (profileData, config) => API.put('/auth/update-profile', profileData, config),
  updatePassword: (passwordData, config) => API.put('/auth/change-password-from-profile', passwordData, config),
};

export default userAPI;
