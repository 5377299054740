import React from "react";
import { heading } from "../constant";
import ServiceCard from "./ServiceCard";

const ServicesList = () => {

  return (
    <div className=" mx-auto px-4 sm:px-6 lg:px-24 py-[44px] lg:py-[120px] flex flex-col items-center gap-[52px]">

      {/* Heading */}
      <div
        className={`text-white flex flex-col items-start lg:items-center gap-[24px] max-w-[370px] lg:max-w-[955px] `}
      >
        <p className="bg-custom_dark_pruple/20 text-custom_dark_pruple font-medium text-lg px-3  rounded-full text-center">
          {heading.heading}
        </p>
        <h1 className="text-2xl font-medium lg:text-5xl tracking-[0px]">{heading.title}</h1>
        <p className="text-gray-300 text-lg font-normal text-left lg:text-center lg:text-[18px] leading-[28px]">
          {heading.description}
        </p>
      </div>

      {/* Services List */}
      <ServiceCard/>
      
    </div>
  );
};

export default ServicesList;
