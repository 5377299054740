import { BlogCardData } from "../constant"
import { Link } from "react-router-dom";

const Blogcards = () => {
  return (
  
     <div className="flex flex-wrap gap-[1px] py-[24px]">

       {BlogCardData.map((data) => (
         <div key={data.id} className="bg-black overflow-hidden flex flex-col gap-[16px] justify-center align-start border border-gray-800">
           
           <img src={data.image} alt="blog" className="w-[320px] h-[230px] object-cover object-center"/>
          
           <div className="px-5 py-2 w-[320px] h-[148px] flex flex-col gap-2">
             <span className=" text-gray-100 rounded-2xl bg-hovered_new_purple_600/30 text-sm px-2 w-fit">{data.type}</span>
             <Link to={`/blog/${data.id}`}>
             <h1 className="text-[24px] font-bold mt-2 text-white overflow-hidden text-ellipsis max-h-[64px] line-clamp-2 whit leading-tight">{data.title}</h1></Link>
             <span className="text-[14px] text-gray-100 font-normal">{data.date}</span>
           </div>

         </div>
       ))}

     </div>

  )
}

export default Blogcards