import React from "react";
import GoogleAdSenseLogo from "../../assets/LandingPage/google.svg";
import LoomLogo from "../../assets/LandingPage/loom.svg";
import TicketmasterLogo from "../../assets/LandingPage/ticketmaster.svg";
import RecurlyLogo from "../../assets/LandingPage/recurly.svg";
import MondayLogo from "../../assets/LandingPage/monday.svg";

const IntegrationSection = () => {
  return (
    <div className="bg-black text-white px-4 py-8 flex flex-col justify-center items-center font-Inter">
      <div className="mb-4 text-center">
        <p className="text-xl lg:text-2xl tracking-tight">
          Seamlessly Connect: Integrate with
          <span className="text-gradient ml-1 font-semibold">
            Real-Time Connectors!
          </span>
        </p>
      </div>
      <div className="overflow-hidden relative">
        <div className="flex items-center justify-start space-x-4 sm:space-x-8 lg:space-x-20 mt-12 overflow-x-hidden">
          <img src={GoogleAdSenseLogo} alt="Google AdSense" className="h-8" />
          <img src={LoomLogo} alt="Loom" className="h-8" />
          <img src={TicketmasterLogo} alt="Ticketmaster" className="h-8" />
          <img src={RecurlyLogo} alt="Recurly" className="h-8" />
          <img src={MondayLogo} alt="Monday.com" className="h-8" />
        </div>
        <div className="absolute inset-y-0 left-0 w-1/12 bg-gradient-to-r from-black to-transparent hidden sm:block"></div>
        <div className="absolute inset-y-0 right-0 w-1/12 bg-gradient-to-l from-black to-transparent hidden sm:block"></div>
      </div>
    </div>
  );
};

export default IntegrationSection;
