import React, { useState } from 'react'
import ToolWrapper from './toolWrapper'
import { dataArray } from './dataArray'

const Tools = () => { 

     const [isActive,setIsActive] = useState(true)
     
  return (
    <div className='w-[100%] h-full flex flex-col    ' >
        {/**Input Continers **/}
        <div className=' w-full flex flex-row justify-between mt-[35px] ' >
            {/***Input Wrapper**/}
            <div className=' w-[50%] h-[50px] flex flex-row items-center gap-2 px-4 py-2 bg-gray-100 border-[0.5px] border-gray-200  rounded-full '  >
                <img src='/media/add-widgets/search.svg' className='h-[20px]' />
                <input type='text' placeholder='Search Your tools ' className='w-[90%] h-full bg-transparent text-[18px]  font-Inter font  outline-none ' />
            </div>

            <div className='w-[20%] h-[50px]  bg-white border-[1px] border-new_gray_300 rounded-full  ' >
                <div className='w-full h-full flex flex-row  justify-between items-center px-4 py-2 cursor-pointer  ' >
                    <span className='text-new_gray_500 text-[16px] font-Inter font-normal' >Most Popular</span>
                    <img src='/media/add-widgets/chevron-down.svg'/>

                </div>
            </div>
        </div>
        <div className='w-full flex flex-wrap gap-x-6 gap-y-6 mt-[35px] ' >
         {
            dataArray.length > 0 && dataArray.map(({name,img,path,component},index)=>{
                return(
                    <ToolWrapper
                     key={index}
                     name={name}
                     img={img}
                     path={path}
                     component={component}

                    />
                )
            })
         }
        </div>

    </div>
  )
}

export default Tools