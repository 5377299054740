import React, { useState } from "react";
import DynamicData from "./dynamicData";
import {useDispatch,useSelector} from "react-redux"
import { setPopupActive,setActiveTool } from "../../../../Redux/toolsSlice";

const ToolWrapper = ({ img, name ,path,component}) => {

  const dispatch = useDispatch()

  const isActive = useSelector((state)=>state.tools.isPopUpActive)

  const closePopup = () => {
    dispatch(setPopupActive())
  }

  const handleTool = () => {

    if(path && component){
      const payload = {
        tool:component,
        path:path
      }
  
      dispatch(setPopupActive())
      dispatch(setActiveTool(payload))
    }
    else{
      return
    }
  }

  return (
   <>
    <div onClick={handleTool} className="sm:min-w-[47%] md:min-w-1/4 lg:min-w-[235px] lg:max-w-auto pl-2 pr-6 p-2 border-[1px] cursor-pointer hover:bg-new_gray_100 transition-all border-gray_200 flex items-center flex-row gap-3 rounded-full">
      <img src={`/media/add-widgets/${img}`} className="w-[40px] h-[40px]" />
      <span className="text-[16px] font-Inter font-semibold">{name}</span>
    </div>

    <div className={`fixed top-0 left-0 z-[1000] ${isActive ? "flex":"hidden"} flex items-center justify-center  w-full h-[100vh] bg-[#1D2939] bg-opacity-[0.9%] `} >
      <div className="w-[470px] h-auto bg-white flex flex-col rounded-md " >
        <div className="w-full h-[60px] border-b-[1px] border-gray-100 flex items-center justify-between px-8 " >
          <div className="flex flex-row items-center gap-2" > 
            <img src="/media/add-widgets/connect.svg" />
            <span className="text-[14px] font-Inter font-semibold " >Connect With</span>
          </div>
          <img onClick={closePopup} src="/media/add-widgets/close.svg" className="w-[24px] h-[24px] hover:scale-110 active:scale-90 transition-all cursor-pointer " />
        </div>
        <div className="w-full h-auto" >
          <DynamicData/>
        </div>
      </div>
    </div>
   </>
  );
};

export default ToolWrapper;
