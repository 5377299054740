import React, { useEffect, useState ,useContext} from "react";
import { useParams } from "react-router-dom";
import DropTarget from "./DropTarget";
import DropYTarget from "./DropXTarget";
import { DimensionalGraph ,resetLine } from "../../../../../../../Redux/chartsSlice";
import { AddWidget } from "src/Redux/widgetSlice";
import { useDispatch } from "react-redux";
import { WidgetContext } from "./chartContext";


const ItemTypes = {
  NUMBER: "number",
  STRING: "string",
};

const LineDrop = () => {
  const dispatch = useDispatch();
  const params = useParams()

  const {setWidgetData} = useContext(WidgetContext);


  const [xAxis, setxAxis] = useState("");
  const [yAxis, setyAxis] = useState([]);

  const submitWidget = (event) => {
   
    const dashboardId = params?.id;
  
    // Optional validation for xAxis and yAxis
    if (!xAxis == "" && yAxis.length > 0) {
     
      const metaData = {
        dashboardId: dashboardId,
        value: "",  // Might need to fill this in later
        xAxis: xAxis,
        yAxis: yAxis,
        yAxisName: "defaultY",  // Set meaningful default if necessary
        xAxisName: "defaultX",  // Set meaningful default if necessary
        chatType: "line",  // "chatType" should probably be "chartType"
        coordinates: {},  // Should this contain actual data?
      };

      dispatch(AddWidget(metaData))
    
      console.log(metaData, "DASHBOARD DATA");
    // Here you might also call an API or update some state with this metaData
    }else{
      console.warn("DATA MISSING")
    }
  
  };

  const handleDelete = (id) => {
    const filter = yAxis.filter((item, index) => {
      return item._id !== id;
    });
    setyAxis(filter);
  };

  useEffect(() => {
    if (!xAxis == " " && yAxis.length > 0) {
      
      setWidgetData({xAxis,yAxis,chart:"line"})

      //Array which contain two dimensional items
      const newArray = [];

      const newXAxis = {
        dataId: xAxis?.dataId,
        columnName: xAxis?.column,
        axis: xAxis?.axis,
      };

      newArray.push(newXAxis);

      //Maping
      yAxis?.map((item, index) => {
        const newItem = {
          dataId: item?.dataId,
          columnName: item?.column,
          axis: item?.axis,
        };
        newArray.push(newItem);
      });

      let payload = {
        type:"line",
        data:newArray
      }

      dispatch(DimensionalGraph(payload));
    }
    else{
      dispatch(resetLine())
    }
  }, [xAxis, yAxis]);


// useEffect to listen to the custom event
useEffect(() => {
  const handleSubmitEvent = (event) => {
    submitWidget();  // Invoke your submitWidget function when event is caught
  };

  window.addEventListener("submitWidgetData", handleSubmitEvent);

  // Cleanup to remove the listener on unmount
  return () => {
    window.removeEventListener("submitWidgetData", handleSubmitEvent);
  };
});  // Empty dependency array to only run this on mount/unmount

  

 
  return (
    <div className="w-full h-auto  flex flex-col items-center gap-4  ">
      <DropTarget
        accept={[ItemTypes.NUMBER, ItemTypes.STRING]}
        label={"X-axis"}
        onDrop={setxAxis}
        item={xAxis}
        setValue={setxAxis}
        handleDelete={() => setxAxis(" ")}
      />
      <DropYTarget
        accept={[ItemTypes.NUMBER, ItemTypes.STRING]}
        label={"Y-axis"}
        onDrop={setyAxis}
        item={yAxis}
        setValue={setyAxis}
        handleDelete={handleDelete}
      />
      <div className="w-full h-[45px] bg-blue-400 rounded-md mt-[10px] text-white font-Inter inline-flex items-center justify-center " >
        Add Widget
      </div>
    </div>
  );
};

export default LineDrop;
