import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  isPopUpActive:false,
  activeTool:{}
}

export const ToolsSlice = createSlice({
  name: 'Tools',
  initialState,
  reducers: {

    setPopupActive:(state,action) => {
        state.isPopUpActive = !state.isPopUpActive
    },
    setActiveTool:(state,action) => {
        state.activeTool = action.payload
    }

  },
})

export const {setPopupActive,setActiveTool} = ToolsSlice.actions

export default ToolsSlice.reducer