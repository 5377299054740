import React, { useState } from 'react'
import {useNavigate} from "react-router-dom"
import {useDispatch, useSelector} from "react-redux"
import { CreateDashboard } from '../../../../Redux/dashboardSlice'
import Spinner from '../../../../components/spinner'

const Popup = ({isPopUpActive,setIsPopupActive}) => {

    const navigate = useNavigate()

    const dispatch = useDispatch()

    const isLoading  = useSelector((state)=>state.dashboard.isCreateDashboarLoading)

    const isActive = isPopUpActive

    const [dashboardName,setDashbaordName] = useState("")

    const handleConfirm = () => {

      if(!dashboardName) return

      dispatch(CreateDashboard({name:dashboardName})).then((resp)=>{
         if(resp.payload){
            const {dashboardId} = resp.payload
           navigate(`/edit/dashboard/${dashboardId}`,{state:{dashboardId:dashboardId}})
        }
      }) 
    }


  return (
    <div className={`w-full h-[100vh] ${isActive ? "visible" : "hidden"} fixed top-0 left-0 z-50 flex items-center justify-center backdrop-blur-sm bg-[#344054] bg-opacity-70  `} >
        <div className='w-[450px] h-[250px] bg-white rounded-md flex flex-col ' >
            <div className='w-full h-[70px] flex flex-row items-center gap-4  px-6 border-b-[1px] border-new_gray_200 ' >
                <img src='/media/dashboard/activity.svg'/>
                <h2 className='text-[18px] font-Inter font-semibold ' >Create Dashboard</h2>
            </div>
            <div className='w-full flex flex-col gap-4 px-6 py-4' >
                <div className='flex flex-col gap-2 pt-2  ' >
                  <h2 className=' font-Inter text-[14px] font-medium ' >Dashboard Name</h2>
                  <input onChange={(event)=>setDashbaordName(event.target.value)} type='text' placeholder='e.g. KPI Performance' className='w-full h-[45px] border-[1px] border-new_gray_300 outline-none p-2 rounded-md font-Inter ' />
                </div>
                <div className='flex flex-row gap-2' >
                  <button onClick={()=>setIsPopupActive(false)} className='w-1/2 border-[1px] px-4 py-2 border-new_gray_300 rounded-md font-Inter font-semibold active:scale-95 transition-all '  >Cancel</button>
                  <button disabled={!dashboardName} onClick={handleConfirm} className={`w-1/2 border-[1px] px-4 py-2 disabled:cursor-not-allowed  bg-new_purple_600 text-white rounded-md font-Inter font-normal ${dashboardName ? "active:scale-95" : "" }  transition-all `}  >
                    {
                      isLoading ? <Spinner className /> : "Confirm"
                    }
                  </button>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Popup