import React from 'react'
import WidgetSelector from './widgetSelector'
import ColorSelector from './colorSelector'
import SufixSelector from './sufixSelector'
import DropZone from './DropZones/dropZone'

const ChartType = () => {
  return (
    <div className='w-[330px] h-full overflow-y-auto  bg-white  shadow-md rounded-lg p-6 flex flex-col gap-4 ' >
    <h2 className='font-Inter text-black text-[16px] font-normal' >Chart Type</h2>
    {/**Widget Selector**/}
    <WidgetSelector/>
    <h2 className='font-Inter text-black text-[16px] font-normal' >Color</h2>
    <ColorSelector/>
    <h2 className='font-Inter text-black text-[16px] font-normal' >Prefix/Sufix</h2>
    {/* <SufixSelector/> */}

    <DropZone/>
  </div>
  )
}

export default ChartType