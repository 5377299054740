
const Heading = ({title,description,className}) => {
  return (
    <div className={`text-white flex flex-col gap-4 lg:w-1/2 py-11 ${className}`}>
      <h1 className="text-3xl font-bold sm:text-[35px]  lg:text-[48px] ">{title}</h1>
      <p className="text-gray-100 text-[14px] font-normal ">{description}</p>
    </div>
  )
}

export default Heading