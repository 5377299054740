import { API } from "./api";
import { isExpired } from "react-jwt";
import userAPI from "./user";
import UserProfile from "./userProfile";
import {Navigate} from "react-router-dom"


const userProfile = new UserProfile()

const AuthApi = {
  userRegistration: (payload) => API.post(`/auth/registration`, payload),
  emailVerification:(payload) => API.put('/auth/account/verification',payload),
  userLogin: (payload) => API.post(`/auth/login`, payload),
  userForgotPass:(payload) => API.post(`/auth/forgot-password`,payload),
  changePassword:(payload) => API.put(`/auth/change-password`,payload),
  googleSignIn: (payload) => API.post(`auth/google-sign`, payload),
  setSession:(token)=>{
    
    localStorage.setItem('token', token);
    userProfile.setUserProfile(token).then(res => {
     
      setTimeout(() => {
        window.location.replace('/create/dashboard'); 
      }, 1000);
    }).catch(err => console.log(err));

  },
  removeSession:()=>{
    localStorage.removeItem('token');
  },
  isAuthenticatied:() => {
    const token = localStorage.getItem('token');
    const isTokenExpired = isExpired(token);

    if (isTokenExpired) {
      AuthApi.removeSession(); 
      return false;
    }
    return true;
  }

}

export default AuthApi;

