import React,{useEffect, useState} from 'react'
import { motion,AnimatePresence } from 'framer-motion'
import {useDispatch,useSelector} from "react-redux"
import Set from './set'
import { GetDataSets } from '../../../../../../Redux/dataSourcesSlice'
import Spinner from '../../../../../../components/spinner'

const DataSet = ({isSideBarActive}) => {

  const dispatch = useDispatch()

  const DataSets = useSelector((state)=>state.sources.dataSets)

  const isLoading = useSelector((state)=>state.sources.isDataSetsLoading)

  const [isMinimize,setIsMinimize] = useState(false)
  
  useEffect(()=>{
    dispatch(GetDataSets())
  },[])

  const handleMinimize = () => {
    setIsMinimize(!isMinimize)
  }

  
  const modalsAnimation = {
    hidden:{
      width:0,
      scale:0,
      opacity:0,
      transition:{
        duration:0.2,
        type:"spring",
        damping:15,
      }

    },
    show:{
      width:"90%",
      scale:1,
      opacity:100,
      transition:{
        duration:0.2,
        type:"spring",
        damping:15,
      }


    }
  }

  return (
    <AnimatePresence>
     {
      isSideBarActive && 
      <motion.div
      variants={modalsAnimation}
      initial="hidden"
      animate="show"
      exit="hidden"
       className='w-[90%] h-auto border-[1px] border-new_gray_200 rounded-xl flex-nowrap flex flex-col' >
        {/**header**/}
        <div className='w-full h-[50px] bg-[#FAFAFF] flex flex-row items-center justify-between border-b-[1px] border-new_gray_100 px-6  ' >
          <div className='flex items-center flex-row gap-4' >
            <img src='/media/add-widgets/data set.svg' className='lg:h-[15px] 2xl:h-[18px]' />
            <span className='lg:text-[12px] 2xl:text-[13px] font-Inter font-semibold ' >Dataset</span>
          </div>
          <div onClick={handleMinimize} className='flex relative' >
            <img  src='/media/add-widgets/minus.svg' className={`lg:h-[20px] 2xl:h-[20px] absolute ${isMinimize ? "rotate-90":"rotate-0"} transition-all  cursor-pointer hover:scale-110 transition-all `} />
            <img  src='/media/add-widgets/minus.svg' className='lg:h-[20px] 2xl:h-[20px] cursor-pointer hover:scale-110 transition-all ' />

          </div>
        </div>
        {/***data*/}
        <div className={`w-full ${isMinimize ? "h-0 opacity-0 pointer-events-none " :" min-h-[10px] max-h-auto "} transition-all  flex flex-col   `} >
          {
            isLoading &&
            <div className='w-full h-[50px] flex items-center justify-center ' >
              <Spinner/>
            </div>
          }
          {
           !isLoading && DataSets && DataSets.undefined?.map((item,index)=>{
              return(
                <Set key={index} datasource={item} type="Excel"  />
              )
            })
          }

          {
           !isLoading && DataSets && DataSets["Google Sheet"]?.map((item,index)=>{
              return(
                <Set key={index} datasource={item} type="GoogleSheet" />
              )
            })
          }


          
        </div>
     </motion.div>
     }

    </AnimatePresence>
    
  )
}

export default DataSet