import React, { useState,useRef, useEffect } from "react";
import DragColumn from "./dragColumns";


const Set = ({ datasource,type }) => {
  const [isActive, setisActive] = useState(false);

  const handleActive = () => {
    setisActive(!isActive);
  };

  const renderIcon = () => {
  
    switch (type) {
      case "GoogleSheet":
          return "google-sheet"
      break;

      case "Excel":
          return "excel-sheet"
      break;
    
      default:
        return "excel-sheet"
      break;
    }
  
  }


  return (
    <div className=" cursor-pointer border-b-[1px] border-new_purple_50 px-6  py-3 ">
      <div
        onClick={handleActive}
        className="flex flex-row items-center justify-between"
      >
        <div className="flex gap-1 items-center">
          <img src={`/media/data-sources/${renderIcon()}.svg`} className="h-[20px]" />
          <span className="lg:text-[12px] 2xl:text-[13px] font-Inter font-medium overflow-hidden ">
            {datasource.fileName}
          </span>
        </div>
        <img
          src="/media/dashboard/chevron-right.svg"
          className={`h-[20px] transition-all ${isActive ? " rotate-90 " : "rotate-0"} `}
        />
      </div>

      <div
        className={`w-full flex flex-col transition-all ${isActive ? "h-auto visible px-6  " : "h-0 opacity-0 pointer-events-none  py-0     "} transition-all  `}
      >
        {datasource?.columns.length > 0 &&
          datasource.columns.map((column, index) => {
            return <DragColumn key={index} column={column} sourceID={datasource._id} isActive={isActive} />;
          })}
      </div>
    </div>
  );
};

export default Set;

