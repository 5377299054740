import { createSlice } from '@reduxjs/toolkit'
import { createAsyncThunk } from '@reduxjs/toolkit';
import Charts from '../Services/charts';
import { act } from 'react';

const initialState = {
  currentChart :"number",
  numberChart:"",
  isCalculationLoading:false,
  lineGraph:{},
  barGraph:{},
  isBarGraphLoading:false,
  isLineGraphLoading:false,
  gaugeChart:{
    minValue:"",
    maxValue:""
  },
  isGuageLoading:false
}


export const Calculate = createAsyncThunk(`calculate-data`,async(payload)=>{
  const res = await Charts.calculateColumn(payload)
  return res;
})

export  const Gauge = createAsyncThunk(`Gauge-calculate`,async(payload)=>{
  const type = payload.type;
  const  item = payload.item

  const res = await Charts.calculateColumn(item)
  return {type,res};
}) 

export const DimensionalGraph = createAsyncThunk(`dimensional-graph`,async(payload)=>{
  const type = payload.type;
  const res = await Charts.dimensionalGraph(payload?.data)
  return {type,res};
})

export const ChartsSlice = createSlice({
  name: 'Charts',
  initialState,
  reducers: {
     setCurrentChart : (state,action) => {
      state.currentChart = action.payload
     },
     resetLine : (state,action) => {
      state.lineGraph = { }
     },
     resetBar:(state,action)=>{
      state.barGraph = { }
     }
  },
  extraReducers(builder) {
    builder
      .addCase(Calculate.pending,(state,action)=>{
        state.isCalculationLoading = true
      })
    .addCase(Calculate.fulfilled,(state,action)=>{
      state.isCalculationLoading = false
      state.numberChart = action.payload
      })
    .addCase(Calculate.rejected,(state,action)=>{
      state.isCalculationLoading = false
      })
      //Dimensional Graph
      .addCase(DimensionalGraph.pending,(state,action)=>{
        const type = action.payload?.type;

          if(type == "line"){
            state.isDimensionApiLoading = false
          }
          else if(type == "bar"){
            state.isLineGraphLoading = false
          }
      })
    .addCase(DimensionalGraph.fulfilled,(state,action)=>{


      const type = action.payload?.type;

        if(type == "line"){
          state.isLineGraphLoading = false
          state.lineGraph = action.payload.res
        }
       else if(type == "bar"){
          state.isBarGraphLoading = false
          state.barGraph = action.payload.res
      }

      
      })
    .addCase(DimensionalGraph.rejected,(state,action)=>{
        const type = action.payload?.type;

        console.log(action.payload,"PAYLOD")

        if(type == "line"){
          state.isDimensionApiLoading = false
        }
      else if(type == "bar"){
          state.isLineGraphLoading = false
      }
      })
      // Gauge Chart

      .addCase(Gauge.pending,(state,action)=>{
        state.isGuageLoading = true
        })
      .addCase(Gauge.fulfilled,(state,action)=>{
        state.isGuageLoading = false;
        
        const type = action.payload.type;

        state.gaugeChart = {
         ...state.gaugeChart,
           [type]:action.payload.res
        }
        })
      .addCase(Gauge.rejected,(state,action)=>{
        state.isGuageLoading = false;        
        })
  },
})

export const {setCurrentChart,resetLine,resetBar} = ChartsSlice.actions

export default ChartsSlice.reducer