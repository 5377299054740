import React,{createContext,Provider, useState} from 'react'
import DataSideBar from './DataSideBar'
import ChartType from './ChartType'
import WidgetPreview from './WidgetPreview'
import { WidgetContext } from './ChartType/DropZones/line/chartContext'

const CreateWidgets = () => {

  const [widgetData, setWidgetData] = useState();

  return (
    <WidgetContext.Provider value={{ widgetData, setWidgetData }}>
    <div className='w-full h-[calc(100%-70px)] flex  bg-[#F7F7F7] '>
       {/**Main Container**/}
       <div className='w-auto h-full bg-white flex flex-row  ' >
         <DataSideBar/>
         <ChartType/>
       </div>
         <WidgetPreview  />
    </div>
    </WidgetContext.Provider>

  )
}

export default CreateWidgets