import React from "react";

import DataIcon from "../../assets/LandingPage/data.svg";
import DashboardIcon from "../../assets/LandingPage/layout.svg";
import WorkspaceIcon from "../../assets/LandingPage/work.svg";
import Wrapper from "../../components/Wrapper";

const FeatureGrid = () => {
  return (
    <Wrapper>
      <div>
        <div className="flex items-center justify-center lg:mt-20 xl:mt-24 sm:mt-20">
          <button className="bg-[#170c31] text-[#9757ff] font-semibold px-3 py-1 rounded-full text-lg">
            Features
          </button>
        </div>
        <div className="bg-black text-white py-12 sm:px-4 font-Inter">
          <div className="text-center mb-10">
            <h2 className="lg:text-4xl xl:text-5xl sm:text-[24px] font-medium mb-3 text-center">
              Simplify dashboard workflows to enhance KPI performance
            </h2>
            <p className="text-white lg:text-lg sm:text-[16px] mt-6">
              Streamline dashboard workflows for enhanced KPI performance.
            </p>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-3 gap-8 lg:mt-20">
            <div className="text-center">
              <div className="flex justify-center items-center mb-4">
                <img src={WorkspaceIcon} alt="Workspace Icon" />
              </div>
              <h3 className="xl:text-xl lg:text-lg font-semibold mb-2">
                Create a workspace to manage your dashboards efficiently
              </h3>
              <p className="text-gray-400 xl:text-lg text-sm">
                Utilizing previous data enables identifying trends, analyzing
                performance, and making more informed decisions efficiently.
              </p>
            </div>

            <div className="text-center">
              <div className="flex justify-center items-center mb-4">
                <img src={DashboardIcon} alt="Workspace Icon" />
              </div>
              <h3 className="xl:text-xl lg:text-lg font-semibold mb-2">
                Create a workspace to manage your dashboards efficiently
              </h3>
              <p className="text-gray-400 xl:text-lg text-sm">
                Utilizing previous data enables identifying trends, analyzing
                performance, and making more informed decisions efficiently.
              </p>
            </div>
            <div className="text-center">
              <div className="flex justify-center items-center mb-4">
                <img src={DataIcon} alt="Workspace Icon" />
              </div>
              <h3 className="xl:text-xl lg:text-lg font-semibold mb-2">
                Create a workspace to manage your dashboards efficiently
              </h3>
              <p className="text-gray-400 xl:text-lg text-sm">
                Utilizing previous data enables identifying trends, analyzing
                performance, and making more informed decisions efficiently.
              </p>
            </div>
            <div className="text-center mt-12">
              <div className="flex justify-center items-center mb-4">
                <img src={WorkspaceIcon} alt="Workspace Icon" />
              </div>
              <h3 className="xl:text-xl lg:text-lg font-semibold mb-2">
                Create a workspace to manage your dashboards efficiently
              </h3>
              <p className="text-gray-400 xl:text-lg text-sm">
                Utilizing previous data enables identifying trends, analyzing
                performance, and making more informed decisions efficiently.
              </p>
            </div>
            <div className="text-center mt-12">
              <div className="flex justify-center items-center mb-4 xl:text-lg text-sm">
                <img src={DashboardIcon} alt="Workspace Icon" />
              </div>
              <h3 className="xl:text-xl lg:text-lg font-semibold mb-2">
                Create a workspace to manage your dashboards efficiently
              </h3>
              <p className="text-gray-400 xl:text-lg text-sm">
                Utilizing previous data enables identifying trends, analyzing
                performance, and making more informed decisions efficiently.
              </p>
            </div>
            <div className="text-center mt-12">
              <div className="flex justify-center items-center mb-4">
                <img src={DataIcon} alt="Workspace Icon" />
              </div>
              <h3 className="xl:text-xl lg:text-lg font-semibold mb-2">
                Create a workspace to manage your dashboards efficiently
              </h3>
              <p className="text-gray-400 xl:text-lg text-sm">
                Utilizing previous data enables identifying trends, analyzing
                performance, and making more informed decisions efficiently.
              </p>
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  );
};

export default FeatureGrid;
