import React,{useEffect, useState} from 'react'
import DropTarget from './DropTarget';
import { Gauge } from '../../../../../../../Redux/chartsSlice';
import { useDispatch } from 'react-redux';
import { FormatFormula } from '../Formula';

const ItemTypes = {
    NUMBER: 'number',
  };

const GaugeDrop = () => {

    const dispatch = useDispatch()

    // const [displayValue, setDisplayValue] = useState({ item: null, formula: 'SUM' });
    const [minValue, setMinValue] = useState({ item: null, formula: 'SUM' });
    const [maxValue, setMaxValue] = useState({ item: null, formula: 'SUM' });


    // useEffect(()=>{
    //   const payload = {
    //     type:"displayValue",
    //     item:{
    //       dataId:displayValue?.item?.dataId,
    //       column:displayValue?.item?.column,
    //       formula:FormatFormula(displayValue?.formula)
    //     }
    //   }
    //   dispatch(Gauge(payload))
    // },[displayValue])

    useEffect(()=>{
      const payload = {
        type:"minValue",
        item:{
          dataId:minValue?.item?.dataId,
          column:minValue?.item?.column,
          formula:FormatFormula(minValue?.formula)
        }
      }
      dispatch(Gauge(payload))
    },[minValue])
    
    useEffect(()=>{
      const payload = {
        type:"maxValue",
        item:{
          dataId:maxValue?.item?.dataId,
          column:maxValue?.item?.column,
          formula:FormatFormula(maxValue?.formula)
        }
      }
      dispatch(Gauge(payload))
    },[maxValue])

  return (
    <div className='w-full h-auto  flex flex-col items-center gap-4  ' >
        {/* <DropTarget
          accept={ItemTypes.NUMBER}
          label={"Value"}
          onDrop={setDisplayValue}
          item={displayValue.item}
          setValue={setDisplayValue}
          formula={displayValue.formula}
          setFormula={(formula) => setDisplayValue(prev => ({ ...prev, formula }))}
          handleDelete={() => setDisplayValue(prev => ({ ...prev, item:"",}))}
        /> */}
        <DropTarget
          accept={ItemTypes.NUMBER}
          label={"Minimum Value"}
          onDrop={setMinValue}
          item={minValue.item}
          setValue={setMinValue}
          formula={minValue.formula}
          setFormula={(formula) => setMinValue(prev => ({ ...prev, formula }))}
          handleDelete={() => setMinValue(prev => ({ ...prev, item:"", }))}

        />
        <DropTarget
          accept={ItemTypes.NUMBER}
          label={"Maximum Value"}
          onDrop={setMaxValue}
          item={maxValue.item}
          setValue={setMaxValue}
          formula={maxValue.formula}
          setFormula={(formula) => setMaxValue(prev => ({ ...prev, formula }))}
          handleDelete={() => setMaxValue(prev => ({ ...prev, item:"", }))}

        />
       
    </div>
  )
}

export default GaugeDrop