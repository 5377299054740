import React, { useEffect, useRef, useState } from "react";
import "/node_modules/react-grid-layout/css/styles.css"
import "/node_modules/react-resizable/css/styles.css"
import "./index.css"
import DashboardBuilder from "src/Helpers/DashboardBuilder";
import Menu2Chart from "../AddWidgets/CreateWidgets/ChartType/charts/menu2Chart";

 
const WidgetsPlayground = ({handleSave}) => {

    const playGroundRef = useRef();
    const cellHighlighter = useRef();
    const [playGroundDiemensions, setPlayGroundDiemensions] = useState({
        w: 0,
        h: 0
    });


    const { 
        initPlayground, 
        allowDrop,
        dropWidget,
        dragWidget,
        endDragging,
        startResizing,
        // startDragging, 
        // moveTo, 
        // endDragging 
    } = DashboardBuilder(
        playGroundRef, 
        setPlayGroundDiemensions,
        4 / 3,
        cellHighlighter
    );
    // 9 / 16,
    
    useEffect(() => {

        initPlayground();

    }, []);

  return (
    <div className="w-full  h-[calc(100vh-70px)] p-12 overflow-y-auto  ">

        <div className={`w-max min-h-12 border p-2 ms-auto bg-white`} ref={ playGroundRef } >
            <div class="w-[90vw] h-[768px]  grid grid-cols-12 gap-2 relative  " onDragOver={ allowDrop }>

                <div 
                    className="widget box-border w-[calc(calc(100%/12)*2)] h-[calc(calc(100%/12)*2)] z-[1001] cursor-pointer bg-[#0c0c0c] rounded border-4 border-white absolute flex items-center justify-center overflow-hidden" 
                    data-id={ 3 } 
                    draggable={ true }
                    onDragStart={ () => false }
                    onMouseDown={ dragWidget }>
                    <Menu2Chart />

                    <div 
                        className="resizeer absolute bottom-0 right-0 w-[6px] h-[6px] bg-blue-400 cursor-nw-resize"
                        onMouseDown={ startResizing }></div>
                </div>

                <div 
                    className="widget box-border w-[calc(calc(100%/12)*2)] h-[calc(calc(100%/12)*2)] z-[1001] left-[calc(calc(100%/12)*2)] cursor-pointer bg-[#0c0c0c] rounded border-4 border-white absolute flex items-center justify-center overflow-hidden" 
                    data-id={ 4 } 
                    draggable={ true }
                    onDragStart={ () => false }
                    onMouseDown={ dragWidget }>
                        <img src="/media/chart 1.jpg" />
                    <div 
                        className="resizeer absolute bottom-0 right-0 w-[6px] h-[6px] bg-blue-400 cursor-nw-resize"
                        onMouseDown={ startResizing }></div>
                </div>
                <div 
                    className="widget box-border w-[calc(calc(100%/12)*2)] h-[calc(calc(100%/12)*2)] z-[1001] left-[calc(calc(100%/12)*2)] cursor-pointer bg-[#0c0c0c] rounded border-4 border-white absolute flex items-center justify-center overflow-hidden" 
                    data-id={ 4 } 
                    draggable={ true }
                    onDragStart={ () => false }
                    onMouseDown={ dragWidget }>
                        <img src="/media/chart2.jpg" />
                    <div 
                        className="resizeer absolute bottom-0 right-0 w-[6px] h-[6px] bg-blue-400 cursor-nw-resize"
                        onMouseDown={ startResizing }></div>
                </div>

                <div 
                    className="widget box-border w-[calc(calc(100%/12)*1)] h-[calc(calc(100%/12)*1)] z-[1001] left-[calc(calc(100%/12)*4)] cursor-pointer bg-[#0c0c0c] rounded border-4 border-white absolute flex items-center justify-center overflow-hidden" 
                    data-id={ 5 } 
                    draggable={ true }
                    onDragStart={ () => false }
                    onMouseDown={ dragWidget }
                    >
                        <img src="/media/chart3.jpg" />
                    <div 
                        className="resizeer absolute bottom-0 right-0 w-[6px] h-[6px] bg-blue-400 cursor-nw-resize"
                        onMouseDown={ startResizing }></div>
                </div>

                <div className="box-border  absolute pointer-events-none z-[1000] hidden bg-blue-800/10 border border-dashed border-blue-500 rounded" ref={ cellHighlighter }></div>
            </div>
        </div>
      
    </div>
  );
};

export default WidgetsPlayground;