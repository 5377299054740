import React from "react";
import articleImg from "../../../assets/BlogPage/articleImg.png";
import articleImg2 from "../../../assets/BlogPage/articleImg2.png";

const FullArticle = () => {
  return (
    <div className=" w-full md:w-2/3">
      <p className="text-white text-[20px]">
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec
        ullamcorper mattis lorem non. Ultrices praesent amet ipsum justo massa.
        Eu dolor aliquet risus gravida nunc at feugiat consequat purus. Non
        massa enim vitae duis mattis. Vel in ultricies vel fringilla.
      </p>
      <div className="h-[2px] bg-white my-[32px]"></div>

      {/* Introduction Section */}
      <div className="py-8 text-white">
        <h1 className="text-[25px] font-semibold mb-4">Introduction</h1>
        <p className="mb-4 text-[18px] text-gray-50">
          Mi tincidunt elit, id quisque ligula ac diam, amet. Vel etiam
          suspendisse morbi eleifend faucibus eget vestibulum felis. Dictum quis
          montes, sit sit. Tellus aliquam enim urna, etiam. Mauris posuere
          vulputate arcu amet, vitae nisi, tellus tincidunt. At feugiat sapien
          varius id.
        </p>
        <p className="mb-4 text-[18px] text-gray-50">
          Eget quis mi enim, leo lacinia pharetra, semper. Eget in
          <a href="#" className="underline">volutpat mollis</a> at volutpat lectus
          velit, sed auctor. Porttitor fames arcu quis fusce augue enim. Quis at
          habitant diam at. Suscipit{" "}
          <a href="#" className="underline">tristique risus</a>, at donec. In
          turpis vel et quam imperdiet. Ipsum molestie aliquet sodales id est ac
          volutpat.
        </p>
        <img
          src={articleImg}
          alt="Descriptive Alt Text"
          className="w-full h-auto mb-4 object-cover object-center" // Adjust object-fit and object-position as needed
        />
        <figcaption className="text-left text-gray-300 text-sm">
          Image courtesy of Laura Davidson via Unsplash
        </figcaption>
        <blockquote className="italic border-l-[3px] text-[25px] font-normal border-new_purple_600 pl-4 text-white my-[48px]">
          “In a world older and more complete than ours they move finished and
          complete, gifted with extensions of the senses we have lost or never
          attained, living by voices we shall never hear.”
          <span className="block text-left text-gray-300 text-[16px] mt-[32px]">
            — Olivia Rhye, Product Designer
          </span>
        </blockquote>
        <p className="mb-4 text-[18px] text-gray-50">
          Mi tincidunt elit, id quisque ligula ac diam, amet. Vel etiam
          suspendisse morbi eleifend faucibus eget vestibulum felis. Dictum quis
          montes, sit sit. Tellus aliquam enim urna, etiam. Mauris posuere
          vulputate arcu amet, vitae nisi, tellus tincidunt. At feugiat sapien
          varius id.
        </p>
        <p className="mb-4 text-[18px] text-gray-50">
          Eget quis mi enim, leo lacinia pharetra, semper. Eget in
          <a href="#" className="underline">volutpat mollis</a> at volutpat lectus
          velit, sed auctor. Porttitor fames arcu quis fusce augue enim. Quis at
          habitant diam at. Suscipit{" "}
          <a href="#" className="underline">tristique risus</a>, at donec. In
          turpis vel et quam imperdiet. Ipsum molestie aliquet sodales id est ac
          volutpat.
        </p>

        {/* Second heading  */}
        <h1 className="text-[25px] font-semibold mb-4">Software and tools</h1>
        <p className="mb-4 text-[18px] text-gray-50">
          Pharetra morbi libero id aliquam elit massa integer tellus. Quis felis
          aliquam ullamcorper porttitor. Pulvinar ullamcorper sit dictumst ut
          eget a, elementum eu. Maecenas est morbi mattis id in ac pellentesque
          ac.
        </p>

        {/* Third heading */}
        <h1 className="text-[25px] font-semibold mb-4">Other resources</h1>
        <p className="mb-4 text-[18px] text-gray-50">
          Sagittis et eu at elementum, quis in. Proin praesent volutpat egestas
          sociis sit lorem nunc nunc sit. Eget diam curabitur mi ac. Auctor
          rutrum lacus malesuada massa ornare et. Vulputate consectetur ac
          ultrices at diam dui eget fringilla tincidunt. Arcu sit dignissim
          massa erat cursus vulputate gravida id. Sed quis auctor vulputate hac
          elementum gravida cursus dis.
        </p>
        <ol className="list-decimal ml-4 mb-4">
          <li>Lectus id duis vitae porttitor enim <a href="#" className="underline">gravida morbi.</a></li>
          <li>Eu turpis <a href="#" className="underline">posuere semper feugiat</a> volutpat elit, ultrices suspendisse. Auctor vel in vitae placerat.</li>
          <li>Suspendisse maecenas ac <a href="#" className="underline">donec scelerisque</a> diam sed est duis purus.</li>
        </ol>
        <img
          src={articleImg2}
          alt="Descriptive Alt Text"
          className="w-full h-auto mb-4 object-cover object-center" // Adjust object-fit and object-position as needed
        />
        <figcaption className="text-left text-gray-300 text-sm mb-4">
        Image courtesy of Leon via <a href="#" className="underline">Unsplash</a>
        </figcaption>
        <p className="mb-4 text-[18px] text-gray-50">
          Mi tincidunt elit, id quisque ligula ac diam, amet. Vel etiam
          suspendisse morbi eleifend faucibus eget vestibulum felis. Dictum quis
          montes, sit sit. Tellus aliquam enim urna, etiam. Mauris posuere
          vulputate arcu amet, vitae nisi, tellus tincidunt. At feugiat sapien
          varius id.
        </p>
        <p className="mb-4 text-[18px] text-gray-50">
          Mi tincidunt elit, id quisque ligula ac diam, amet. Vel etiam
          suspendisse morbi eleifend faucibus eget vestibulum felis. Dictum quis
          montes, sit sit. Tellus aliquam enim urna, etiam. Mauris posuere
          vulputate arcu amet, vitae nisi, tellus tincidunt. At feugiat sapien
          varius id.
        </p>

        {/* Last Heading */}
        <h1 className="text-[25px] font-semibold mb-4">Conclusion</h1>
        <p className="mb-4 text-[18px] text-gray-50">
          Mi tincidunt elit, id quisque ligula ac diam, amet. Vel etiam
          suspendisse morbi eleifend faucibus eget vestibulum felis. Dictum quis
          montes, sit sit. Tellus aliquam enim urna, etiam. Mauris posuere
          vulputate arcu amet, vitae nisi, tellus tincidunt. At feugiat sapien
          varius id.
        </p>
        <p className="mb-4 text-[18px] text-gray-50">
          Mi tincidunt elit, id quisque ligula ac diam, amet. Vel etiam
          suspendisse morbi eleifend faucibus eget vestibulum felis. Dictum quis
          montes, sit sit. Tellus aliquam enim urna, etiam. Mauris posuere
          vulputate arcu amet, vitae nisi, tellus tincidunt. At feugiat sapien
          varius id.
        </p>
        
      </div>
    </div>
  );
};

export default FullArticle;
