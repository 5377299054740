import React from "react";
import { heroSection } from "../constant";
import ReusableButton from "../../../components/IntegrationButton";

const HeroSection = () => {
  const words = heroSection.title.split(" ");

  // Check if there are enough words to style
  if (words.length < 5) {
    return <h1 className="text-white">{heroSection.title}</h1>; // Fallback for titles with less than 5 words
  }

  const firstTwoWords = words.slice(0, 1).join(" ");
  const middleWords = words.slice(1, -2).join(" ");
  const lastTwoWords = words.slice(-2).join(" ");

  return (
    <section className="relative font-Inter">
      <img
          src={heroSection.stars}
          alt="stars"
          className="absolute top-0 right-0 hidden md:block h-[648px] lg:h-[620px]"
        />
        <div
          className="
        absolute
        top-0
        left-0
        w-full
        md:h-[700px]
        sm:h-[648px]
        lg:h-[620px]
        bg-gradient-to-r
        from-[#060606]/0
        via-[#6938EF]/50
        to-[#060606]/0
        opacity-50
        z-30
        "
        ></div>

      <div className=" mx-auto w-[80%] sm:w-[90%] xs:w-[85%] md:w-[90%] lg:w-[85%] xl:w-[80%] h-[100%] max-w-6xl sm:py-[44px] xs:py-[65px] md:py-[44px] lg:py-[120px] ">
        
        <div className="flex justify-between items-center flex-col lg:flex-row gap-8 lg:gap-0 ">
          {/* Text Section */}
          <div className="flex flex-col md:w-[550px] lg:max-w-[612px] gap-[24px] md:gap-5 z-40">
            <h1 className="text-2xl lg:text-5xl font-bold">
              <span className="text-service_purple">{firstTwoWords} </span>
              <span className="text-white">{middleWords}</span>
              <span className="text-service_purple"> {lastTwoWords}</span>
            </h1>
            <p className="text-gray-100 text-[14px] ">
              {heroSection.description}
            </p>

            <ReusableButton
              label={heroSection.buttonText}
              className={
                "bg-new_purple_600 text-gray-100 rounded-lg w-full justify-center lg:w-fit mt-4"
              }
            />
          </div>

          {/* Image Section */}
          <div className="lg:w-[488px]">
            <img src={heroSection.heroImg} alt="heroImg" className="w-[299px] h-[232px] md:w-[488px] md:h-[380px]"/>
          </div>

        </div>

      </div>
    </section>
  );
};

export default HeroSection;