import React, { useState } from "react";
import { DndProvider, useDrag, useDrop } from 'react-dnd';


const DropTarget = ({accept, setFormula, formula, handleDelete, item,label,onDrop }) => {
  const [isActive, setIsActive] = useState(false);

  const [{ canDrop,isOver }, drop] = useDrop({
    accept: accept,
    drop:(col)=>{
       onDrop(prev => ({
         ...prev,
         item:col.column
       }))
    },
    collect: (monitor) => ({
        isOver: !!monitor.isOver(),
        canDrop: monitor.canDrop(),
    }),
  });



  return (
    <div className="w-full h-auto border-[1px] flex flex-col  ">
      <div className="w-full h-[40px] border-b-[1px] flex items-center px-4 ">
        <h2 className="font-Inter">{label}</h2>
      </div>
      <div className="w-full h-auto p-2 flex items-center justify-center">
        {/***DRAGGED BOX*/}
        {!item && (
          <div ref={drop}  className={`w-full h-[40px] border-dashed border-[3px] text-center ${canDrop && "border-blue-200 p-1  "}}"} `}>
           <div   className={`w-full h-full  text-center ${canDrop && " bg-blue-300 "}}"} `}>
            <span className="w-full h-full flex flex-row gap-2 items-center  justify-center">
            {canDrop ? (
                <span className="text-[14px] font-Inter text-white ">
                  Drop to Add
                </span>
              ) : (
                <>
                  <span className="text-[14px] font-Inter  ">Drag</span>
                  <span className="text-[14px] font-Inter text-green-400  ">
                    123
                  </span>
                </>
              )}
            </span>
           </div>
          </div>
        )}

        {item?.value && (
          <div className="w-full   ">
            <div ref={drop} className="w-full h-[30px] flex items-center justify-between gap-2 ">
              <div
                onClick={() => setIsActive(!isActive)}
                className=" flex gap-2 items-center"
              >
                <img
                  src="/media/dashboard/chevron-right.svg"
                  className="h-[18px] cursor-pointer"
                />
                <span className="text-[15px] font-Inter ">
                  {" "}
                  {formula} of {item.value}
                </span>
              </div>
              <img
                onClick={() => handleDelete()}
                src="/media/dashboard/delete.svg"
                className="h-[18px] cursor-pointer hover:scale-125 active:scale-90  transition-all  "
              />
            </div>
            <div
              style={{ height: isActive ? "50px" : "0px  " }}
              className={`w-full transition-all ${isActive ? " opacity-100  py-2" : " opacity-0 "} border-t-[1px]  flex flex-col  gap-2  overflow-hidden `}
            >
              <select
                value={formula}
                onChange={(e) => setFormula(e.target.value)}
                className="w-[150px] h-[40px] border-[1px] outline-none px-2 "
              >
                <option value={"sum"} >Sum</option>
                <option value={"Count of Row"}>Count of Row</option>
                <option value={"Minimum"} >Minimum</option>
                <option value={"Maximum"} >Maximum</option>
                <option value={"Distinct Count"} >Distinct Count</option>
              </select>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default DropTarget;
