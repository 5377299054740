import React, { useEffect } from 'react'
import { Outlet,useNavigate } from 'react-router-dom'

const Dashboard = () => {

  const navigate = useNavigate();

  useEffect(() => {
    if (window.location.pathname === "/dashboard") {
      navigate("/create/dashboard", { replace: true });
    }
  }, [navigate]);

  return (
    <div className='w-full min-h-[100vh]   ' >
      <Outlet/>
    </div>
  );
};

export default Dashboard;
