import React from "react";
import { useNavigate,Link,useLocation } from "react-router-dom";

const ExcelCard = ({data}) => {
  const {state} = useLocation();

    const navigate = useNavigate()

    const {_id,fileName,sourceDescription,sourceName,userId} = data

    const dataId = _id

    const handleAddData = () => {
        navigate(`/dashboard/${state}/select-data/${_id}`,{ state: {dataId,dashboardId:state.dashboardId}})
        localStorage.removeItem("excelSheet")
   }

  return (
    <div onClick={handleAddData} className=" w-full h-[60px] cursor-pointer  hover:bg-gray-100 active:scale-95 transition-all border-[1px] border-gray-100 flex flex-row items-center gap-2  p-4  rounded-md">
      <img
        src="/media/add-widgets/file-success.svg"
        className="w-[40px] h-[40px]"
      />
      <h2 className="font-Inter font-semibold">{fileName}</h2>
    </div>
  );
};

export default ExcelCard;
