import React,{lazy,Suspense} from 'react'
import { useSelector } from 'react-redux'
import Spinner from "../../../../components/spinner"

const DynamicData = () => {

  const tool = useSelector((state)=>state.tools.activeTool);
  const isActive = useSelector((state)=>state.tools.isPopUpActive)

  const Component = lazy(()=>import(`${tool?.path}`))

  return (
    <div>
      {
        isActive && 
        <Suspense >
         <Component />
       </Suspense>
      }
      
    </div>
  )
}

export default DynamicData