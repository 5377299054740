
export const dataArray = [
    {
       name:"active compaign",
       img:"int-1.png",
    },
    {
       name:"AirBrake",
       img:"int-1.png"
    },
    {
       name:"Aircall",
       img:"int-3.png",
      
    },
    {
       name:"Airtable",
       img:"int-4.png"
    },
    {
       name:"Excel Sheet",
       img:"int-8.png",
       path:"./Tools/Excel",
       component:"ExcelIntegration"
    },
    {
       name:"Amplitude",
       img:"int-5.png"
    },
    {
       name:"Data.ai",
       img:"int-6.png"
    },
    {
       name:"appFigures",
       img:"int-7.png"
    },
    {
       name:"Google Sheet",
       img:"int-8.png"
    },
    {
       name:"Bannerbear",
       img:"int-9.png"
    },
    {
       name:"Baremetrics",
       img:"int-10.png"
    },
    {
       name:"appFigures",
       img:"int-7.png"
    },
    {
       name:"Google Sheet",
       img:"int-8.png"
    },
    {
       name:"Bannerbear",
       img:"int-9.png"
    },
    {
       name:"Baremetrics",
       img:"int-10.png"
    },
    {
       name:"Bannerbear",
       img:"int-9.png"
    },
    {
       name:"Baremetrics",
       img:"int-10.png"
    },
    {
       name:"appFigures",
       img:"int-7.png"
    },
    {
       name:"Google Sheet",
       img:"int-8.png"
    },
    {
       name:"Bannerbear",
       img:"int-9.png"
    },
    {
       name:"Baremetrics",
       img:"int-10.png"
    },
    {
       name:"Baremetrics",
       img:"int-10.png"
    },
    {
       name:"appFigures",
       img:"int-7.png"
    },
    {
       name:"Google Sheet",
       img:"int-8.png"
    },
    {
       name:"Bannerbear",
       img:"int-9.png"
    },
    {
       name:"Baremetrics",
       img:"int-10.png"
    }
    ,
    {
       name:"Baremetrics",
       img:"int-10.png"
    },
    {
       name:"appFigures",
       img:"int-7.png"
    },
    {
       name:"Google Sheet",
       img:"int-8.png"
    },
    {
       name:"Bannerbear",
       img:"int-9.png"
    },
    {
       name:"Baremetrics",
       img:"int-10.png"
    }
 ]