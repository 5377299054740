import {InitiateProcessData as data} from './constant'
import IntegrationButton from './IntegrationButton'

const InitiateProcess = () => {
  return (
    <>
    <div
          className="
       
          lg:block
          h-[1px] 
          bg-gradient-to-r
          from-[#52729F]/0
        via-[#1D2939]
          to-[#52729F]/0
       
         "></div>
        <div className="flex flex-col justify-center items-start lg:items-center gap-7 
        mx-auto max-w-[340px] lg:max-w-[612px] px-6 lg:px-0 pt-[44px] pb-[44px] lg:pt-[78px] lg:pb-[78px]">

            <h1 className="text-2xl font-bold lg:text-5xl text-white text-left lg:text-center">{data.title}</h1>
            <p className="text-gray-100 text-[14px] font-normal text-left lg:text-center leading-[20px]">
            {data.description}
            </p>
            <IntegrationButton label={data.buttonText} className={'bg-new_purple_600 text-white text-lg justify-center font-medium px-5 py-2 w-fit rounded-md'}/>

        </div>
        <div
          className="
        
          lg:block
          h-[1px] 
          bg-gradient-to-r
          from-[#52729F]/0
        via-[#1D2939]
          to-[#52729F]/0
       
         "></div>
    </>
  )
}

export default InitiateProcess