import React from 'react'

const SearchBar = () => {
  return (
    <div className='w-full h-[50px] bg-[#F2F4F7] mt-6 mb-3 rounded-md flex items-center px-4 gap-2 ' >
        <img src='/media/data-sources/search.svg' className='h-[25px]' />
        <input type='text' placeholder='Search Resource' className='w-full p-2 bg-transparent outline-none font-Inter '/>
    </div>
  )
}

export default SearchBar