import bcrypt from 'bcryptjs'

import userAPI from "./user";

export default class UserProfile {
  setUserProfile = async (token) => {
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + token
    };
    return await userAPI.getProfile({headers})
      .then((res) => {
        const userProfile = JSON.stringify(res);
        localStorage.setItem("user-profile", userProfile);
        return res;
      })
      .catch((err) => {
        console.log(err);
      });
  };

  getUserProfile = () => {
    const userProfile = localStorage.getItem("user-profile");
    return JSON.parse(userProfile);
  };


  updateUserProfile = async (profileData) => {
    const token = localStorage.getItem('token');
    if (!token) {
      throw new Error('No authentication token found');
    }
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    };
    try {
      const res = await userAPI.updateProfile(profileData, { headers });
      await this.setUserProfile(token); // Update local storage with new profile data
      return res.data;
    } catch (err) {
      console.error('Error updating profile:', err);
      throw err;
    }
  };

  updatePassword = async (currentPassword, newPassword) => {
    const token = localStorage.getItem('token');
    if (!token) {
      throw new Error('No authentication token found');
    }
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    };
    try {
      const userProfile = this.getUserProfile();
      const match = await bcrypt.compare(currentPassword, userProfile.password);
      if (!match) {
        throw new Error('Current password is incorrect');
      }

      // const hashedNewPassword = await bcrypt.hash(newPassword, 10);
      const res = await userAPI.updatePassword({ currentPassword, newPassword }, { headers });

      // Update local storage with new password
      userProfile.password = newPassword;
      localStorage.setItem('user-profile', JSON.stringify(userProfile));

      return res.data;
    } catch (err) {
      console.error('Error updating password:', err);
      throw err;
    }
  };
}
