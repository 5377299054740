import { API } from "./api";


const AllDataSources = {
  getDataSources: ()=> API.get("/integrations"),
  getDataSets:()=>API.get("data/import/get-data-sources"),
  googleSheet:()=>API.post('integration/google-sheets/client-login'),
  getSheet:(payload)=>API.get(`integration/google-sheets/get-sheets?spreadsheetURL=${payload}`),
  saveSheet:(payload)=>API.get(`integration/google-sheets/get-sheet-data?spreadsheetURL=${payload.sheetURL}&sheetRange=${payload.sheetRange}&sheetTitle=${payload.sheetTitle}`)
 
}

export default AllDataSources;
