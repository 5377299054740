import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom';
import AuthApi from '../../../Services/Auth';
import Spinner from '../../../components/spinner';
import Alert from '../../../components/Alert';

const SendEmail = ({STATUS_TYPE,setForm,form}) => {

    const navigate = useNavigate()

    const [isLoading,setLoading] = useState(false)
    const [error,setError] = useState(false);
    const [message,setMessage] = useState("")

    const handleInput = (e) => {
        setForm(prev => ({
            ...prev,
            email: e.target.value
        }));
    }

    const handleSend = async () => {
        setLoading(true)
        setError(false)
        setMessage(" ")

        try {   
            await AuthApi.userForgotPass({email:form.email});
            setForm(prev=>({
                ...prev,
                status:STATUS_TYPE.SEND_OTP
            }));
            setError(false);
            setMessage(" ")
            setLoading(false);
        } catch (error) {
            setLoading(false);
            setError(true)
            setMessage(error?.response?.data?.error)
        }
    }

    const disabled = !form.email

  return (
        <div className=" w-[70%] max-w-[550px]  flex flex-col items-center gap-6 ">
          {/**Logo Baiynah**/}
          <div className="w-full flex flex-col gap-3 items-center p-4  ">
            <img
              src="/media/login/baiynah-logo.svg"
              className="mb-[10px] mt-[20px] "
            />
            <h2 className=" sm:text-[22px] 2xl:text-[30px] font-Inter font-semibold ">
            Reset Password
            </h2>
            <span className="sm:text-[14px] 2xl:text-[16px] font-Inter font-normal text-center text-[new_gray_700] text-[#344054] ">
            We suggest using the email adress you use at wok.
            </span>
          </div>
         
          {/**SIGN UP FORM **/}
          <div className='w-full  flex flex-col gap-6  ' >
            {
              error &&
              <Alert
               message={message}
               closeAction={() => setError(false)}
              />
            
            }
           <div className='w-full flex flex-col gap-1' >
              <span className='text-[#344054] font-Inter text-[14px] font-[500]' >Email</span>
              <input onChange={handleInput} name='email' value={form.email} type='email' placeholder='example@gmail.com' className='px-3 py-2 border-[1px] border-[#D0D5DD] shadow-[shadow_inputs] focus:border-new_purple_500  outline-none rounded-md' />
           </div>
          </div>
        {/**Button**/}
          <div className='w-full flex flex-col ' >
            <button disabled={disabled || isLoading } onClick={handleSend} className={`p-3 bg-new_purple_600 disabled:cursor-not-allowed disabled:bg-hovered_new_purple_600 hover:bg-hovered_new_purple_600 ${!disabled && "active:scale-95" } transition-all text-white font-Inter rounded-md `} >
             
             {
                isLoading ?
                <Spinner/>
                :
                "Send Email"
             }
            </button>
            <div className='flex flex-row gap-1 items-center justify-center mt-[30px] ' >
                <span className='text-[14px] font-Inter text-new_gray_500' >Have remembered the password?</span>
                <span onClick={()=>navigate('/login')} className='text-[14px] font-Inter cursor-pointer text-new_purple_600' ><b>Login</b></span>
            </div>
          </div>
          <div className="w-full h-[30px] block"></div>
        </div>
  )
}

export default SendEmail