import React,{useState,useEffect} from 'react'
import "./index.css"
import { useDispatch,useSelector } from 'react-redux'
import { GetExcelData } from '../../../../../Redux/SheetSlice'
import Spinner from '../../../../../components/spinner'

const SheetTable = ({isSideBarOpen,id}) => {

  const dispatch = useDispatch()

  const SheetData = useSelector((state)=>state.sheet.excelSheetData)
  const isLoading = useSelector((state)=>state.sheet.isSheetLoading)

  console.log(SheetData)

  

  useEffect(()=>{
    dispatch(GetExcelData(id))
  },[])

  const GetRowValues = (row) => {
    const values = Object.values(row);
    return values.map((value, index) => (
      <td key={index} className="px-6 py-3 font-Inter whitespace-nowrap capitalize">
        {
          value
        }
      </td>
    ));
  };


  

  return (
    <div className={`relative transition-all ${isSideBarOpen ? " lg:w-[70%] 2xl:w-[78%]" : "w-[100%]"} h-[calc(100vh-180px)] bg-white rounded-md px-6 py-7 flex flex-col gap-4 overflow-hidden `} >
        {
          isLoading ? 
          <div className='absolute w-full h-full top-0 left-0 flex flex-col gap-2 items-center justify-center' >
              <Spinner/>
              <h2>Loading...</h2>
          </div>
          :
          <>
           <div className='w-full h-[50px] flex  justify-between ' >
            <h2 className='text-[16px] font-Inter font-semibold ' >Please select your data</h2>
            <div className=' w-[173px] h-[40px]  flex flex-row items-center bg-new_purple_50 rounded-full p-1  ' >
                <span className='text-[14px] text-new_purple_700 font-Inter font-semibold bg-white w-1/2 h-full rounded-full flex items-center justify-center ' >Data Set</span>
                <span className='text-[14px] text-new_purple_700 font-Inter font-semibold  w-1/2 h-full rounded-full flex items-center justify-center ' >1000 Data</span>
            </div>
           </div>
          <div class="relative px-2  min-h-[400px] dataTable_Container overflow-x-auto overflow-y-auto     ">      
              <table class="w-full text-sm text-left rtl:text-right text-gray-500 border-[1px] border-new_gray_100 rounded-lg    ">
                  <thead class="text-xs text-gray-700 uppercase bg-[#FAFAFF]">
                      <tr>
                          
                        {
                          SheetData && SheetData.length  > 0 && 
                           Object.values(SheetData[0]).map((item,index)=>{
                            return(
                              <th key={index} scope="col" class="px-6 py-3 font-Inter whitespace-nowrap capitalize ">
                              {item}
                              </th>
                            )
                           })
                        }


                      </tr>
                  </thead>
                  <tbody>

                   {
                    SheetData && SheetData.length  > 0 && SheetData.slice(1).map((row,index)=>{
                      return(
                        <tr key={index} className="border-b border-[1px] border-new_gray_100   ">
                         {GetRowValues(row)}
                      </tr>
                      )
                    })
                   }
                    
                  </tbody>
              </table>
          </div>
          </>
        }


        
       
    </div>
  )
}

export default SheetTable