import React from 'react'
import ExcelCard from './excel-card'

const UserSheetsData = ({ExcelSheets}) => {
  return (
    <div className='w-full h-[200px] flex flex-col gap-2  overflow-y-auto' >
        {
            ExcelSheets?.length > 0 ? ExcelSheets.map((item,index)=>{
                return(
                    <ExcelCard key={index}  data={item} />
                )
            }):""
        }
    </div>
  )
}

export default UserSheetsData