import React, { useEffect, useState } from "react";
import DropTarget from "./DropTarget";
import DropYTarget from "./DropXTarget";
import { DimensionalGraph ,resetBar } from "../../../../../../../Redux/chartsSlice";
import { useDispatch } from "react-redux";

const ItemTypes = {
  NUMBER: "number",
  STRING: "string",
};

const BarDrop = () => {
  const dispatch = useDispatch();

  const [xAxis, setxAxis] = useState(" ");
  const [yAxis, setyAxis] = useState([]);

  const handleDelete = (id) => {
    const filter = yAxis.filter((item, index) => {
      return item._id !== id;
    });
    setyAxis(filter);
  };

  useEffect(() => {
    if (!xAxis == " " && yAxis.length > 0) {
      //Array which contain two dimensional items
      const newArray = [];

      const newXAxis = {
        dataId: xAxis?.dataId,
        columnName: xAxis?.column,
        axis: xAxis?.axis,
      };

      newArray.push(newXAxis);



      //Maping
      yAxis?.map((item, index) => {
        const newItem = {
          dataId: item?.dataId,
          columnName: item?.column,
          axis: item?.axis,
        };
        newArray.push(newItem);
      });

      let payload = {
        type:"bar",
        data:newArray
      }

      dispatch(DimensionalGraph(payload));
    }
    else{
      dispatch(resetBar())
    }
  }, [xAxis, yAxis]);

 
  return (
    <div className="w-full h-auto  flex flex-col items-center gap-4  ">
      <DropTarget
        accept={[ItemTypes.NUMBER, ItemTypes.STRING]}
        label={"X-axis"}
        onDrop={setxAxis}
        item={xAxis}
        setValue={setxAxis}
        handleDelete={() => setxAxis(" ")}
      />
      <DropYTarget
        accept={[ItemTypes.NUMBER, ItemTypes.STRING]}
        label={"Y-axis"}
        onDrop={setyAxis}
        item={yAxis}
        setValue={setyAxis}
        handleDelete={handleDelete}
      />
      <div className="w-full h-[45px] bg-blue-400 rounded-md mt-[10px] text-white font-Inter inline-flex items-center justify-center " >
        Add Widget
      </div>
    </div>
  );
};

export default BarDrop;
