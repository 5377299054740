import React from 'react'
import Layout from "../layout";
import {heading} from "./constant";
import Container from "../../components/Container";
import Heading from "../../components/Heading";
import Faq from './section/Faq';

const HelpCenter = () => {
  return (
    <div className="bg-black ">
        <Layout>
        <div className='mx-auto w-[80%] sm:w-[90%] xs:w-[85%] md:w-[90%] lg:w-[85%] xl:w-[80%] h-[100%] max-w-6xl'>
           <Heading
            title={heading.title}
            description={heading.description}
            />
            <div className='h-[1px] bg-gray-800'></div>

            <div className=''>
            <Faq/>
            </div>
        </div>
        </Layout>
    </div>
  )
}

export default HelpCenter