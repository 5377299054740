import React from 'react'
import { NewsLetter } from '../constant'
import { Send } from 'lucide-react'
import IntegrateButton from '../../../components/IntegrationButton'

const SideBar = () => {

  const words = NewsLetter.text.split(" ");

  const firstTwoWords = words.slice(0, -2).join(" ");
  const middleWords = words.slice( -2).join(" ");

  return (
    <div className='w-full md:w-1/3 bg-[#F7FCF8]/10 rounded-2xl p-8 flex flex-col h-fit'>
      <div className='bg-[#F7FCF8]/10 rounded-full text-white text-xl w-fit p-4'><Send /></div>
      <p className='text-white text-2xl font-semibold mt-4'>{NewsLetter.title}</p>
      <p className='text-gray-400 text-md mt-4'>{NewsLetter.description}</p>
      <input type='text' placeholder='Enter your email' className='bg-white border-[1px] border-gray-300 text-black mt-4 p-2 rounded-lg'/>
      <p className='text-blue-400 text-md mt-2'>{firstTwoWords} <a href="#" className='underline cursor-pointer'>{middleWords}</a></p>
      <IntegrateButton label={NewsLetter.buttonText} className={'bg-new_purple_600 rounded-lg w-full flex justify-center mt-4'}/>
    </div>
  )
}

export default SideBar