import Layout from "../layout";
import Container from "../../components/Container";
import HeroSection from './section/HeroSection';
import Template from "./section/Template";
import InitiateProcess from "../../components/InitiateProcess";

const Kpi = () => {
  return (
    <div className="bg-black ">
        <Layout>
  
          <HeroSection/>
          <Template/>
     {/* Initiate the Process */}
     <InitiateProcess />
        </Layout>
    </div>
  )
}

export default Kpi