import React, { useRef, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { GetDataSources} from "../../../../../../Redux/dataSourcesSlice";
import { motion, AnimatePresence } from "framer-motion";
import AllDataSource from "./allDataSource";
import SwitchSources from "./switchSources";



const DataSourceModal = ({ isPopupActive, setIsPopupActive }) => {
  const modalRef = useRef();
  const dispatch = useDispatch()


  useEffect(() => {
    const handleClickOutside = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        setIsPopupActive(false);
      }
    };

    window.addEventListener("mousedown", handleClickOutside);
    return () => {
      window.removeEventListener("mousedown", handleClickOutside);
    };
  }, [setIsPopupActive]);



  const hide = isPopupActive ? "opacity-1" : "opacity-0 pointer-events-none";

  return (
    <div
      className={`${hide} w-full h-[100vh] flex items-center justify-center fixed top-0 left-0 z-[100] bg-[#344054a2] bg-opacity-75 backdrop-blur-md`}
    >
     <AnimatePresence>
    {isPopupActive && (
      <motion.div
        initial={{
          scale: 0,
          opacity: 0,
        }}
        animate={{
          scale: 1,
          opacity: 1,
          transition: {
            duration: 0.1,
            type: "spring",
            damping: 25,
            stiffness: 500,
          },
        }}
        exit={{
          scale: 0,
          opacity: 0,
          transition: {
            duration: 0.1,
            type: "spring",
            damping: 25,
            stiffness: 500,
          },
        }}
        ref={modalRef}
        className={`w-[70%] h-[80%] bg-white rounded-md backdrop-blur-md px-6 overflow-y-auto `}
      >    
       <SwitchSources/>
      </motion.div>
    )}
  </AnimatePresence> 
    </div>
  );
};

export default DataSourceModal;