import React, { useState } from 'react'
import AllDataSource from './allDataSource'
import Excel from './Sources/Excel'
import GoogleSheet from './Sources/Google'

const SwitchSources = () => {

    const [currentTab,setCurrentTab] = useState({
       name:"AllSources",
       id:""
    })

    const renderTab = () => {
       
        switch (currentTab.name) {
            case "AllSources":
                 return <AllDataSource setCurrentTab={setCurrentTab} />;
                break;

            case "Excel":
                return <Excel currentTab={currentTab} setCurrentTab={setCurrentTab} />;
                break;

            case "Google Sheet":
                return <GoogleSheet currentTab={currentTab} setCurrentTab={setCurrentTab} />;
                break;
        
            default:
                return <AllDataSource setCurrentTab={setCurrentTab}/>;
        }

    }


  return (
    <>
     {
        renderTab()
     }
    </>
  )
}

export default SwitchSources