import { createSlice } from '@reduxjs/toolkit'
import AddWidgets from '../Services/add-widgets';
import { createAsyncThunk } from '@reduxjs/toolkit';

const initialState = {
    isSheetLoading:false,
    sheetData:[],
    excelSheetData:{},
    dataCalulationLoading:false,
    calulatedData:"",
    isSideBarActive:false,
    columnNames:[],
    selectedColumn:"",
    selectedFormula:"",
    selectedColumnData:{
   
    }    
}

export const GetUserSheetData = createAsyncThunk("get-user-data",async(payload)=>{
    const res = await AddWidgets.getUserData();
    return res;
})

export const GetExcelData = createAsyncThunk("get-excel-data",async(params)=>{
      const res = await AddWidgets.getExcelData(params)
      return res;
})

export const DataCalculation = createAsyncThunk(`data-calculation`,async(payload)=>{
    const res = await AddWidgets.dataCalculation(payload)
    return res;  
})

export const GetColumnNames = createAsyncThunk(`get-column-names`,async(params)=>{
      const res = await AddWidgets.getColumnNames(params)
      return res;
})


export const SheetSlice = createSlice({
  name: 'Sheet',
  initialState,
  reducers: {

      resetSheet : (state,action) => {
            state.excelSheetData = []
      },
      setisSideBarActive : (state,action) => {
            state.isSideBarActive = !state.isSideBarActive
      },
      setSelectedFormula : (state,action) => {
            state.selectedFormula = action.payload
      },
     
      setSelectedColumn : (state,action) => {
            state.selectedColumn = action.payload
      },
      setColumnData : (state,action) => {
            state.selectedColumnData = action.payload
      },
      setSideBarOpen:(state,action) => {

      }

     

  },
  extraReducers(builder) {
    builder
      .addCase(GetUserSheetData.pending,(state,action)=>{
           
      })
      .addCase(GetUserSheetData.fulfilled,(state,action)=>{
           
            state.sheetData = action.payload
      })
      .addCase(GetUserSheetData.rejected,(state,action)=>{
          
      })

      //Get Excel Data
      .addCase(GetExcelData.pending,(state,action)=>{
            state.isSheetLoading = true
            state.excelSheetData = []
      })
      .addCase(GetExcelData.fulfilled,(state,action)=>{
            state.isSheetLoading = false
            state.excelSheetData = action.payload

      })
      .addCase(GetExcelData.rejected,(state,action)=>{
            state.isSheetLoading = false
      })

      // Get Column Names

      .addCase(GetColumnNames.pending,(state,action)=>{
           
      })
      .addCase(GetColumnNames.fulfilled,(state,action)=>{
        state.columnNames = action.payload.columnNames 
      })
      .addCase(GetColumnNames.rejected,(state,action)=>{
       
      })

      //Data Calulations
     .addCase(DataCalculation.pending,(state,action)=>{
            state.dataCalulationLoading = true
     })
     .addCase(DataCalculation.fulfilled,(state,action)=>{
            state.dataCalulationLoading = false;
            state.calulatedData = action.payload
     })
     .addCase(DataCalculation.rejected,(state,action)=>{
            state.dataCalulationLoading = false;
     })
   
  },
})

export const {resetSheet,setisSideBarActive,setSideBarOpen,setSelectedFormula,setSelectedColumn,setColumnData} = SheetSlice.actions
export default SheetSlice.reducer