import React from "react";
import Popular from "../../assets/Pricing/popular.svg";
import Wrapper from "../../components/Wrapper";

const plans = [
  {
    name: "Select Your Plan",
    disc: "Yearly Disc.20%",
    price: "",
    description: "Foundations for Success",
    features: [
      { label: "Data Source", value: "Unlimited Source" },
      { label: "Users", value: "10" },
      { label: "Send to TV", value: "3" },
      { label: "Dashboard", value: "10 Dashboards" },
      { label: "Dashboard", value: "Unlimited Source" },
      { label: "Dashboard", value: "Unlimited Source" },
    ],
    monthly: true,
    yearlyDiscount: null,
  },
  {
    name: "Essential",
    disc: "",
    price: "Free",
    description: "Perfect plan for starters",
    features: [
      { label: "Data Source", value: "Limited Source" },
      { label: "Users", value: "3" },
      { label: "Send to TV", value: "1" },
      { label: "Dashboard", value: "3 Dashboards" },
      { label: "Dashboard", value: "3 Dashboards" },
      { label: "Dashboard", value: "3 Dashboards" },
    ],
    monthly: true,
    yearlyDiscount: null,
    popular: false,
  },
  {
    name: "Pro",
    disc: "",
    price: "$159",
    originalPrice: "$259",
    description: "Perfect plan for Your business",
    features: [
      { label: "Data Source", value: "Unlimited Source" },
      { label: "Users", value: "10" },
      { label: "Send to TV", value: "3" },
      { label: "Dashboard", value: "10 Dashboards" },
      { label: "Extra Feature", value: "Unlimited Source" },
      { label: "Extra Feature", value: "Unlimited Source" },
    ],
    monthly: true,
    yearlyDiscount: null,
    popular: true,
  },
  {
    name: "Scale",
    disc: "",
    price: "$240",
    description: "Perfect plan for Your business",
    features: [
      { label: "Data Source", value: "Unlimited Source" },
      { label: "Users", value: "25" },
      { label: "Send to TV", value: "10" },
      { label: "Dashboard", value: "30 Dashboards" },
      { label: "Extra Feature", value: "Unlimited Source" },
      { label: "Extra Feature", value: "Unlimited Source" },
    ],
    monthly: false,
    yearlyDiscount: null,
    popular: false,
  },
];

const PlanCard = ({ plan, isFirstCard, isThirdCard }) => (
  <div
    className={`p-4 md:p-6 shadow-lg relative grid gap-4 sm:gap-6 mb-4 sm:mb-0 md:mb-0 grid-rows-[auto_1fr_auto] ${
      plan.popular && !isFirstCard
        ? "lg:bg-[linear-gradient(to_top,#1e1e1f_54.25%,#6938ef_50%)] sm:bg-[linear-gradient(to_top,#6938ef_54.25%,#6938ef_50%)] text-white"
        : "bg-black text-white border border-gray-800"
    }`}
  >
    {plan.popular && (
      <div className="absolute -top-4 right-4 px-3 py-1 rounded-full text-sm">
        <img src={Popular} className="w-[106px] h-[22px]" alt="logo" />
      </div>
    )}
    <div>
      <h2 className="text-[22px] font-semibold">{plan.name}</h2>
      <p
        className={`${plan.popular ? "text-white" : "text-gray-400"} text-[16px]`}
      >
        {plan.description}
      </p>
    </div>
    <div className="text-left">
      <div className="flex items-baseline justify-left space-x-2">
        {plan.originalPrice && (
          <p className="text-gray-400 line-through text-[20px]">
            {plan.originalPrice}
          </p>
        )}
        <p className="text-[36px] font-bold">{plan.price}</p>
      </div>
      {!isFirstCard && <p className="text-gray-300">/ Month</p>}
    </div>
    <div className="flex justify-between mt-0">
      {isFirstCard ? (
        <div className="bg-[#1e1e1f] rounded-lg flex items-center justify-between w-auto -my-2 flex-row py-2 lg:px-1 sm:hidden lg:flex xl:flex">
          <button className="text-white bg-[#6938ef] rounded-lg transition duration-150 ease-in-out py-2 xl:px-3 lg:px-2 focus:outline-none flex-grow xl:w-24 xl:ml-1 lg:w-20">
            Monthly
          </button>
          <p className="text-white bg-transparent rounded-lg transition duration-150 ease-in-out py-2 xl:px-1 lg:px-1 xl:ml-3 lg:ml-1 focus:outline-none flex-grow text-sm whitespace-nowrap">
            {plan.disc}
          </p>
        </div>
      ) : (
        <button
          className={`${
            plan.popular
              ? "bg-white text-[#6938ef] hover:bg-[#6938ef] hover:text-white font-bold"
              : plan.name === "Scale"
                ? "bg-[#6938ef] hover:bg-purple-800 text-white font-bold"
                : "bg-transparent border border-white hover:bg-white hover:text-[#6938ef] text-white font-bold"
          } py-3 px-6 rounded w-full transition duration-150 ease-in-out`}
        >
          {plan.popular
            ? "Subscribe"
            : plan.name === "Scale"
              ? "Subscribe"
              : "Try It for Free"}
        </button>
      )}
    </div>
    <ul className="w-full list-none m-0 p-0 mt-10">
      {plan.features.map((feature, index) => (
        <li
          key={index}
          className={`border-t lg:border-gray-900 py-2 px-4 ${
            plan.popular && isThirdCard
              ? "sm:border-gray-900"
              : "sm:border-gray-900"
          }`}
          style={{
            padding: "18px 20px",
            margin: "0 -16px",
          }}
        >
          <div className="md:hidden flex justify-between items-center p-0">
            <span
              className={`flex-1 pr-4 ${plan.popular ? "sm:text-white" : "text-gray-500"}`}
            >
              {feature.label}
            </span>
            <span className="flex-1 pl-4">{feature.value}</span>
          </div>
          <div className="hidden md:block">{feature.value}</div>
        </li>
      ))}
    </ul>
  </div>
);
const PricingComponent = () => {
  return (
    <Wrapper>
      <div className="px-4 sm:px-6 mx-auto">
        <div className="bg-black py-12 font-Inter text-left">
          <h2 className="text-3xl md:text-4xl font-bold text-white mb-[8px] text-left md:text-left">
            Plans & Pricing
          </h2>
          <p className="text-white md:text-left text-[14px] mb-8 leading-[20px]">
            Straightforward Pricing. No Hidden Fees. Advanced Features Tailored
            for Your Business.
          </p>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 mt-20 mb-12 lg:gap-0 xl:gap-0 sm:gap-2">
            {plans.map((plan, index) => (
              <PlanCard
                key={index}
                plan={plan}
                isFirstCard={index === 0}
                isThirdCard={index === 2}
              />
            ))}
          </div>
        </div>

        <div className="bg-black text-white text-center py-12 mt-20 font-Inter flex-col items-center justify-center sm:hidden xs:hidden md:block lg:block">
          <h3 className="text-5xl font-medium mb-4">Initiate the process</h3>
          <p className="mb-6 text-[14px]">
            Experience the convenience of getting up and running effortlessly
            within minutes by taking advantage of our complimentary 14-day
            trial. Rest assured, there's no need to provide any credit card
            information during this trial period.
          </p>
          <button className="bg-[#6938ef] hover:bg-purple-700 text-white font-semibold py-2 px-4 rounded-lg mt-[48px] text-[16px] mb-[38px]">
            Let's Get Started
          </button>
        </div>
      </div>
    </Wrapper>
  );
};

export default PricingComponent;
