import React from "react";

const ReusableButton = ({ label, onClick, className, logo }) => {
  const defaultStyles = `
    flex items-left justify-left
    text-white font-semibold xl:px-3 lg:px-1 md:px-1 py-2 
    rounded-full focus:outline-none focus:ring 
    w-[220px] text-center
    transform transition hover:scale-105 duration-300 ease-in-out
  `;

  const combinedStyles = `${defaultStyles} ${className}`;

  return (
    <button onClick={onClick} className={combinedStyles}>
      {logo && <img src={logo} alt="Logo" className="mr-2 h-6" />}
      {label}
    </button>
  );
};

export default ReusableButton;
