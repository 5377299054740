import React, { useEffect, useState } from 'react'
import {useDispatch} from "react-redux"
import AllDataSources from '../../../../../../../../Services/data-sources'
import Spinner from '../../../../../../../../components/spinner'
import Sheeturl from './sheeturl'
import { GetDataSets } from '../../../../../../../../Redux/dataSourcesSlice'
const SelectSheet = () => {


  const [sheetURl,setSheetURL] = useState("")
  const [allSpreadSheets,setAllSpreadSheets] = useState([])
  const [isLoading,setisLoading] = useState(false)

  console.log(allSpreadSheets,"ALL SPREAD")

  const handleURL = async (e) => {

    const {value} = e.target;
    setSheetURL(value)
  }


  const getData = async () => {
    try {
      setisLoading(true)
      const resp = await AllDataSources.getSheet(sheetURl)
  
      if(resp){
        console.log(resp)
        setAllSpreadSheets(resp.response)
        setisLoading(false)
      }
      
     } catch (error) {
      setisLoading(false)
     }
  }



  useEffect(()=>{
    if(!sheetURl) return
    getData()
  },[sheetURl])

   

  return (
    <div className='w-full h-full flex flec-col px-6 ' >
        <div className='w-[600px] h-auto flex flex-col gap-6 ' >
           <div className='flex flex-row gap-8' >
            <select className='w-[65%] h-[45px] border-[1px] border-[#D0D5DD] px-4 outline-none rounded-md ' >
                <option>Mohsin Rahman</option>
                <option>Yaseen Rahman</option>
            </select>
            <button className='w-[30%] h-[45px] bg-[#D9DCE1] hover:bg-[#d9dce1cc] inline-flex gap-2 items-center justify-center rounded-md active:scale-90 transition-all ' >
                <img src='/media/data-sources/plus.svg'/>
                <span className=' font-Inter font-medium ' >Add Account</span>
            </button>
           </div>
           <div className='w-full h-[45px] flex items-center justify-center border-[1px] outline-none rounded-md border-[#D0D5DD] px-4' >
            {
              isLoading ? <Spinner/> :
              <input onChange={handleURL} type='text' placeholder='Add Link to Sheet' className='w-full outline-none h-full' />

            }
           </div>

            <div className='w-full h-auto flex flex-col  border-[1px] border-[#D0D5DD] rounded-md  ' >
             {
             allSpreadSheets && allSpreadSheets.length > 0 && allSpreadSheets.map((item,index)=>{
                return(
                   <Sheeturl item={item} sheet={sheetURl} />
                )
              })
             }
             
             
            
            </div>
        </div>
    </div>
  )
}

export default SelectSheet