import React from 'react'
import { servicesList } from "../constant";

const ServiceCard = () => {

  const FirstServiceCard = servicesList.slice(0, 3);
  const SecondServiceCard = servicesList.slice(3, 6);
  
  const Card = ({ icon, title, description, shouldHideVerticalLine,removeLast }) => (
    <div className="flex flex-col lg:flex-row">
      <div className="flex flex-col items-start py-3 lg:py-0  shadow rounded-lg  max-w-[339px]">
        <img src={icon} alt={title} className="w-[52px] h-[52px] mb-8" />
        <h3 className="text-xl font-medium text-white mb-[16px]">{title}</h3>
        <p className="text-sm font-normal text-gray-400">{description}</p>
      </div>

      {/* Line width and height */}
      {/* Verticle Line */}
      {
        !shouldHideVerticalLine && (
        <div
        className="
        ml-[32px]
        hidden
        lg:block
        w-[1.5px] 
        bg-gradient-to-r
        from-[#52729F]/0
        via-[#1D2939]
        to-[#52729F]/0
        "
      ></div>
        )
      }

      {/* Horizontal Line */}
      {
        !removeLast && (
          <div
        className="
        lg:hidden
        h-[2px] 
        bg-gradient-to-r
        from-[#52729F]/0
        via-[#1D2939]
        to-[#52729F]/0
      mt-[32px]
        "
      ></div>)
      }

    </div>
  );

  return (
    <div className="flex flex-col justify-center gap-[32px] lg:gap-0">
        <div className="flex flex-col lg:flex-row gap-[32px] ">
          {FirstServiceCard.map((service, index) => (
            <Card key={index} {...service} shouldHideVerticalLine={index === 2}/>
          ))}
        </div>

        <div
          className="
          hidden
          lg:block
          h-[1px] 
          bg-gradient-to-r
          from-[#52729F]/0
        via-[#1D2939]
          to-[#52729F]/0
          my-[44px]
         "></div>

        <div className="flex flex-col lg:flex-row gap-[32px]">
          {SecondServiceCard.map((service, index) => (
            <Card key={index} {...service} shouldHideVerticalLine={index === 2} removeLast={index==2?true:false}/>
          ))}
        </div>
      </div>
  )
}

export default ServiceCard