import React from 'react'
import { useDispatch } from 'react-redux'
import { setSelectedFormula } from '../../../../../Redux/SheetSlice'

const Formulas = ({title,selectedFormula,formula }) => {

  const dispatch = useDispatch()
    const handleSelect = () => {
        dispatch(setSelectedFormula(title))
    }

    const handleCancel = () => {     
      dispatch(setSelectedFormula(""))
    }
 

  return (
   <>
     <div onClick={handleSelect} className={`px-8 py-3 cursor-pointer transition-all flex flex-row justify-between gap-2 border-b-[1px] border-new_purple_50 ${selectedFormula == title ?"bg-new_purple_50":""}  `} >
      <h2  className={`lg:text-[14px] 2xl"text-[16px] font-Inter font-semibold ${selectedFormula == title ? "text-new_purple_600":""} `} >{title}</h2>
      {
        selectedFormula == title ?
        <img onClick={handleCancel}  src='/media/add-widgets/cancel.svg'  className='h-[25px]' />
        :
        <img src='/media/add-widgets/info-formula.svg'/>
      }
    </div>
   </>
  )
}

export default Formulas