import React from 'react'
import { useParams } from "react-router-dom"
import { ArticlesData } from './constant';
import Layout from "../layout";
import Container from "../../components/Container";
import TopArticle from './section/TopArticle';
import FullArticle from './section/FullArticle';
import SideBar from './section/SideBar';
import Subscribebar from '../../components/Subscribebar'

const Articles = () => {
  const { id } = useParams();
  const data = ArticlesData.find((data) => data.id === parseInt(id));
 
  return (
    <div className="bg-black ">
        <Layout>
       <div className='mx-auto w-[80%] sm:w-[90%] xs:w-[85%] md:w-[90%] lg:w-[85%] xl:w-[80%] h-[100%] max-w-6xl'>
          <TopArticle data={data}/>
          <div className='flex flex-col md:flex-row gap-[20px] md:gap-[96px]'>
            <FullArticle data={data}/>
            <SideBar/>
          </div>
          </div>
          <Subscribebar/>
        </Layout>
    </div>
  )
}

export default Articles