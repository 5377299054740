import React from 'react'
import Layout from "../layout";
import Container from "../../components/Container";
import HeroSection from './section/HeroSection';
import ServicesList from './section/ServicesList';
import InitiateProcess from '../../components/InitiateProcess';

const Services = () => {
  return (
    <div className="bg-black font-Inter">
        <Layout>
    
          <HeroSection/>
          <ServicesList/>
       {/* Initiate the Process */}
      <InitiateProcess/>
        </Layout>
    </div>
  )
}

export default Services