import React from "react";

const Wrapper = ({ children }) => {
  return (
    <div className="w-full">
      <div className="w-[80%] sm:w-[90%] xs:w-[85%] md:w-[90%] lg:w-[85%] xl:w-[80%] h-[100%] max-w-6xl mx-auto">
        {children}
      </div>
    </div>
  );
};

export default Wrapper;
