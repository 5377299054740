import React, { useState, useEffect } from 'react';
import UserProfileService from '../../../../../Services/userProfile';
import { FaEye, FaEyeSlash } from 'react-icons/fa';

const AccountSettings = () => {
  const [userProfile, setUserProfile] = useState({});
  const [editableProfile, setEditableProfile] = useState({
    firstName: '',
    lastName: '',
    email: '',
    currentPassword: '',
    newPassword: '',
    confirmPassword: '',
  });
  const [showPassword, setShowPassword] = useState({
    currentPassword: false,
    newPassword: false,
    confirmPassword: false
  });
  const [passwordError, setPasswordError] = useState('');
  const [loadingProfile, setLoadingProfile] = useState(false);
  const [loadingPassword, setLoadingPassword] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');

  useEffect(() => {
    const fetchUserProfile = async () => {
      try {
        const profile = await new UserProfileService().getUserProfile();
        setUserProfile(profile);
        setEditableProfile({
          firstName: profile.firstName || '',
          lastName: profile.lastName || '',
          email: profile.email || 'example@example.com',
          currentPassword: '',
          newPassword: '',
          confirmPassword: '',
        });
      } catch (error) {
        console.error('Failed to fetch user profile:', error);
      }
    };

    fetchUserProfile();
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEditableProfile((prevProfile) => ({
      ...prevProfile,
      [name]: value,
    }));
  };

  const togglePasswordVisibility = (field) => {
    setShowPassword((prevShowPassword) => ({
      ...prevShowPassword,
      [field]: !prevShowPassword[field],
    }));
  };

  const handleProfileSubmit = async (e) => {
    e.preventDefault();
    setLoadingProfile(true);
    const fullName = `${editableProfile.firstName} ${editableProfile.lastName}`;
    try {
      await new UserProfileService().updateUserProfile({ fullName, email: editableProfile.email });
      setSuccessMessage('Profile updated successfully');
      setLoadingProfile(false);
      setTimeout(() => {
        window.location.reload();
      }, 1500);
    } catch (error) {
      console.error('Failed to update profile:', error);
      setLoadingProfile(false);
    }
  };

  const handlePasswordChange = async (e) => {
    e.preventDefault();
    setLoadingPassword(true);
    if (editableProfile.newPassword !== editableProfile.confirmPassword) {
      setPasswordError('New password and confirm password do not match');
      setLoadingPassword(false);
      return;
    }
    try {
      await new UserProfileService().updatePassword(editableProfile.currentPassword, editableProfile.newPassword);
      setSuccessMessage('Password updated successfully');
      setPasswordError('');
      setLoadingPassword(false);
      setTimeout(() => {
        window.location.reload();
      }, 1500);
    } catch (error) {
      setPasswordError(error.message);
      console.error('Failed to update password:', error);
      setLoadingPassword(false);
    }
  };

  const handleCancelProfile = () => {
    setEditableProfile({
      firstName: userProfile.firstName || '',
      lastName: userProfile.lastName || '',
      email: userProfile.email || 'example@example.com',
      currentPassword: '',
      newPassword: '',
      confirmPassword: '',
    });
  };

  const handleCancelPassword = () => {
    setEditableProfile((prevProfile) => ({
      ...prevProfile,
      currentPassword: '',
      newPassword: '',
      confirmPassword: '',
    }));
    setPasswordError('');
  };

  return (
    <div className="p-6 bg-white shadow-lg max-w-7xl mx-auto">
      <h3 className="text-lg font-semibold mb-2 ml-2">Account & Password</h3>
      <p className="text-sm text-gray-600 mb-4 ml-2">Ensure that your profile matches your real data and update your password with a stronger one.</p>
      <div className="border-b mb-4"></div>
      <div className="flex flex-col lg:flex-row gap-8">
        <div className="w-full lg:w-80 flex flex-col items-center">
          <div className="flex flex-col items-center w-full">
            <div
              className="w-full h-48 flex items-center justify-center text-2xl bg-cover bg-center lg:w-80 md:w-1/2 xs:w-1/2 sm:w-1/2"
              style={{
                backgroundImage: userProfile?.photoProfile ? `url(${userProfile.photoProfile})` : '',
                backgroundColor: !userProfile?.photoProfile ? '#f0b1a4' : '',
              }}
            >
              {!userProfile?.photoProfile && (userProfile?.fullName ? userProfile.fullName[0] : '')}
            </div>
            <div className="bg-blue-600 p-10 w-full text-left lg:w-full md:w-1/2 xs:w-1/2 sm:w-1/2" style={{ backgroundColor: '#6938ef' }}>
              <h4 className="font-semibold text-white">{userProfile?.fullName}</h4>
            </div>
          </div>
        </div>
        <div className="w-full lg:w-2/3">
          {successMessage && (
            <div className="mb-4 text-green-600">
              {successMessage}
            </div>
          )}
          <div className="mb-4">
            <h4 className="text-lg font-semibold">Profile</h4>
          </div>
          <form onSubmit={handleProfileSubmit}>
            <div className="mb-4">
              <label className="block text-sm font-medium text-gray-700">First Name</label>
              <input
                type="text"
                name="firstName"
                className="mt-1 p-2 w-full border rounded-lg"
                placeholder="First Name"
                value={editableProfile.firstName}
                onChange={handleInputChange}
                required
              />
            </div>
            <div className="mb-4">
              <label className="block text-sm font-medium text-gray-700">Last Name</label>
              <input
                type="text"
                name="lastName"
                className="mt-1 p-2 w-full border rounded-lg"
                placeholder="Last Name"
                value={editableProfile.lastName}
                onChange={handleInputChange}
                required
              />
            </div>
            <div className="mb-4">
              <label className="block text-sm font-medium text-gray-700">Email</label>
              <input
                type="email"
                name="email"
                className="mt-1 p-2 w-full border rounded-lg"
                placeholder="Email"
                value={editableProfile.email}
                onChange={handleInputChange}
              />
            </div>
            <div className="flex justify-end gap-2">
              <button type="button" onClick={handleCancelProfile} className="px-3 py-2 border rounded-lg text-gray-700" style={{ borderColor: '#6938ef', borderWidth: '1.25px', borderStyle: 'solid' }}>
                Cancel
              </button>
              <button type="submit" className="px-3 py-2 bg-blue-600 text-white rounded-lg" style={{ backgroundColor: '#6938ef' }}>
                {loadingProfile ? 'Saving...' : 'Save Profile'}
              </button>
            </div>
          </form>
          <div className="border-b my-6"></div>
          <div className="mb-4">
            <h4 className="text-lg font-semibold">Password</h4>
          </div>
          <form onSubmit={handlePasswordChange}>
            <div className="mb-4">
              <label className="block text-sm font-medium text-gray-700">Current Password</label>
              <div className="relative">
                <input
                  type={showPassword.currentPassword ? 'text' : 'password'}
                  name="currentPassword"
                  className="mt-1 p-2 w-full border rounded-lg"
                  placeholder="Current Password"
                  value={editableProfile.currentPassword}
                  onChange={handleInputChange}
                />
                <span
                  className="absolute inset-y-0 right-0 pr-3 flex items-center cursor-pointer"
                  onClick={() => togglePasswordVisibility('currentPassword')}
                >
                  {showPassword.currentPassword ? <FaEyeSlash /> : <FaEye />}
                </span>
              </div>
            </div>
            <div className="mb-4">
              <label className="block text-sm font-medium text-gray-700">New Password</label>
              <div className="relative">
                <input
                  type={showPassword.newPassword ? 'text' : 'password'}
                  name="newPassword"
                  className="mt-1 p-2 w-full border rounded-lg"
                  placeholder="New Password"
                  value={editableProfile.newPassword}
                  onChange={handleInputChange}
                />
                <span
                  className="absolute inset-y-0 right-0 pr-3 flex items-center cursor-pointer"
                  onClick={() => togglePasswordVisibility('newPassword')}
                >
                  {showPassword.newPassword ? <FaEyeSlash /> : <FaEye />}
                </span>
              </div>
            </div>
            <div className="mb-4">
              <label className="block text-sm font-medium text-gray-700">Confirm Password</label>
              <div className="relative">
                <input
                  type={showPassword.confirmPassword ? 'text' : 'password'}
                  name="confirmPassword"
                  className="mt-1 p-2 w-full border rounded-lg"
                  placeholder="Confirm Password"
                  value={editableProfile.confirmPassword}
                  onChange={handleInputChange}
                />
                <span
                  className="absolute inset-y-0 right-0 pr-3 flex items-center cursor-pointer"
                  onClick={() => togglePasswordVisibility('confirmPassword')}
                >
                  {showPassword.confirmPassword ? <FaEyeSlash /> : <FaEye />}
                </span>
              </div>
              {passwordError && <p className="mt-2 text-sm text-red-600">{passwordError}</p>}
            </div>
            <div className="flex justify-end gap-2">
              <button type="button" onClick={handleCancelPassword} className="px-3 py-2 border rounded-lg text-gray-700" style={{ borderColor: '#6938ef', borderWidth: '1.25px', borderStyle: 'solid' }}>
                Cancel
              </button>
              <button type="submit" className="px-3 py-2 bg-blue-600 text-white rounded-lg" style={{ backgroundColor: '#6938ef' }}>
                {loadingPassword ? 'Updating...' : 'Update Password'}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default AccountSettings;
