import React, { useState, useEffect } from "react";
import connectLogo from "../../assets/LandingPage/connect.svg";
import bar from "../../assets/LandingPage/bar.svg";
import arrow from "../../assets/LandingPage/arrow.svg";
import grey from "../../assets/LandingPage/greyicon.svg";
import Wrapper from "../../components/Wrapper";

const FeatureSection = () => {
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 640);

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth < 640);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div
      className="flex flex-col lg:flex-row bg-black font-Inter px-2 sm:px-10 gap-10 overflow-hidden"
      style={{
        backgroundImage: "url('/media/bg.png')",
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
      <Wrapper>
        <div className="flex flex-col lg:flex-row gap-20 py-10 sm:py-28">
          <div className="lg:p-2 sm:p-0 space-y-4 sm:space-y-6 flex-1">
            <div className="flex items-center space-x-2">
              <img src={connectLogo} alt="Connect Logo" className="h-6 w-6" />
              <p className="text-new_purple_500 text-lg sm:text-xl">
                Connect Data
              </p>
            </div>
            <h2 className="text-3xl sm:text-2xl lg:text-3xl xl:text-4xl font-medium text-white lg:px-0 self-stretch">
              Craft real-time, multi-app dashboards on canvas
            </h2>
            <div className="space-y-4">
              <div className="flex flex-col sm:flex-row items-start space-x-0 sm:space-x-4">
                <img src={bar} alt="Bar" className="self-center mb-4 sm:mb-0" />
                <div className="flex-1">
                  <h3 className="text-xl font-semibold text-white sm:text-xl">
                    Link your data
                  </h3>
                  <p className="text-[#98A2B3] mt-4 sm:mt-6 pr-2 sm:pr-4 lg:pr-16 sm:text-base">
                    Select from 90+ data sources and effortlessly connect
                    real-time data with just a few clicks.
                  </p>
                  <div className="flex items-center space-x-2 mt-4 sm:mt-6">
                    <button className="text-new_purple_500 hover:underline font-medium">
                      Try for Free
                    </button>
                    <img src={arrow} alt="Arrow" />
                  </div>
                </div>
              </div>
              <div>
                <h3 className="xl:text-xl lg:text-lg text-[#475467] font-medium lg:mt-6 sm:mt-16 ml-2 sm:ml-5 sm:text-lg">
                  Customize your dashboard appearance
                </h3>
                <p className="text-[#475467] font-medium ml-2 sm:ml-5 mt-2 sm:mt-4 lg:text-sm sm:text-base">
                  Pick the metrics you want, then create a professional-looking
                  dashboard with our easy drag-and-drop interface.
                </p>
                <div className="flex items-center space-x-2 mt-2 sm:mt-4 ml-2 sm:ml-5">
                  <button className="text-[#475467] hover:underline font-medium">
                    Try for Free
                  </button>
                  <img src={grey} alt="Arrow" />
                </div>
              </div>
              <div>
                <h3 className="xl:text-xl lg:text-lg text-[#475467] lg:mt-6 sm:mt-16 ml-2 sm:ml-5 sm:text-lg">
                  Ensure it's impossible to overlook.
                </h3>
                <p className="text-[#475467] font-medium mt-2 sm:mt-4 ml-2 sm:ml-5 lg:text-sm sm:text-base">
                  Create a sharing link. Display on TV. View on mobile. Or
                  schedule snapshots for email and Slack.
                </p>
                <div className="flex items-center space-x-2 mt-2 sm:mt-4 ml-2 sm:ml-5">
                  <button className="text-[#475467] hover:underline font-medium">
                    Try for Free
                  </button>
                  <img src={grey} alt="Arrow" />
                </div>
              </div>
            </div>
          </div>

          <div className="flex-1 lg:mt-0 sm:mt-10">
            <div className="w-full h-auto flex justify-center">
              <img
                src={"/media/login/choose.png"}
                alt="Visual Representation"
                className="xl:h-[582px] xl:w-[700px] lg:h-[520px] lg:w-[540px] md:hidden sm:hidden lg:block"
              />
            </div>
          </div>
        </div>
      </Wrapper>
    </div>
  );
};

export default FeatureSection;
