import React, { useEffect } from 'react'
import Tools from './tools'
import { useLocation,useNavigate } from 'react-router-dom'

const Integrations = () => {
  const {state} = useLocation();
  const navigate = useNavigate()

  useEffect(()=>{
    if(!state){
        navigate(-1)
    }
  },[])

  
  return (
    <div className='w-full min-h-[calc(100vh-70px)] flex flex-col relative  ' >
        {/**Purple Bar**/}
        <div className='w-full flex flex-col gap-1 items-start justify-center h-[160px] bg-new_purple_600 px-14  ' >
            <h2 className=' lg:text-[30px] 2xl:text-[36px] text-white font-Inter font-semibold -tracking-[1px]'>Choose the data that want you to analysis</h2>
            <span className=' text-[16px] text-white font-Inter font-light  ' >Please choose the account type according to your preferences</span>
        </div>
        {/**Integration Tools**/}
        <div className='w-full h-[calc(100vh-230px)] flex overflow-y-auto  px-14 gap-4 ' >
          {/***Integrations Side */}
          <Tools  />
        </div>
    </div>
  )
}

export default Integrations