import React from 'react'
import {useNavigate} from "react-router-dom"

const SuccessPage = () => {

  const navigate = useNavigate()

  return (
    <div className=" w-[70%] max-w-[550px]  flex flex-col items-center gap-1 ">
       {/**Logo Baiynah**/}
    <div className="w-full flex flex-col gap-3 items-center p-4  ">
      <img
        src="/media/login/baiynah-logo.svg"
        className="mb-[10px] mt-[20px] "
      />
      <h2 className=" sm:text-[22px] 2xl:text-[30px] font-Inter font-semibold ">
      Password Successfully Changed
      </h2>
      <span className="sm:text-[14px] 2xl:text-[16px] font-Inter font-normal text-center text-[new_gray_700] text-[#344054] ">
      Your password has been updated successfully.
      </span>

    </div>
    <div className='flex flex-row gap-1 items-center justify-center ' >
         <b><span onClick={()=>navigate("/login")} className='text-[16px] font-Inter text-new_purple_600 cursor-pointer' >Back to Login</span></b>        
      </div>
    </div>
  )
}

export default SuccessPage