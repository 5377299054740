import React, { useRef, useState } from "react";
import { useEffect } from "react";
import AddWidgets from "../../../../../../../../Services/add-widgets";
import Spinner from "../../../../../../../../components/spinner";
import { useDispatch ,useSelector} from "react-redux";
import { GetUserSheetData } from "../../../../../../../../Redux/SheetSlice";
import { GetDataSets } from "../../../../../../../../Redux/dataSourcesSlice";
import UserSheetsData from "./userSheetsData";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'

const Excel = ({currentTab,setCurrentTab}) => {
  const fileRef = useRef();
  const dispatch = useDispatch()

  const ExcelSheets = useSelector((state)=>state.sheet.sheetData)

  const [ExcelData, setExcelData] = useState(null);
  const [isError, setisError] = useState(false);
  const [progressBar, setProgressBar] = useState(0);
  const [intervalId, setIntervalId] = useState(null);
  const [isLoading, setisLoading] = useState(false);


  useEffect(()=>{
    dispatch(GetUserSheetData())
  },[])

  const handleUpload = (event) => {
    setisError(false);
    const file = event.target.files[0];

    if (file) {
      if (isExcelFile(file)) {
        setExcelData(file);
        startProgressInterval();
      } else {
        setisError(true);
        return;
      }
    }
  };

  const isExcelFile = (file) => {
    const extension = file.name.split(".").pop().toLowerCase();

    return extension === "xls" || extension === "xlsx";
  };

  const convertToKb = (size) => {
    const sizeInKb = Math.round(size / 1024);
    return sizeInKb + " KB";
  };

  const handleDelete = () => {
    setExcelData(null);
    if (fileRef.current) {
      fileRef.current.value = "";
      setProgressBar(0);
      setIntervalId(null);
    }
  };

  const startProgressInterval = () => {
    setProgressBar(0);
    const id = setInterval(() => {
      setProgressBar((prevWidth) => {
        if (prevWidth < 100) {
          return prevWidth + 20;
        }
        clearInterval(id);
        return prevWidth;
      });
    }, 1000);
    setIntervalId(id);
  };

  useEffect(() => {
    return () => {
      clearInterval(intervalId); // Cleanup the interval on component unmount
    };
  }, [intervalId]);

  const handleAdd = async (e) => {
    setisLoading(true);
    e.preventDefault();

    if (ExcelData) {
      const formData = new FormData();
      const integrationId = currentTab.id

      formData.append("file", ExcelData);
      formData.append("IntegrationId",integrationId)

      try {
        const res = await AddWidgets.uploadFile(formData);
        setisLoading(false);
        dispatch(GetDataSets())
        setExcelData(null);
        toast.success(`Uploaded Successfully`, {
          position: "top-right",
         },5000)
   
        if (fileRef.current) {
          fileRef.current.value = "";
          setProgressBar(0);
          setIntervalId(null);
        }
      } catch (error) {
        // setisError(error?.response?.data?.error)
        setisLoading(false);
      }
    }
  };

  const disabled = !ExcelData;

  return (
    <div className="w-full h-full flex flex-col  ">
       <div className="w-full h-[60px] flex items-center justify-between border-b-[1px]  ">
          <span onClick={()=>setCurrentTab(" ")} className="font-Inter cursor-pointer font-semibold text-[#101828] " >Back</span>
          <img src="/media/add-widgets/connect.svg"/>
        </div>
      <div className="w-full h-[calc(100%-70px)] max-w-[600px] mx-auto flex flex-col items-center justify-center gap-6 pt-6">
            {/**Tool Logo**/}
          <div className="flex flex-row gap-4 items-center ">
            <img src="/media/add-widgets/excel.png" className="h-[64px]" />
            <h2 className="text-[20px] font-Inter font-semibold ">Upload Excel File</h2>
          </div>
          {/**Some Text**/}
          <span className="font-Inter text-[14px] ">
            To add a metric, choose from already connected data sources, or connect
            a new one
          </span>
      
          {
            ExcelSheets?.length > 0 &&  <UserSheetsData ExcelSheets={ExcelSheets} />
          }
          
          {!ExcelData && (
            <div
              onClick={() => fileRef.current.click()}
              className="w-full h-[120px] border-[1px] relative border-gray-100 hover:bg-gray-100 bg-opacity-5 cursor-pointer rounded-md gap-2 flex items-center justify-center flex-col  "
            >
              <div className="flex flex-col gap-2 items-center">
                <img
                  src="/media/add-widgets/upload-cloud.svg"
                  className="w-[40px] h-[40px] "
                />
                <span className="text-[14px] text-new_purple_600 font-semibold ">
                  Click to upload
                </span>
                {isError && (
                  <span className="text-[14px] text-red-600 font-semibold">
                    Cant upload file other than Excel
                  </span>
                )}
                <input
                  ref={fileRef}
                  onChange={handleUpload}
                  type="file"
                  className="text-[40px] w-[300px]  absolute top-[50px] hidden "
                />
              </div>
            </div>
          )}
          {ExcelData && (
            <div className=" w-full h-[100px] border-[1px] border-gray-100 flex flex-row items-start gap-2  p-4  rounded-md">
              <img
                src="/media/add-widgets/file-success.svg"
                className="w-[40px] h-[40px]"
              />
              <div className="flex flex-col w-[80%] ">
                <h2 className="font-Inter font-semibold">{ExcelData?.name}</h2>
                <span className="font-Inter font-normal text-new_gray_500 ">
                  {convertToKb(ExcelData?.size)}
                </span>
                <div className="flex flex-row gap-2  items-center ">
                  {/***PROGERESS */}
                  <div className="w-[90%] h-[8px] bg-gray-200 rounded-full z-10 relative ">
                    <span
                      style={{ width: progressBar + "%" }}
                      className=" h-[8px] transition-all bg-new_purple_600 rounded-full z-30 absolute left-0 "
                    ></span>
                  </div>

                  <span className="font-Inter font-semibold ">
                    {progressBar + "%"}
                  </span>
                </div>
              </div>
              <img
                onClick={handleDelete}
                src="/media/add-widgets/trash-2.svg"
                className="hover:scale-105 active:scale-90 cursor-pointer transition-all "
              />
            </div>
          )}
          
          <button
            onClick={handleAdd}
            disabled={disabled || progressBar < 100 || isLoading}
            className={`w-full h-[45px]  bg-new_purple_600 disabled:bg-new_purple_500 disabled:cursor-not-allowed   ${
              !disabled && "hover:bg-new_purple_500"
            } cursor-pointer flex items-center justify-center flex-row gap-1 rounded-md `}
          >
            {isLoading ? (
              <Spinner />
            ) : (
              <>
                <img src="/media/add-widgets/plus.svg" />
                <span className="font-Inter text-white ">Add</span>
              </>
            )}
          </button>
          
          </div>
    </div>
  );
};

export default Excel;
